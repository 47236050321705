export function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

export function getCroppedImage(image, crop, fileName) {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width * scaleX;
  canvas.height = crop.height * scaleY;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        return reject(new Error('Canvas is empty'));
      }
      blob.name = fileName;
      window.URL.revokeObjectURL(blob);
      resolve(canvas.toDataURL('image/jpeg'));
    }, 'image/jpeg');
  });
}

export const IMAGE_ORIENTATION = {
  LANDSCAPE: 'LANDSCAPE',
  PORTRAIT: 'PORTRAIT',
  SQUARE: 'SQUARE',
};

export function getImageOrientation(height, width) {
  const difference = (Math.abs(width - height) / Math.max(width, height)) * 100;

  if (difference < 1) {
    return IMAGE_ORIENTATION.SQUARE;
  } else if (width > height) {
    return IMAGE_ORIENTATION.LANDSCAPE;
  } else {
    return IMAGE_ORIENTATION.PORTRAIT;
  }
}
