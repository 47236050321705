import React, { useMemo } from 'react';
import styled from 'styled-components';
import theme from '../../theme';
import { USER_TYPE, STORAGE_BASE_URL } from '../../utils/constants';

const AVATAR_COLORS = [
  '#30011E',
  '#0D5F07',
  '#475D32',
  '#B68F40',
  '#545454',
  '#05668d',
  '#ee6c4d',
  theme.color.avatarPurple,
  '#370617',
  '#2a9d8f',
  '#264653',
  '#ef476f',
  '#ff6b6b',
  '#2d3142',
  '#084c61',
];

const getDiameterAndFontSize = (size) => {
  switch (size) {
    case 'xl':
      return { diameter: 150, fontSize: 96 };
    case 'l':
      return { diameter: 100, fontSize: 64 };
    case 'ml':
      return { diameter: 70, fontSize: 40 };
    case 's':
      return { diameter: 40, fontSize: 22 };
    case 'xs':
      return { diameter: 30, fontSize: 16 };
    default:
      return { diameter: 56, fontSize: 32 };
  }
};

const StyledText = styled.span`
  color: ${theme.color.white};
  font-size: ${(props) => props.fontSize}px;
  margin-bottom: 0;
`;

const StyledContainer = styled.div`
  display: flex;
  width: ${(props) => props.diameter}px;
  height: ${(props) => props.diameter}px;
  border-radius: ${(props) => props.diameter / 2}px;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.backgroundColor};
`;

const StyledImage = styled.img`
  width: ${(props) => props.diameter}px;
  height: ${(props) => props.diameter}px;
  border-radius: ${(props) => props.diameter / 2}px;
`;

const UserAvatar = (props) => {
  const {
    user,
    textOverride = '',
    size,
    overrideBackground,
    defaultBackground = theme.color.blackTransparent,
    imageUrl,
  } = props;

  const isCollector = user.userType === USER_TYPE.COLLECTOR;
  const username = user.username || user.uid?.replace(/[0-9]/g, '');
  const avatarText = textOverride || (username ? username[0].toUpperCase() : ' ');

  const { diameter, fontSize } = getDiameterAndFontSize(size);

  const backgroundColor =
    textOverride || username
      ? overrideBackground || AVATAR_COLORS[avatarText.charCodeAt(0) % 14]
      : defaultBackground;

  const imageSource = useMemo(
    () => (isCollector ? `${STORAGE_BASE_URL}/collectibles/ziggy-1.png` : imageUrl),
    [imageUrl, isCollector]
  );

  return (
    <StyledContainer diameter={diameter} backgroundColor={backgroundColor}>
      {isCollector || imageUrl ? (
        <StyledImage diameter={diameter} src={imageSource} />
      ) : (
        <StyledText fontSize={fontSize}>{avatarText}</StyledText>
      )}
    </StyledContainer>
  );
};

export default UserAvatar;
