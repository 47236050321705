import React from 'react';
import styled from 'styled-components';

const StyledLabel = styled.span`
  margin-bottom: 9px;
  font-weight: bold;
  font-size: 1em;
`;

const FormLabel = (props) => (
  <div>
    <div style={{ display: 'flex', marginTop: '20px' }}>
      <StyledLabel>{props.label}</StyledLabel>
    </div>
  </div>
);

export default FormLabel;
