import React from 'react';
import styled from 'styled-components';
import Dropdown from '../../../UI/Dropdown/Dropdown';

const PromptFilterDropdown = styled(Dropdown)`
  && {
    width: 100%;
  }
`;

export default PromptFilterDropdown;
