import React from 'react';
import NavMenu from './NavMenu/NavMenu';
import styled from 'styled-components';
import theme from '../../theme';

const StyledNavbar = styled.div`
  background-color: ${theme.color.blue};
  color: white;
  height: 50px;
  display: flex;
  align-items: center;
`;

const StyledAppTitle = styled.span`
  font-size: 1.35em;
  font-weight: bold;
  margin-left: 5%;
`;

const Navbar = (props) => {
  return (
    <StyledNavbar>
      <StyledAppTitle>Zigazoo</StyledAppTitle>
      {props.userType && !props.hideLinks && <NavMenu userType={props.userType} />}
    </StyledNavbar>
  );
};

export default Navbar;
