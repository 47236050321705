import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../../config/firebase';
import { httpsCallable } from '../../utils/functions';
import * as Sentry from '@sentry/react';

export const signInWithGooglePopup = () => {
  return signInWithPopup(auth, new GoogleAuthProvider());
};

export const getUser = async (uid) => {
  return await httpsCallable('getUser', { uid })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
      Sentry.addBreadcrumb(e);
    });
};

export const updateUser = async ({ uid, ...data }) => {
  return await httpsCallable('updateUser', data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
      Sentry.addBreadcrumb(e);
    });
};
