import React from 'react';
import { useDispatch } from 'react-redux';
import { PromptAgeRangeFilter } from '../../../../utils/constants';
import { setPromptsFilterAction } from '../../../../store/prompts/actions';
import usePromptFilters from '../hooks/usePromptFilters';
import PromptFilterDropdown from './PromptFilterDropdown';

const PromptFilterAgeRange = () => {
  const dispatch = useDispatch();
  const { filters } = usePromptFilters();
  const { allowedAgeRanges } = filters;
  const options = [
    { name: 'All', value: PromptAgeRangeFilter.ALL },
    { name: 'Kids', value: PromptAgeRangeFilter.KIDS },
    { name: 'General Audience', value: PromptAgeRangeFilter.GA },
    { name: 'Only Both', value: PromptAgeRangeFilter.BOTH },
  ];

  const handleChange = (e) => {
    const { value } = e.target;

    dispatch(
      setPromptsFilterAction({
        allowedAgeRanges: value == PromptAgeRangeFilter.ALL ? [] : value,
      })
    );
  };

  return (
    <PromptFilterDropdown
      name="ageRange"
      options={options}
      label="Age Range"
      changed={handleChange}
      value={allowedAgeRanges || PromptAgeRangeFilter.ALL}
    />
  );
};

export default PromptFilterAgeRange;
