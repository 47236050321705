import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

const ArrowUpIcon = ({ color, width, height }) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    color={color}
    width={width}
    height={height}>
    <g>
      <path d="M17.293,15.207,12,9.914,6.707,15.207,5.293,13.793,10.586,8.5a2,2,0,0,1,2.828,0l5.293,5.293Z" />
    </g>
  </Icon>
);

ArrowUpIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default ArrowUpIcon;
