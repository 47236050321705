import usePromptFilters from './usePromptFilters';

export default function usePromptMode() {
  const { isApprovedStatusSelected, isUnreviewedStatusSelected } = usePromptFilters();

  return {
    isRankingMode: isApprovedStatusSelected,
    isReviewingMode: isUnreviewedStatusSelected,
  };
}
