import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { AgeRange as AgeRangeConst } from '../../../../utils/constants';
import { areArraysEqual } from '../../../../utils/helpers';
import FormLabel from '../../../UI/FormLabel/FormLabel';
import Disclaimer from '../../../UI/Disclaimer/Disclaimer';
import RadioButton from '../../../UI/RadioButton/RadioButton';

const AgeRange = ({ ageRanges, availableAgeRanges, onChange }) => {
  const allowedAgeRanges = useMemo(() => {
    const ranges = {};
    const hasKidsAgeRange = availableAgeRanges.includes(AgeRangeConst.KIDS);
    const hasGaAgeRange = availableAgeRanges.includes(AgeRangeConst.GA);

    if (hasKidsAgeRange) {
      ranges.kids = { label: 'Kids', value: [AgeRangeConst.KIDS] };
    }
    if (hasGaAgeRange) {
      ranges.ga = { label: 'General Audience', value: [AgeRangeConst.GA] };
    }
    if (hasKidsAgeRange && hasGaAgeRange) {
      ranges.both = { label: 'Both', value: [AgeRangeConst.KIDS, AgeRangeConst.GA] };
    }

    return ranges;
  }, [availableAgeRanges]);

  const handleChange = (event) => {
    const { value } = event.target;
    const ageRange = allowedAgeRanges[value].value;

    onChange(ageRange);
  };

  return (
    <div className="age-range-form">
      <FormLabel label={<span className="mandatory-field">Who should see this challenge?</span>} />

      <Disclaimer style={{ marginTop: 0 }}>
        Only age ranges enabled for the prompt owner will show
      </Disclaimer>

      {Object.entries(allowedAgeRanges).map(([key, ageRange]) => (
        <RadioButton
          id={key}
          key={key}
          label={ageRange.label}
          value={key}
          checked={areArraysEqual(ageRanges, ageRange.value)}
          changed={handleChange}
        />
      ))}
    </div>
  );
};

AgeRange.propTypes = {
  ageRanges: PropTypes.arrayOf(PropTypes.string).isRequired,
  availableAgeRanges: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

AgeRange.defaultProps = {
  availableAgeRanges: [AgeRangeConst.KIDS],
};

export default AgeRange;
