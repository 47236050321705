import React, { useMemo } from 'react';
import styled from 'styled-components';
import TextInput from '../../../UI/TextInput/TextInput';
import TextArea from '../../../UI/TextArea/TextArea';
import DatePickerContainer from '../../../UI/DatePickerContainer/DatePickerContainer';
import Button from '../../../UI/Button/Button';

const StyledResetButton = styled(Button)`
  font-size: 14px;
  padding: 10px 20px;
  background-color: white;
  border: 1px solid #ccc;
  margin-top: 20px;
`;

const RewardDetails = ({ rewardDetails, onUpdate }) => {
  const areAllFieldsFilled = useMemo(
    () => Object.values(rewardDetails).every((value) => Boolean(value)),
    [rewardDetails]
  );

  const handleChange = (event) => {
    const { value, name } = event.target;
    const match = name.match(/\[([^[\]]+)\]/);
    const key = match[1];

    onUpdate({ ...rewardDetails, [key]: value });
  };

  const handleExpiresAtChange = (date) => {
    onUpdate({ ...rewardDetails, expiresAt: date.getTime() });
  };

  const handleReset = () => {
    onUpdate({ name: null, description: null, expiresAt: null });
  };

  return (
    <div>
      <TextInput
        label="Reward Name"
        name="rewardDetails[name]"
        value={rewardDetails.name || ''}
        maxLength={100}
        changed={handleChange}
      />
      <TextArea
        label="Reward Description"
        name="rewardDetails[description]"
        changed={handleChange}
        value={rewardDetails.description || ''}
        maxlength={280}
      />
      <DatePickerContainer
        label="Reward Expires At"
        name="rewardDetails[expiresAt]"
        date={rewardDetails.expiresAt}
        changed={handleExpiresAtChange}
        withDisclaimer={false}
      />
      {areAllFieldsFilled && (
        <StyledResetButton clicked={handleReset}>Reset The Reward</StyledResetButton>
      )}
    </div>
  );
};

export default RewardDetails;
