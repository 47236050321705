import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getModerationFeedAction } from '../../../store/moderation/feed/actions';
import ModerationFeedList from './ModerationFeedList';

const ModerationFeed = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getModerationFeedAction());
  }, []);

  return (
    <div className="Container">
      <h1>Moderation Feed</h1>
      <ModerationFeedList />
    </div>
  );
};

export default ModerationFeed;
