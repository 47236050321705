export const GET_PROMPT = 'GET_PROMPT';
export const GET_PROMPT_SUCCESS = 'GET_PROMPT_SUCCESS';
export const GET_PROMPT_FAILURE = 'GET_PROMPT_FAILURE';

export const getPromptAction = (promptId) => ({
  type: GET_PROMPT,
  promptId,
});

export const setPromptSuccessAction = (prompt) => ({
  type: GET_PROMPT_SUCCESS,
  prompt,
});

export const setPromptFailureAction = (errors) => ({
  type: GET_PROMPT_FAILURE,
  errors,
});
