import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPromptAction } from '../../../store/prompt/actions';

export default function usePromptLoader(promptId) {
  const dispatch = useDispatch();
  const { prompt, errors, isLoading } = useSelector((state) => state.prompt);
  const isPromptWhatLookingFor = (prompt?.firestoreId || prompt?.id) === promptId;

  useEffect(() => {
    if (!promptId || isPromptWhatLookingFor) {
      return;
    }

    dispatch(getPromptAction(promptId));
  }, [promptId]);

  return {
    prompt: isPromptWhatLookingFor ? prompt : null,
    errors,
    isLoading,
  };
}
