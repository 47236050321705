import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPromptsAction, preventPromptsLoadAction } from '../../../../store/prompts/actions';
import usePromptFilters from './usePromptFilters';
import usePromptSearch from './usePromptSearch';

export default function usePromptListLoader() {
  const dispatch = useDispatch();
  const { filters, hasFilterChosen } = usePromptFilters();
  const { searchTerm, hasSearchTerm } = usePromptSearch();
  const { isLoading, hasPrompts, preventPromptsLoad } = useSelector((state) => state.prompts);
  const hasNotFoundPrompts = hasSearchTerm && !hasPrompts && !isLoading;
  const arePromptsEmpty = !hasPrompts && !isLoading && !hasSearchTerm && !hasFilterChosen;

  useEffect(() => {
    if (preventPromptsLoad) {
      return;
    }

    dispatch(getPromptsAction());

    return () => {
      dispatch(preventPromptsLoadAction());
    };
  }, [filters, searchTerm]);

  return { isLoading, hasNotFoundPrompts, arePromptsEmpty };
}
