import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import theme from '../../../theme';
import { RowContainer, ColumnContainer, Column } from '../../UI/Grid';
import Button from '../../UI/Button/Button';
import { PenIcon, TrashIcon } from '../../UI/Icons';
import { useDownloadUrl } from '../../../hooks';
import UserAvatar from '../UserAvatar';
import { setUserAction } from '../../../store/users/actions';
import { getUserAction } from '../../../store/users/actions';
import { Routes } from '../../../utils/constants';

const StyledListItemContainer = styled(RowContainer)`
  && {
    padding: 10px 15px;
    opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};

    &:hover {
      background-color: ${theme.color.fogGrey};
    }
  }
`;

const StyledTruncatedColumn = styled(ColumnContainer)`
  && {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const StyledActionColumn = styled(ColumnContainer)`
  && {
    text-align: right;
  }
`;

const EditButton = styled(Button)`
  background-color: ${theme.color.blue};
  margin-right: 10px;
  padding: 5px 10px;
}`;

const DeleteButton = styled(Button)`
  background-color: ${theme.color.red};
  margin-right: 10px;
  padding: 5px 10px;
}`;

const UsersListItem = ({
  isLoading,
  uid,
  fullName,
  username,
  email,
  phoneNumber,
  profileThumbnail,
  userType,
  highlights,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [imageUrl] = useDownloadUrl(profileThumbnail);
  const user = { userType, uid, fullName, username, email, phoneNumber };

  const highlighted = useMemo(() => {
    const fields = {
      uid,
      fullName,
      username,
      email,
      phoneNumber,
    };

    if (!highlights || !highlights.length) {
      return fields;
    }

    return highlights.reduce((fields, highlight) => {
      const highlightedValue = highlight.texts
        .map((text) => {
          if (text.type == 'hit') {
            return '<mark>' + text.value + '</mark>';
          } else {
            return text.value;
          }
        })
        .join('')
        .replace(/\s/g, '&nbsp;');

      fields[highlight.path] = highlightedValue;

      return fields;
    }, fields);
  }, [highlights, uid, fullName, username, email, phoneNumber]);

  const handleEditClick = () => {
    dispatch(setUserAction(user));

    history.push(Routes.EDIT_USER);
  };

  const handleDeleteClick = () => {
    dispatch(getUserAction(username));

    history.push(Routes.DISABLE_USER);
  };

  return (
    <StyledListItemContainer isDisabled={isLoading}>
      <Column width="5%">
        <UserAvatar user={user} size="xs" imageUrl={imageUrl} />
      </Column>
      <StyledTruncatedColumn width="10%" dangerouslySetInnerHTML={{ __html: highlighted.uid }} />
      <StyledTruncatedColumn
        width="25%"
        dangerouslySetInnerHTML={{ __html: highlighted.fullName }}
      />
      <StyledTruncatedColumn
        width="20%"
        dangerouslySetInnerHTML={{ __html: highlighted.username }}
      />
      <StyledTruncatedColumn width="20%" dangerouslySetInnerHTML={{ __html: highlighted.email }} />
      <StyledTruncatedColumn
        width="10%"
        dangerouslySetInnerHTML={{ __html: highlighted.phoneNumber }}
      />
      <StyledActionColumn width="10%">
        <EditButton clicked={handleEditClick}>
          <PenIcon color="white" height="14px" width="14px" />
        </EditButton>
        <DeleteButton clicked={handleDeleteClick}>
          <TrashIcon color="white" height="14px" width="14px" />
        </DeleteButton>
      </StyledActionColumn>
    </StyledListItemContainer>
  );
};

export default UsersListItem;
