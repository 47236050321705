import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import theme from '../../../../theme';
import { ArrowUpIcon, ArrowDownIcon, DisableIcon } from '../../../UI/Icons';
import * as Constants from '../../../../utils/constants';
import { updatePromptRanksAction, setPromptsAction } from '../../../../store/prompts/actions';
import usePromptFilters from '../hooks/usePromptFilters';
import PromptItem from './PromptItem/PromptItem';

const StyledPromptGroup = styled.div`
  margin-bottom: 10px;
  border-radius: 5px;
  overflow: hidden;
  background: ${theme.color.lightGrey};
  width: 100%;
`;

const StyledPromptGroupHeader = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  border: 0;
  background: ${(props) => (props.disabled ? theme.color.lightGrey : theme.color.fogGrey)};
  width: 100%;
  font-size: 24px;
  font-weight: 600;
  padding: 15px 20px;
  color: ${(props) => (props.disabled ? theme.color.mediumGrey : theme.color.black)};

  svg {
    path {
      fill: ${(props) => (props.disabled ? theme.color.mediumGrey : theme.color.black)};
    }
  }

  small {
    font-size: 14px;
    color: ${theme.color.mediumGrey};
    margin-left: 10px;
  }
`;

const StyledPromptGroupBody = styled.div`
  padding: 0px 20px;
`;

const PromptGroup = (props) => {
  const { isKidsRangeSelected, isGARangeSelected } = usePromptFilters();
  const isAdmin = props.userType === 'ADMIN';
  const rankFieldName = isGARangeSelected ? 'gaRank' : 'rank';
  const rankAgeRange = isKidsRangeSelected
    ? Constants.AgeRange.KIDS
    : isGARangeSelected
    ? Constants.AgeRange.GA
    : null;
  const [isOpen, setIsOpen] = useState(props.isOpen);
  const [prompts, setPrompts] = useState(props.prompts);
  const isDisabled = prompts.length === 0;

  useEffect(() => {
    setPrompts(props.prompts);
  }, [props.prompts]);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const toggleOpen = () => {
    props.openedType(props.promptTypeId, !isOpen);
    setIsOpen(!isOpen);
  };

  const onDragEnd = (dragEvent) => {
    // NOTE: in this function, `prompts` refers to array of prompts for the current prompt type,
    // while `allPrompts` refers to all existing prompts and corresponding prompt types
    if (!dragEvent.destination) {
      return;
    }

    const initialPos = dragEvent.source.index - 1;
    const finalPos = dragEvent.destination.index - 1;

    // First we remove the moved prompt from the array
    const promptsCopy = [...prompts];
    const prompt = promptsCopy.splice(initialPos, 1);

    // Then we reinsert it at the correct position, update the rank on all prompts and update the internal state
    let newPrompts = [...promptsCopy.slice(0, finalPos), ...prompt, ...promptsCopy.slice(finalPos)];
    newPrompts = newPrompts.map((prompt, index) => ({ ...prompt, [rankFieldName]: index + 1 }));
    setPrompts(newPrompts);

    // Then we call the service to update the ranks on the database
    props.updatePromptRanks(
      props.promptTypeId,
      rankAgeRange,
      newPrompts.map((prompt, index) => ({ promptId: prompt.id, rank: index + 1 })),
      prompt[0].id
    );

    // Finally, we update Redux to reflect the rank update without calling the database
    const promptTypeIndex = props.allPrompts.findIndex(
      (promptType) => promptType.id == props.promptTypeId
    );
    const allPromptsCopy = [...props.allPrompts];

    allPromptsCopy[promptTypeIndex].prompts = [...newPrompts];
    // NOTE: we do not need to force update the prompts here
    //       because we are updating the prompts via state management
    //       to make UX behave right
    props.setPrompts(allPromptsCopy, { force: true });
  };

  // NOTE: One loop is enough to calculate all the values we need and boost performance
  const [availablePrompts, promptsToReviewCount] = useMemo(() => {
    const availablePrompts = [];
    let promptsToReviewCount = 0;

    prompts.forEach((prompt) => {
      if (prompt.isNonLiveUnreviewed) {
        promptsToReviewCount++;
      }

      availablePrompts.push(prompt);
    });

    return [availablePrompts, promptsToReviewCount];
  }, [prompts]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={props.promptTypeId}>
        {(provided) => (
          <StyledPromptGroup ref={provided.innerRef} {...provided.droppableProps}>
            <StyledPromptGroupHeader type="button" disabled={isDisabled} onClick={toggleOpen}>
              <div>
                {isAdmin && !isDisabled ? `${props.type}: ${promptsToReviewCount}` : props.type}
              </div>
              {isDisabled ? (
                <DisableIcon width="18px" height="18px" />
              ) : isOpen ? (
                <ArrowUpIcon />
              ) : (
                <ArrowDownIcon />
              )}
            </StyledPromptGroupHeader>
            {isOpen && (
              <StyledPromptGroupBody>
                {availablePrompts.map((prompt, index) => (
                  <PromptItem
                    key={prompt.id}
                    prompt={prompt}
                    promptTypeId={props.promptTypeId}
                    rank={prompt[rankFieldName] || index}
                    isReviewMode={props.isReviewMode}
                  />
                ))}
                {provided.placeholder}
              </StyledPromptGroupBody>
            )}
          </StyledPromptGroup>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const mapStateToProps = (state) => ({
  allPrompts: state.prompts.prompts,
  userType: state.session.userType,
});

const mapDispatchToProps = (dispatch) => ({
  updatePromptRanks: (...args) => dispatch(updatePromptRanksAction(...args)),
  setPrompts: (...args) => dispatch(setPromptsAction(...args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PromptGroup);
