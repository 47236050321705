import { put, call } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import { searchTags } from './services';
import { searchTagsSuccessAction, searchTagsFailureAction } from './actions';

export function* searchTagsSaga({ value, ageRange }) {
  try {
    const tags = yield searchTags(value, ageRange);
    yield put(searchTagsSuccessAction(tags));
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    yield put(searchTagsFailureAction(error.message));
  }
}
