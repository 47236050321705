import { isStaging } from '../utils/constants';

const algoliasearch = require('algoliasearch');

// Algolia configuration and initialization

let client = algoliasearch('Y1836R7NDR', '3a80ad3260c67719fd1cf7bb06894696'); // TODO: Move to Secret Manager
const userIndexName = isStaging ? 'staging_users' : 'prod_users'; // TODO: Move to Secret Manager
const userIndex = client.initIndex(userIndexName);

export { userIndex };
