import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchBar from '../../UI/SearchBar';
import { setUsersListSearchTermAction } from '../../../store/users/actions';
import { getUsersListAction } from '../../../store/users/actions';

const UsersListSearchBar = () => {
  const { usersListSearchTerm } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const handleSubmit = (value) => {
    dispatch(setUsersListSearchTermAction(value));

    dispatch(
      getUsersListAction({
        skip: 0,
        limit: 30,
        ...(value ? { searchTerm: value } : {}),
      })
    );
  };

  return (
    <SearchBar
      minLengath={3}
      placeholder="Search by username, email, phone, name or ID"
      value={usersListSearchTerm}
      onSubmit={handleSubmit}
    />
  );
};

export default UsersListSearchBar;
