const core = {
  fontFamily: 'Roboto-Regular',
  fontSize: 14,
  color: {
    avatarPurple: '#7400b8',
    blue: '#3467C1',
    blueTransparent: 'rgba(52,103,193,0.6)',
    darkBlue: '#154AA8',
    navy: '#337ab7',
    mediumGrey: '#AFB3BC',
    heatherGrey: '#D5DAE2',
    fogGrey: '#E7ECF3',
    lightGrey: '#f4f6f9',
    white: '#fff',
    black: '#2E3133',
    green: '#28a745',
    lightPink: 'rgb(255, 227, 227)',
    red: '#ff5454',
    yellow: '#ffc107',
  },
};

const implementations = {
  outlined: {
    borderWidth: 5,
    borderColor: core.color.fogGrey,
  },
  outlinedSelected: {
    backgroundColor: core.color.darkBlue,
    borderColor: core.color.darkBlue,
    color: core.color.white,
  },
};

export default {
  ...core,
  ...implementations,
};
