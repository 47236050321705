import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

function KidIcon({ color, width, height }) {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      color={color}
      width={width}
      height={height}>
      <path d="M24 11.5a3.5 3.5 0 00-2.149-3.226 10 10 0 00-19.7 0 3.5 3.5 0 001.119 6.718 10.607 10.607 0 002.071 2.955 8.908 8.908 0 00-2.272 4.928 1 1 0 00.868 1.117 1.093 1.093 0 00.124.008 1 1 0 00.991-.875 6.924 6.924 0 011.815-3.872A8.948 8.948 0 0012 21a8.94 8.94 0 005.119-1.74 6.922 6.922 0 011.808 3.862 1 1 0 00.991.876 1.063 1.063 0 00.125-.008 1 1 0 00.868-1.116 8.9 8.9 0 00-2.261-4.918 10.622 10.622 0 002.082-2.966A3.5 3.5 0 0024 11.5zm-3.752 1.473a.993.993 0 00-1.117.651C18.215 16.222 15.13 19 12 19s-6.215-2.78-7.131-5.378a.994.994 0 00-1.117-.651A1.606 1.606 0 013.5 13a1.5 1.5 0 01-.27-2.972 1 1 0 00.816-.878A7.961 7.961 0 018.13 3a4.075 4.075 0 00-.022 1.942 4 4 0 007.688.318.977.977 0 00-.945-1.26H14.7a.867.867 0 00-.806.631A2 2 0 1112 2a7.978 7.978 0 017.954 7.15 1 1 0 00.816.878A1.5 1.5 0 0120.5 13a1.606 1.606 0 01-.252-.027z"></path>
      <circle cx="9.5" cy="11.5" r="1.5"></circle>
      <circle cx="14.5" cy="11.5" r="1.5"></circle>
    </Icon>
  );
}

KidIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default KidIcon;
