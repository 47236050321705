import React from 'react';
import { useDispatch } from 'react-redux';
import ButtonGroup from '../../../UI/ButtonGroup';
import {
  PromptReleaseStatusFilter,
  PromptAgeRangeFilter,
  PromptListMode,
} from '../../../../utils/constants';
import { setPromptsFilterAction } from '../../../../store/prompts/actions';
import usePromptMode from '../hooks/usePromptMode';

const BUTTON_TO_FILTER_MAP = {
  [PromptListMode.REVIEWING]: {
    releaseStatus: PromptReleaseStatusFilter.UNREVIEWED,
    allowedAgeRanges: null,
  },
  [PromptListMode.RANKING]: {
    releaseStatus: PromptReleaseStatusFilter.APPROVED,
    allowedAgeRanges: PromptAgeRangeFilter.KIDS,
  },
  [PromptListMode.OTHER]: {
    releaseStatus: null,
    allowedAgeRanges: null,
  },
};

const PromptMode = () => {
  const dispatch = useDispatch();
  const { isReviewingMode, isRankingMode } = usePromptMode();
  const activeButton = isReviewingMode
    ? PromptListMode.REVIEWING
    : isRankingMode
    ? PromptListMode.RANKING
    : PromptListMode.OTHER;

  const handleButtonClick = (id) => {
    dispatch(setPromptsFilterAction(BUTTON_TO_FILTER_MAP[id]));
  };

  return (
    <ButtonGroup
      activeButton={activeButton}
      buttons={[
        { id: PromptListMode.REVIEWING, label: 'Reviewing' },
        { id: PromptListMode.RANKING, label: 'Ranking' },
        { id: PromptListMode.OTHER, label: 'Other' },
      ]}
      onButtonClick={handleButtonClick}
    />
  );
};

export default PromptMode;
