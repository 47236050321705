import React, { useState } from 'react';
import styled from 'styled-components';

const StyledShareIcon = styled.img`
  height: 20px;
  width: auto;
  margin-left: 10px;
`;

const ShareIcon = (props) => {
  const [isCopied, setIsCopied] = useState(false);

  let promptUrl = `https://app.zigazoo.com/prompt/${props.promptId}`;
  let shareUrl = '';
  let icon = '';

  switch (props.type) {
    case 'facebook':
      shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${promptUrl}`;
      break;

    case 'twitter':
      shareUrl = `https://twitter.com/intent/tweet?url=${promptUrl}`;
      break;

    default:
      shareUrl = promptUrl; // General case, so prompt url = share url
  }

  const handleClick = (e) => {
    e.stopPropagation();
    if (['facebook', 'twitter'].includes(props.type)) {
      // Social media share
      window.open(shareUrl, '_blank');
    } else {
      // Else we just copy to clipboard
      navigator.clipboard.writeText(shareUrl);
      setIsCopied(true);

      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    }
  };

  return (
    <>
      <StyledShareIcon onClick={handleClick} src={`/images/${props.type}.png`} />
      {isCopied && (
        <span
          style={{
            position: 'relative',
            bottom: '10px',
            left: '5px',
            fontSize: '12px',
            fontWeight: 'bold',
          }}>
          copied!
        </span>
      )}
    </>
  );
};

export default ShareIcon;
