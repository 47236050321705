import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

function PeopleIcon({ color, width, height }) {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      color={color}
      width={width}
      height={height}>
      <path d="M12 16a4 4 0 114-4 4 4 0 01-4 4zm0-6a2 2 0 102 2 2 2 0 00-2-2zm6 13a6 6 0 00-12 0 1 1 0 002 0 4 4 0 018 0 1 1 0 002 0zm0-15a4 4 0 114-4 4 4 0 01-4 4zm0-6a2 2 0 102 2 2 2 0 00-2-2zm6 13a6.006 6.006 0 00-6-6 1 1 0 000 2 4 4 0 014 4 1 1 0 002 0zM6 8a4 4 0 114-4 4 4 0 01-4 4zm0-6a2 2 0 102 2 2 2 0 00-2-2zM2 15a4 4 0 014-4 1 1 0 000-2 6.006 6.006 0 00-6 6 1 1 0 002 0z"></path>
    </Icon>
  );
}

PeopleIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default PeopleIcon;
