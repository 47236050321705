export const GET_INTERESTS = 'GET_INTERESTS';
export const GET_INTERESTS_SUCCESS = 'GET_INTERESTS_SUCCESS';
export const GET_INTERESTS_FAILURE = 'GET_INTERESTS_FAILURE';

export const getInterestsAction = () => ({
  type: GET_INTERESTS,
});

export const getInterestsSuccessAction = (interests) => ({
  type: GET_INTERESTS_SUCCESS,
  interests,
});

export const getInterestsFailureAction = (error) => ({
  type: GET_INTERESTS_FAILURE,
  error,
});
