import React from 'react';
import FormLabel from '../FormLabel/FormLabel';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Disclaimer from '../Disclaimer';
import styled from 'styled-components';

const StyledDatePickerWrapper = styled.div`
  input {
    width: auto;
  }
`;

const DatePickerContainer = (props) => {
  const isFutureTime = (time) => {
    const bufferTimeHrs = 1;
    const unavailableDate = new Date();
    unavailableDate.setHours(unavailableDate.getHours() + bufferTimeHrs);
    const selectedDate = new Date(time);

    return unavailableDate < selectedDate;
  };

  const getLocalTimeFromUtc = (utcHour) => {
    const date = new Date();
    date.setHours(utcHour, 0, 0, 0);

    return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  };

  const minTime = getLocalTimeFromUtc(13); // 13h utc == 9h et
  const maxTime = getLocalTimeFromUtc(22); // 22h utc == 6h et

  return (
    <div>
      <FormLabel label={props.label} />
      {props.withDisclaimer && (
        <Disclaimer style={{ marginTop: 0 }}>
          Depending on the volume of project submissions, this project will be scheduled +/- 2 days
          from the specified date preference. We will do our best to accommodate your preference. If
          no preferred release time is selected, it will automatically be set to 1 hour from the
          time of submission.
        </Disclaimer>
      )}
      <StyledDatePickerWrapper>
        <DatePicker
          name={props.name}
          selected={props.date}
          onChange={props.changed}
          showTimeSelect
          minTime={props.restricted ? minTime : null}
          maxTime={props.restricted ? maxTime : null}
          filterTime={props.restricted ? isFutureTime : () => true}
          dateFormat="MM/dd/yyyy h:mm aa"
        />
      </StyledDatePickerWrapper>
    </div>
  );
};

DatePickerContainer.defaultProps = {
  withDisclaimer: true,
};

export default DatePickerContainer;
