import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const PageThumbnailContainer = styled.div`
  box-sizing: border-box;
`;

const PageThumbnailImage = styled.img`
  border-radius: 10px;
  max-width: 100%;
  height: auto;
`;

const PageThumbnailImageWrapper = styled.div`
  background-color: rgb(243, 246, 249);
  border-radius: 10px;
  padding: 2%;
`;

const PageThumbnailCaption = styled.p`
  width: 100%;
  color: #007bff;
  padding: 10px;
  margin: 0;
  text-decoration: underline;
`;

const PageThumbnailLink = styled(Link)`
  display: block;
  text-align: center;
  position: relative;
`;

const PageThumbnail = ({ caption, image, url }) => {
  return (
    <PageThumbnailContainer>
      <PageThumbnailLink to={url}>
        <PageThumbnailImageWrapper>
          <PageThumbnailImage src={image} alt={caption} />
        </PageThumbnailImageWrapper>
        <PageThumbnailCaption>{caption}</PageThumbnailCaption>
      </PageThumbnailLink>
    </PageThumbnailContainer>
  );
};

PageThumbnail.propTypes = {
  caption: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default PageThumbnail;
