import React from 'react';
import { connect } from 'react-redux';
import { GA_CAROUSEL_EDITOR_IFRAME_URL } from '../../utils/constants';

const GACarouselEditor = (props) => {
  return (
    <div className="Container">
      <h1>Gen Z Carousel Editor</h1>
      <p>
        Note: You <strong>must</strong> have a ReTool account in order to use this page.
      </p>
      <iframe src={GA_CAROUSEL_EDITOR_IFRAME_URL} width="100%" height="800px"></iframe>
    </div>
  );
};

export default GACarouselEditor;
