import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import TextInput from '../../UI/TextInput/TextInput';
import Button from '../../UI/Button/Button';
import Spinner from '../../UI/Spinner/Spinner';
import HorizontalRule from '../../UI/HorizontalRule/HorizontalRule';

import theme from '../../../theme';
import {
  getUserAction,
  clearUserAction,
  updateUserAction,
  deleteUserAction,
  validateUsernameAction,
} from '../../../store/users/actions';
import { getUser } from '../../../store/session/services';

const EditUser = (props) => {
  const [username, setUsername] = useState('');
  const [newUsername, setNewUsername] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    return () => {
      // Reset the user field
      props.clearUser();
    };
  }, []);

  useEffect(() => {
    if (props.justUpdatedUser) {
      setSuccess(true);
    }
  }, [props.justUpdatedUser]);

  const reset = () => {
    props.clearUser();
    setSuccess(false);
    setUsername('');
    setNewUsername('');
  };

  const handleUserLookupChange = (e) => {
    setUsername(e.target.value);
  };

  const handleNewUsernameChange = (e) => {
    const unvalidatedUsername = e.target.value
      .replace(/[^0-9A-Za-zÀ-ÿ]/g, '')
      .toLowerCase()
      .trim();
    props.validateUsername(unvalidatedUsername);
    setNewUsername(unvalidatedUsername);
  };

  const handleUserUpdate = () => {
    props.updateUser(props.user.uid, {
      username: newUsername,
    });
  };

  const renderSubmitButton = () => {
    if (success) {
      return (
        <a
          style={{ color: '#094dac', cursor: 'pointer', fontWeight: 'bold', fontStyle: 'italic' }}
          onClick={reset}>
          Edit another user
        </a>
      );
    }

    return props.user && props.user.uid ? (
      <Button
        style={{ marginTop: '5px' }}
        enabled={newUsername && !props.isValidating && props.validatedUsername}
        title="Update Username"
        clicked={handleUserUpdate}
      />
    ) : (
      <Button
        style={{ marginTop: '10px' }}
        enabled={username !== ''}
        title="Find"
        clicked={() => props.getUser(username)}
      />
    );
  };

  const renderValidationStatus = () =>
    props.isValidating ? (
      <div style={{ marginTop: '10px' }}>
        <Spinner />
      </div>
    ) : (
      <div style={{ marginTop: 29, marginBottom: 16, display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            width: 20,
            height: 20,
            borderRadius: 10,
            marginRight: 5,
            backgroundColor: props.validatedUsername ? theme.color.green : theme.color.red,
          }}
        />
        {props.validatedUsername ? (
          <span style={{ color: theme.color.green }}>Valid</span>
        ) : (
          <span style={{ color: theme.color.red }}>Invalid</span>
        )}
      </div>
    );

  return (
    <div className="Container">
      <h1>Edit User</h1>
      <HorizontalRule />
      {props.user && !props.user.uid ? (
        <TextInput
          name="username"
          placeholder="Enter username"
          style={{ width: '30%' }}
          changed={handleUserLookupChange}
          value={username}
        />
      ) : null}
      {props.user && props.user.username && (
        <>
          <div>
            <span style={{ fontWeight: 'bold' }}>ID: </span>
            {props.user.uid}
          </div>
          <div>
            <span style={{ fontWeight: 'bold' }}>Name: </span>
            {props.user.fullName || '-'}
          </div>
          <div>
            <span style={{ fontWeight: 'bold' }}>User Type: </span>
            {props.user.userType || <span style={{ fontStyle: 'italic' }}>General User</span>}
          </div>
          <div>
            <span style={{ fontWeight: 'bold' }}>Username: </span>
            {props.user.username}
            {success ? (
              <span style={{ color: theme.color.green }}>{` --> ${newUsername}`}</span>
            ) : null}
          </div>
          <div>
            <span style={{ fontWeight: 'bold' }}>Email: </span>
            {props.user.email || '-'}
          </div>
          <div>
            <span style={{ fontWeight: 'bold' }}>Phone Number: </span>
            {props.user.phoneNumber || '-'}
          </div>
          {!success ? (
            <div style={{ height: 90, display: 'flex', flexDirection: 'row' }}>
              <TextInput
                name="newUsername"
                placeholder="New username"
                style={{ width: '95%' }}
                changed={handleNewUsernameChange}
                value={newUsername}
              />
              {newUsername && renderValidationStatus()}
            </div>
          ) : null}
        </>
      )}
      <br />

      {props.loading ? <Spinner /> : renderSubmitButton()}
      {props.error && <p className="Error">{props.error}</p>}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.users.user,
  loading: state.users.loading,
  isValidating: state.users.isValidating,
  validatedUsername: state.users.validatedUsername,
  justUpdatedUser: state.users.justUpdatedUser,
  error: state.users.error,
});

const mapDispatchToProps = (dispatch) => ({
  getUser: (username) => dispatch(getUserAction(username)),
  validateUsername: (username) => dispatch(validateUsernameAction(username)),
  updateUser: (userId, updateRequest) => dispatch(updateUserAction(userId, updateRequest)),
  clearUser: () => dispatch(clearUserAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
