import { put, select } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import { getChannels } from '../prompts/services';
import { setChannelsAction } from '../prompts/actions';
import { getPrompt } from './services';
import { setPromptSuccessAction, setPromptFailureAction } from './actions';

import * as selectors from '../selectors';

export function* getPromptSaga({ promptId }) {
  try {
    // If the user is an admin, we get the channel names first
    const userType = yield select(selectors.userType);

    if (userType === 'ADMIN') {
      const channels = yield getChannels();
      yield put(setChannelsAction(channels));
    }

    const prompt = yield getPrompt(promptId);

    yield put(setPromptSuccessAction(prompt));
  } catch (e) {
    yield put(setPromptFailureAction(e));
    console.log(e);
    Sentry.captureException(e);
  }
}
