import * as Sentry from '@sentry/react';
import { httpsCallable } from '../../../utils/functions';

const MODERATION_FEED_LIMIT = 50;

export const getAllModerationFeed = async () => {
  try {
    const response = await httpsCallable('getPortalModerationFeed', {
      limit: MODERATION_FEED_LIMIT,
    });
    const feed = response.data?.feed;
    const hasNextRecords = feed?.length === MODERATION_FEED_LIMIT;

    return [feed, hasNextRecords];
  } catch (e) {
    console.error(e);
    Sentry.captureException(e);
    throw new Error(e);
  }
};
