import { CometChat } from '@cometchat-pro/chat';
import { isStaging } from '../utils/constants';

// Comet Chat configuration and initialization
const cometChatApiKey = isStaging
  ? '817daf7c6422139ab0558f2655085e4b94b77a18'
  : 'a2fd71b03e88d274e1ab525d61ea25e5b92813b9'; // TODO: Move to Secret Manager
const appID = isStaging ? '291091c3e91dbac' : '29108dd42618031'; // TODO: Move to Secret Manager
const region = 'us'; // TODO: Move to Secret Manager

const appSetting = new CometChat.AppSettingsBuilder()
  .subscribePresenceForAllUsers()
  .setRegion(region)
  .build();

CometChat.init(appID, appSetting).then(
  () => {
    console.log('CometChat Initialization completed successfully');
  },
  (error) => {
    console.log('CometChat initialization failed with error:', error);
  }
);

export { cometChatApiKey };
