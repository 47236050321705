import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

const ArrowDownIcon = ({ color, width, height }) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    color={color}
    width={width}
    height={height}>
    <g>
      <path d="M12,15.5a1.993,1.993,0,0,1-1.414-.585L5.293,9.621,6.707,8.207,12,13.5l5.293-5.293,1.414,1.414-5.293,5.293A1.993,1.993,0,0,1,12,15.5Z" />
    </g>
  </Icon>
);

ArrowDownIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default ArrowDownIcon;
