import React from 'react';
import FormLabel from '../FormLabel/FormLabel';
import { Range } from 'rc-slider';
import './SliderContainer.css';
import { GradeLevelMarks } from '../../../utils/constants';

import 'rc-slider/assets/index.css';

const SliderContainer = (props) => {
  return (
    <div>
      <FormLabel label={props.label} />
      {props.readOnly ? (
        <p className="readOnlyStyle">{`${GradeLevelMarks[props.default[0]]} - ${
          GradeLevelMarks[props.default[1]]
        }`}</p>
      ) : (
        <Range
          marks={props.marks}
          min={props.min}
          max={props.max}
          defaultValue={props.default}
          onChange={props.changed}
        />
      )}
    </div>
  );
};

export default SliderContainer;
