import React from 'react';
import styled from 'styled-components';
import { CenteredLayout } from '../components/UI/Layouts';
import Spinner from '../components/UI/Spinner/Spinner';
import UsersList, { UsersListSearchBar } from '../components/Users/UsersList';

const StyledHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  @media (max-width: 991px) {
    align-items: stretch;
    flex-direction: column;
    gap: 10px;
  }
`;

const UsersListPage = () => (
  <div className="Container">
    <StyledHeader>
      <h1>Users</h1>
      <UsersListSearchBar />
    </StyledHeader>
    <UsersList />
  </div>
);

export default UsersListPage;
