import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.15em;
  padding: 13px 35px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  color: black;
  background-color: ${(props) => (props.disabled ? 'lightgrey' : '#ffc107')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  &:hover {
    opacity: ${(props) => (props.disabled ? 1 : 0.9)};
  }
  &:focus {
    outline: 0;
  }
`;

const Button = ({ name, className, style, enabled, clicked, children, title, ...props }) => {
  return (
    <StyledButton
      name={name}
      className={className}
      style={style}
      disabled={!enabled}
      onClick={clicked}
      {...props}>
      {children || title}
    </StyledButton>
  );
};

Button.defaultProps = {
  children: null,
  className: '',
  style: {},
  enabled: true,
};

export default Button;
