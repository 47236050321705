import {
  GET_MODERATION_FEED_FAILURE,
  GET_MODERATION_FEED_SUCCESS,
  GET_MODERATION_FEED,
} from './actions';

const decorateModerationFeed = (feed) => {
  return feed.map((item) => ({
    rawData: item,
    id: item.id,
    isLoading: false,
    error: null,
  }));
};

const defaultState = {
  error: null,
  isLoading: false,
  hasNextRecords: true,
  feed: [],
};

export const moderationFeed = (state = defaultState, action) => {
  switch (action.type) {
    case GET_MODERATION_FEED: {
      return { ...state, isLoading: true };
    }

    case GET_MODERATION_FEED_SUCCESS:
      return {
        ...state,
        feed: decorateModerationFeed(action.feed || []),
        isLoading: false,
        hasNextRecords: action.hasNextRecords,
      };

    case GET_MODERATION_FEED_FAILURE:
      return { ...state, isLoading: false, error: action.error };

    default:
      return state;
  }
};
