import {
  PromptAgeRangeFilter,
  PromptReleaseStatusFilter,
  PromptListSort,
} from '../../utils/constants';
import {
  CREATE_PROMPT,
  CREATE_PROMPT_SUCCESS,
  CREATE_PROMPT_FAILURE,
  SET_PROMPT_TYPES,
  CLEAR_NOTIFICATION_MESSAGE,
  GET_PROMPTS,
  SET_PROMPTS,
  UPDATE_PROGRESS_BAR,
  UPDATE_SEND_TO_PROMPTS,
  SET_CHANNELS,
  SET_PROMPTS_FILTER,
  SET_PROMPTS_SEARCH_TERM,
  PREVENT_PROMPTS_LOAD,
  UPDATE_PROMPT_RANKS,
  UPDATE_PROMPT_RANKS_SUCCESS,
  UPDATE_PROMPT_RANKS_FAILURE,
} from './actions';

const defaultState = {
  isLoading: false,
  message: '',
  title: '', // message, notificationType and title are for notifications, which we'll refactor and/or couple together later
  notificationType: 'success',
  promptTypes: [],
  prompts: [],
  preventPromptsLoad: false,
  hasPrompts: false,
  percentage: '0',
  sendToPrompts: false,
  lastUpdated: Date.now(),
  channels: [],
  searchTerm: null,
  rankingPromptId: null,
  // NOTE: the Reviewing status by default
  sort: [PromptListSort.LATEST_SCHEDULED],
  filters: {
    allowedAgeRanges: null,
    // NOTE: the Reviewing status by default
    releaseStatus: PromptReleaseStatusFilter.UNREVIEWED,
  },
};

export const prompts = (state = defaultState, action) => {
  switch (action.type) {
    case CREATE_PROMPT:
      return { ...state, isLoading: true };

    case CREATE_PROMPT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        title: action.title,
        message: action.message,
        notificationType: 'success',
        sendToPrompts: true,
        percentage: '0',
      };

    case CREATE_PROMPT_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.message,
        title: action.title,
        sendToPrompts: true,
        notificationType: 'error',
        percentage: '0',
      };

    case SET_PROMPT_TYPES:
      return { ...state, promptTypes: action.promptTypes };

    case CLEAR_NOTIFICATION_MESSAGE:
      return { ...state, message: '', title: '', notificationType: 'success' };

    case GET_PROMPTS:
      return { ...state, isLoading: true };

    case SET_PROMPTS:
      return {
        ...state,
        prompts: action.prompts,
        hasPrompts: action.prompts.some((type) => type.prompts.length > 0),
        isLoading: false,
        ...(action.force ? {} : { lastUpdated: Date.now() }),
      };

    case UPDATE_PROGRESS_BAR:
      return { ...state, percentage: action.percentage };

    case UPDATE_SEND_TO_PROMPTS:
      return { ...state, sendToPrompts: action.sendToPrompts };

    case SET_CHANNELS:
      return { ...state, channels: action.channels };

    case SET_PROMPTS_FILTER:
      return {
        ...state,
        preventPromptsLoad: false,
        filters: {
          ...state.filters,
          ...action.filter,
        },
        // NOTE: the sequence of the sort array matters
        sort: (() => {
          const sort = [];
          const releaseStatus = action.filter.releaseStatus || state.filters.releaseStatus;
          const allowedAgeRanges = action.filter.allowedAgeRanges || state.filters.allowedAgeRanges;

          // NOTE: in Reviewing mode, we sort by latest scheduled first
          if (releaseStatus === PromptReleaseStatusFilter.UNREVIEWED) {
            sort.push(PromptListSort.LATEST_SCHEDULED);
          }

          switch (allowedAgeRanges) {
            case PromptAgeRangeFilter.KIDS:
              sort.push(PromptListSort.TOP_KIDS_RANKED);
              break;
            case PromptAgeRangeFilter.GA:
              sort.push(PromptListSort.TOP_GA_RANKED);
              break;
            default:
          }

          // NOTE: in Ranking mode, we sort by latest scheduled last
          if (releaseStatus === PromptReleaseStatusFilter.APPROVED) {
            sort.push(PromptListSort.LATEST_SCHEDULED);
          }

          return sort;
        })(),
      };

    case SET_PROMPTS_SEARCH_TERM:
      return {
        ...state,
        preventPromptsLoad: false,
        searchTerm: action.searchTerm,
        isLoading: !action.searchTerm,
      };

    case PREVENT_PROMPTS_LOAD:
      return {
        ...state,
        preventPromptsLoad: true,
      };

    case UPDATE_PROMPT_RANKS:
      return {
        ...state,
        isUpdatingRanks: true,
        rankingPromptId: action.promptId,
      };

    case UPDATE_PROMPT_RANKS_SUCCESS:
      return {
        ...state,
        isUpdatingRanks: false,
        rankingPromptId: null,
      };

    case UPDATE_PROMPT_RANKS_FAILURE:
      return {
        ...state,
        isUpdatingRanks: false,
        rankingPromptId: null,
        message: action.error,
        notificationType: 'failure',
      };

    default:
      return state;
  }
};
