import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import pick from 'lodash/pick';
import TextInput from '../../UI/TextInput/TextInput';
import Dropdown from '../../UI/Dropdown/Dropdown';
import TextArea from '../../UI/TextArea/TextArea';
import Button from '../../UI/Button/Button';
import DatePickerContainer from '../../UI/DatePickerContainer/DatePickerContainer';
import TagsContainer from './Tags/TagsContainer/TagsContainer';
import HorizontalRule from '../../UI/HorizontalRule/HorizontalRule';
import FileInput from '../../UI/FileInput/FileInput';
import Spinner from '../../UI/Spinner/Spinner';
import SliderContainer from '../../UI/Sliders/SliderContainer';
import SingleSelectSlider from '../../UI/Sliders/SingleSelectSlider';
import MediaType from '../PromptForm/MediaType/MediaType';
import Checkbox from '../../UI/Checkbox/Checkbox';
import FormLabel from '../../UI/FormLabel/FormLabel';
import { getInterestsAction } from '../../../store/interests/actions';
import {
  createPromptAction,
  getAllPromptTypesAction,
  getPromptsAction,
  assignPromptToGroupAction,
  updateSendToPromptsAction,
  updateLocalPromptAction,
} from '../../../store/prompts/actions';
import * as Constants from '../../../utils/constants';
import { Line } from 'rc-progress';
import { Routes, AgeRange as AgeRangeConst, PromptMediaType } from '../../../utils/constants';
import moment from 'moment';
import styled from 'styled-components';
import theme from '../../../theme';
import AgeRange from './AgeRange';
import PromptFormPullUpButtons from './PromptFormPullUpButtons';
import Poll from './Poll';
import usePromptFormState from './hooks/usePromptFormState';
import PollTypeField from './Poll/PollTypeField';
import RewardDetails from './RewardDetails';
// import HashTagsNestedForm from './Tags/HashTags/HashTagsNestedForm';
import { convertTimeToMilliseconds } from '../../../utils/functions';

const StyledNotificationBanner = styled.div`
  border-radius: 5px;
  margin: auto;
  padding: 2%;
  margin-bottom: 10px;
  background-color: ${(props) => {
    if (props.isWarning) {
      return theme.color.red;
    } else if (props.isSubtle) {
      return theme.color.mediumGrey;
    } else {
      return theme.color.blue;
    }
  }};
  font-size: 0.9em;
  color: white;
  text-align: center;
`;

const StyledImage = styled.img`
  height: 100px;
  margin-right: 5px;
  border-radius: 5px;
`;

const StyledActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  flex-direction: column;
  row-gap: 15px;
  column-gap: 15px;
`;

const NONE_SELECTED = 'Select a Subject';

const PromptForm = (props) => {
  const history = useHistory();
  const { isEditing, userType, prompt } = props;
  const isResubmissionEditMode = isEditing && prompt.failed;
  const isAdmin = userType === Constants.UserTypes.ADMIN;
  const [formState, setFormState, resetForm] = usePromptFormState(
    { promptTypes: props.promptTypes, interests: props.interests, ...prompt },
    {
      isEditing,
      isAdmin,
    }
  );

  useEffect(() => {
    // Sends users back to the main prompt screen if a prompt was created or edited
    if (props.sendToPrompts) {
      resetForm();
      history.push(Routes.PROMPTS);
      props.updateSendToPrompts(false);
    }
  }, [props.sendToPrompts]);

  useEffect(() => {
    // Get prompt types if needed
    if (props.promptTypes.length === 0) {
      props.getPromptTypes();
      return;
    }

    setFormState({
      ...formState,
      promptTypes: props.promptTypes,
      promptTypeId: isEditing ? formState.promptTypeId : null,
    });
  }, [props.promptTypes]);

  useEffect(() => {
    // Get interests if needed
    if (props.interests.length === 0) {
      props.getInterests();
      return;
    }

    setFormState({
      ...formState,
      interests: props.interests,
      interestId: isEditing ? formState.interestId : undefined,
    });
  }, [props.interests]);

  useEffect(() => {
    // Resets form when going from edit to create
    if (!isEditing) {
      resetForm();
    }
  }, [isEditing]);

  const [useUploadedThumbnail, setUseUploadedThumbnail] = useState(true);
  const [submitIsProcessing, setSubmitProcessingState] = useState(false);
  const [isInvalidFormat, setInvalidFormat] = useState(false);
  const hasPollFormat = formState.mediaType === PromptMediaType.POLL;
  const hasVideoFormat = formState.mediaType === PromptMediaType.VIDEO;
  const hasImageFormat = formState.mediaType === PromptMediaType.IMAGE;

  const isLive =
    Date.now() > formState.scheduledDate &&
    formState.reviewStatus !== Constants.PromptReviewStatus.DENIED &&
    formState.reviewStatus !== Constants.PromptReviewStatus.UNREVIEWED;
  const isReadOnly = isEditing && isLive && !isAdmin;

  // Flag for whether user can change the scheduled date (used to prevent non-admins from changing the scheduled date after a prompt is live)
  const canChangeScheduledDate = isAdmin || !isEditing || !isLive;

  // useEffect(() => {
  //   setFormState({
  //     ...formState,
  //     hashTags: isEditing ? prompt.hashTags : [],
  //   });
  // }, [formState.allowedAgeRanges]);

  // Changes the scheduled date for prompt based on datepicker selection
  const handleScheduledDateChange = (date) => {
    setFormState({ ...formState, scheduledDate: date.getTime() });
  };

  // Handles an updated tag using its index to update it in the state
  const handleTagChange = (event) => {
    const newTags = [...formState.tags];
    newTags[event.target.getAttribute('data-tag-id')] = event.target.value.toLowerCase();

    setFormState({ ...formState, tags: newTags });
  };

  const handleAllowedAgeRangesChange = (newAllowedAgeRanges) => {
    setFormState({ ...formState, allowedAgeRanges: newAllowedAgeRanges });
  };

  // Handles image being selected from local disk
  const handleImageChange = (event) => {
    const imageFile = event.target.files[0];
    const isValidExtension = imageFile?.name.match(/\.(jpeg|jpg|png)$/i);

    if (imageFile && isValidExtension) {
      setInvalidFormat(false);
      return setFormState({ ...formState, image: imageFile });
    }

    setInvalidFormat(true);
    return setFormState({ ...formState, image: null });
  };

  // Handles video being selected from local disk
  const handleVideoChange = (event) => {
    if (event.target.files[0]) {
      setFormState({ ...formState, video: event.target.files[0] });
    }
  };

  // Handles changes to input, textarea and select (dd) fields
  const handleInputChange = (event) => {
    const newState = { ...formState };
    const { value, name } = event.target;

    if (name === 'promptTypeId') {
      const currentValue = value === NONE_SELECTED ? null : value;

      if (value === NONE_SELECTED) {
        newState.promptTypeId = null;
        newState.type = null;
      } else {
        // If changing type, we update both the type id and the type
        newState.promptTypeId = currentValue;

        const selectedIndex = event.nativeEvent.target.selectedIndex;
        const selectedLabel = event.nativeEvent.target[selectedIndex].text;

        newState.type = selectedLabel;
      }
    } else if (name === 'interests') {
      newState.interestId = value;
    } else {
      newState[name] = value;
    }
    setFormState(newState);
  };

  const handleRequirementChange = (event) => {
    const convertToNumber = ['minShoutout'];
    const newState = {
      ...formState,
      requirements: {
        ...formState.requirements,
        [event.target.name]: convertToNumber.includes(event.target.name)
          ? Number(event.target.value)
          : event.target.value,
      },
    };

    setFormState(newState);
  };

  // const handleHashTagsChange = (hashTags) => {
  //   setFormState({ ...formState, hashTags });
  // };

  // Handles slider change for grade levels, keeping track of both min and max
  const handleGradeLevelChange = (event) => {
    setFormState({ ...formState, gradeMin: event[0], gradeMax: event[1] });
  };

  // Handles radio button changes for media type
  const handleMediaTypeChange = (event) => {
    if (!isEditing) {
      setFormState({
        ...formState,
        mediaType: event.target.value,
        video: null,
        image: null,
      });
      setInvalidFormat(false);
    }
  };

  // Handles change in cover image check box
  const handleUseUploadedThumbnailToggle = (event) => {
    setUseUploadedThumbnail(!event.target.checked);
    setFormState({
      ...formState,
      image: null,
    });
  };

  const handleExternalShoutoutTogggle = (event) => {
    handleRequirementChange({
      target: {
        name: 'externalShoutoutsOnly',
        value: event.target.checked,
      },
    });
  };

  const handleRandomizePollAnswersToggle = (event) => {
    setFormState({
      ...formState,
      randomizePollAnswers: event.target.checked,
    });
  };

  const handleHideLeaderboardToggle = (event) => {
    setFormState({
      ...formState,
      hideLeaderboard: event.target.checked,
    });
  };

  const handlePollAnswersChange = (pollAnswers) => {
    setFormState({
      ...formState,
      pollAnswers,
    });
  };

  const handleRewardDetailsChange = (rewardDetails) => {
    setFormState({
      ...formState,
      rewardDetails,
    });
  };

  const handlePollTypeChange = (pollType) => {
    setFormState({ ...formState, pollType });
  };

  const getLocalTimeFromUtc = (utcHour) => {
    const date = new Date();
    date.setHours(utcHour, 0, 0, 0);

    return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  };

  const getValidScheduleTime = (scheduled, windowStartHrUtc, windowEndHrUtc) => {
    // Must be at least 1 hr from now
    const minimum = moment();
    minimum.add(1, 'hours');

    const windowStart = moment(getLocalTimeFromUtc(windowStartHrUtc));
    const windowEnd = windowStart.clone().add(windowEndHrUtc - windowStartHrUtc, 'hours');

    const validated = moment.max(moment(new Date(scheduled)), windowStart, minimum);

    if (windowStart.add(1, 'days') > validated && validated > windowEnd) {
      validated.add(1, 'days');
      validated.set({
        hour: windowStart.hour(),
        minute: 0,
        second: 0,
      });
    }

    return validated.valueOf();
  };

  const ownerAvailableAgeRanges = useMemo(() => {
    let availableAgeRanges = null;

    if (isEditing && isAdmin) {
      availableAgeRanges = props.channels.find(({ uid }) => formState.userId === uid)
        ?.allowedAgeRanges || [AgeRangeConst.KIDS, AgeRangeConst.GA];
    } else if (isAdmin) {
      availableAgeRanges = [AgeRangeConst.KIDS, AgeRangeConst.GA];
    } else {
      availableAgeRanges = props.sessionAllowedAgeRanges;
    }

    return availableAgeRanges || [AgeRangeConst.KIDS];
  }, [formState.userId, props.channels, props.sessionAllowedAgeRanges]);

  // Used for both creating and editing prompts
  const createPrompt = (reviewStatus) => {
    // If the prompt is read-only for this user, we return
    if (isReadOnly) {
      return;
    }

    // Filter out empty tags
    const newState = { ...formState };
    newState.tags = newState.tags.filter((t) => t);

    // Add first category, and second category if selected
    newState.categories.push(newState.firstCategory);
    if (formState.secondCategory !== Constants.PromptDefaults.SECOND_CATEGORY) {
      newState.categories.push(newState.secondCategory);
    }

    // If there's a resource link url, we check that it's valid and add the URL protocol if necessary
    if (newState.lessonPlanUrl !== '') {
      // First, we check if the URL is valid. If not, we display an alert and return
      const validUrlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
      if (!validUrlRegex.test(newState.lessonPlanUrl)) {
        alert('Please enter a valid resource link URL or remove it altogether.');
        return;
      }

      // Then, if it's valid but it doesn't have https or http, we add it
      if (
        !newState.lessonPlanUrl.includes('https://') &&
        !newState.lessonPlanUrl.includes('http://')
      ) {
        newState.lessonPlanUrl = `https://${newState.lessonPlanUrl}`;
      }
    }

    if (!isAdmin) {
      // 13h utc == 9h et, 22h utc == 6h et
      newState.scheduledDate = getValidScheduleTime(newState.scheduledDate, 13, 22);
    } else {
      if (!newState.allowedAgeRanges.length) {
        alert('Please, choose the age range.');
        return;
      }
    }

    if (hasPollFormat) {
      const isInvalidPoll =
        !newState.pollAnswers.length ||
        newState.pollAnswers.some((answer) => {
          const isTextEmpty = !answer.answerText.trim();
          const hasNewImage = Boolean(answer.image);
          const hasPersistedImage = Boolean(answer.answerImgUrl);

          if (newState.pollType === Constants.PollType.TEXT) {
            return isTextEmpty;
          }

          return isTextEmpty || (!hasNewImage && !hasPersistedImage);
        });

      if (isInvalidPoll) {
        alert('Please, fill the poll answers.');
        return;
      }
    }

    if (isAdmin) {
      const isRewardEmpty = Object.values(newState.rewardDetails).every((value) => !Boolean(value));

      if (!isRewardEmpty) {
        const isInvalidReward = Object.values(newState.rewardDetails).some(
          (value) => !Boolean(value)
        );

        if (isInvalidReward) {
          alert('Please, fill the reward details.');
          return;
        }
      }
    }

    newState.reviewStatus = reviewStatus;
    setSubmitProcessingState(true);

    props.createPrompt(newState, isEditing);
  };

  const renderMediaUploadSection = () => {
    const videoThumbnailSpecs = (
      <StyledNotificationBanner
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        isSubtle>
        <StyledImage
          src={'https://storage.googleapis.com/zigazoo-public/admin_portal/admin-thumb-07.png'}
        />
        <div style={{ marginLeft: '10px', textAlign: 'left' }}>
          <span style={{ fontSize: '14px' }}>Aspect Ratio: 0.7</span>
          <br />
          <span style={{ fontSize: '14px' }}>Ex: 500px x 720px</span>
        </div>
      </StyledNotificationBanner>
    );

    const generatedThumbnailWarning = (
      <StyledNotificationBanner style={{ display: 'flex', flexDirection: 'row' }} isWarning>
        <img
          style={{ borderRadius: '5px', height: '100px' }}
          src="https://storage.googleapis.com/zigazoo-public/admin_portal/project-auto-thumb.png"
        />
        <div style={{ maxWidth: '500px', margin: 'auto' }}>
          <span style={{ fontWeight: 'bold', fontSize: '18px' }}>⚠️ WARNING! </span>
          <br />
          <span>
            A generated thumbnail will be cropped from the center and may NOT look good. This is not
            editable after the challenge is published.
          </span>
        </div>
      </StyledNotificationBanner>
    );

    const getWatermarkUrl = () => {
      for (let property of [
        'watermarkUrl',
        'muxStaticHighWatermarkUrl',
        'muxStaticMediumWatermarkUrl',
        'muxStaticLowWatermarkUrl',
      ]) {
        if (!!formState[property]) {
          return formState[property];
        }
      }

      return null;
    };

    const getVideoUrl = () => {
      const watermarkUrl = getWatermarkUrl();

      if (watermarkUrl) {
        return watermarkUrl;
      } else if (isEditing && formState.video_url) {
        return formState.video_url;
      }
      return '';
    };

    return (
      <>
        {!isReadOnly &&
          (isAdmin ? (
            <MediaType
              label={<span className="mandatory-field">Project Format</span>}
              type={formState.mediaType}
              changed={handleMediaTypeChange}
            />
          ) : (
            <FormLabel label={<span className="mandatory-field">Video</span>} />
          ))}
        {isResubmissionEditMode ? (
          <div
            style={{
              backgroundColor: theme.color.lightPink,
              color: theme.color.red,
              borderRadius: '5px',
              padding: '8px 12px',
            }}>
            There was an error uploading your video. Re-select your video file, submit the update,
            and <strong>DO NOT</strong> close your browser window until the upload has completed.
          </div>
        ) : null}
        {(hasVideoFormat || hasPollFormat) && (
          <>
            <FileInput
              changed={handleVideoChange}
              key={props.message + formState.mediaType + '_video'}
              accept="video/mp4,video/x-m4v,video/*"
              hyperlink={getVideoUrl()}
              readOnly={isReadOnly}
            />
            <FormLabel label={<span className="mandatory-field">Thumbnail</span>} />
          </>
        )}
        {!isReadOnly && (hasVideoFormat || hasPollFormat) && useUploadedThumbnail
          ? videoThumbnailSpecs
          : null}
        {(hasImageFormat && isAdmin) ||
        ((hasVideoFormat || hasPollFormat) && useUploadedThumbnail) ? (
          <>
            <FileInput
              changed={handleImageChange}
              aspectRatio={1.2}
              key={props.message + formState.mediaType}
              accept="image/jpeg,image/png"
              url={
                formState.image
                  ? URL.createObjectURL(formState.image)
                  : isEditing
                  ? prompt.thumbnail_1200_url
                    ? prompt.thumbnail_1200_url
                    : prompt.url
                  : ''
              }
              readOnly={isReadOnly}
            />
            {isInvalidFormat && (
              <StyledNotificationBanner isWarning>
                Please upload the following file types: .jpeg, .jpg, .png.
              </StyledNotificationBanner>
            )}
          </>
        ) : (
          generatedThumbnailWarning
        )}
        {!isReadOnly && (hasVideoFormat || hasPollFormat) && (
          <div>
            <br />
            <Checkbox
              id="cover-image"
              label={
                <span>
                  <strong>Use first frame of video as thumbnail</strong>
                </span>
              }
              changed={handleUseUploadedThumbnailToggle}
              checked={!useUploadedThumbnail}
            />
          </div>
        )}
      </>
    );
  };

  const isNewPromptValid = (isReviewer) => {
    const hasVideoAndThumbnail = formState.video && (formState.image || !useUploadedThumbnail);
    const hasValidVideoData = hasVideoFormat && hasVideoAndThumbnail;
    const hasValidImageData = hasImageFormat && isReviewer && Boolean(formState.image);
    const hasValidPollData = hasPollFormat && hasVideoAndThumbnail;

    return (
      !props.isEditing &&
      (hasValidVideoData || hasValidImageData || hasValidPollData) &&
      formState.promptTypeId &&
      formState.name &&
      formState.instructions &&
      !!formState.allowedAgeRanges.length
    );
  };

  const GetSubmitButtons = (props) => {
    if (isReadOnly) {
      return <Button title="Done" clicked={() => history.push(Routes.PROMPTS)} enabled />;
    }

    const reviewerAction = props.isEditing
      ? formState.reviewStatus
      : Constants.PromptReviewStatus.APPROVED;

    const isFormValid =
      !submitIsProcessing &&
      (Boolean(props.isEditing && (formState.video_url || formState.url)) ||
        isNewPromptValid(props.isReviewer) ||
        Boolean(formState.video && props.isResubmissionEdit));

    const basicOption = (
      <Button
        title={props.isEditing ? 'Update Project' : 'Submit Project'}
        enabled={isFormValid}
        clicked={() =>
          createPrompt(props.isReviewer ? reviewerAction : Constants.PromptReviewStatus.UNREVIEWED)
        }
      />
    );

    if (props.isReviewer && props.isEditing && !props.isLive) {
      return (
        <span style={{ display: 'flex' }}>
          {basicOption}
          <Button
            style={{ marginLeft: '10px', marginRight: '10px', backgroundColor: '#28a745' }}
            title={'Approve Project'}
            enabled={!submitIsProcessing && (formState.video_url || formState.url)}
            clicked={() => createPrompt(Constants.PromptReviewStatus.APPROVED)}
          />
          <Button
            style={{ backgroundColor: '#ff5454' }}
            title={'Reject Project'}
            enabled={!submitIsProcessing && (formState.video_url || formState.url)}
            clicked={() => createPrompt(Constants.PromptReviewStatus.DENIED)}
          />
        </span>
      );
    } else {
      return <span>{basicOption}</span>;
    }
  };

  return (
    <div className="Container">
      {formState.id &&
        formState.reviewStatus !== Constants.PromptReviewStatus.DENIED &&
        !isResubmissionEditMode &&
        isEditing && (
          <StyledNotificationBanner>
            {isLive
              ? 'Your prompt is accessible at'
              : 'Once this prompt is live, it will be accessible at'}
            <br />
            <strong>{`\nhttps://app.zigazoo.com/prompt/${formState.id}`}</strong>
          </StyledNotificationBanner>
        )}
      {formState.reviewStatus === Constants.PromptReviewStatus.DENIED &&
        isEditing &&
        !isReadOnly && (
          <StyledNotificationBanner isWarning>
            ⚠️ This challenge is currently under review. Please await further instructions.
          </StyledNotificationBanner>
        )}
      {formState.reviewStatus === Constants.PromptReviewStatus.APPROVED &&
        isEditing &&
        !isReadOnly && (
          <StyledNotificationBanner isWarning>
            ⚠️ <strong>This prompt has already been reviewed and approved.</strong> If you edit your
            project the after 9 ET the night before it is scheduled to post, please text
            347-977-0039 so that our Education Team can re-approve it. Otherwise it will not post.
            Thank you!
          </StyledNotificationBanner>
        )}
      <h2> {isEditing ? (isReadOnly ? formState.name : 'Edit Project') : 'Create New Project'} </h2>
      <HorizontalRule />
      {props.promptTypes.length == 0 ? (
        <Spinner />
      ) : (
        <Dropdown
          name="promptTypeId"
          options={[
            { name: NONE_SELECTED },
            ...formState.promptTypes.map(([value, id, allowedAgeRanges]) => {
              return {
                id,
                name: value,
                label: `[${(allowedAgeRanges || ['KIDS']).join(', ')}] `,
              };
            }),
          ]}
          label={<span className="mandatory-field">Subject</span>}
          changed={handleInputChange}
          value={formState.promptTypeId}
          readOnly={isReadOnly}
        />
      )}
      <TextInput
        label={<span className="mandatory-field">Project Question</span>}
        name="name"
        changed={handleInputChange}
        value={formState.name}
        maxlength={100}
        readOnly={isReadOnly}
      />
      <TextArea
        label={<span className="mandatory-field">Instructions</span>}
        name="instructions"
        changed={handleInputChange}
        value={formState.instructions}
        maxlength={280}
        readOnly={isReadOnly}
      />
      <br />
      <HorizontalRule />

      {renderMediaUploadSection()}
      <br />
      <HorizontalRule />
      {hasPollFormat && (
        <>
          <PollTypeField
            pollType={formState.pollType}
            isDisabled={isEditing}
            onChange={handlePollTypeChange}
          />
          <Poll
            pollType={formState.pollType}
            pollAnswers={formState.pollAnswers}
            onUpdate={handlePollAnswersChange}
          />
          <br />
          <Checkbox
            id="poll-answers-randomize"
            label={
              <span>
                <strong>Show in Random Order</strong>
              </span>
            }
            changed={handleRandomizePollAnswersToggle}
            checked={!!formState.randomizePollAnswers}
          />
          <br />
          <HorizontalRule />
        </>
      )}
      {props.interestsAreLoading ? (
        <Spinner />
      ) : (
        <Dropdown
          name="interests"
          options={[
            { name: NONE_SELECTED },
            ...formState.interests.map((interest) => {
              return {
                id: interest.id,
                name: interest.name,
              };
            }),
          ]}
          label="Interest Category"
          changed={handleInputChange}
          value={formState.interestId}
          readOnly={isReadOnly}
        />
      )}

      <br />
      <HorizontalRule />
      <SliderContainer
        label={<span className="mandatory-field">Grade Levels</span>}
        changed={handleGradeLevelChange}
        marks={Constants.GradeLevelMarks}
        min={Constants.PromptDefaults.GRADE_LOWEST}
        max={Constants.PromptDefaults.GRADE_HIGHEST}
        default={[formState.gradeMin, formState.gradeMax]}
        readOnly={isReadOnly}
      />
      {!isReadOnly && (
        <div>
          <br />
        </div>
      )}
      {!isReadOnly && canChangeScheduledDate && (
        <DatePickerContainer
          name="scheduledDate"
          label={<span className="mandatory-field">Date Preference (use your local time)</span>}
          date={formState.scheduledDate}
          changed={handleScheduledDateChange}
          restricted={!isAdmin}
        />
      )}
      {isAdmin && (
        <>
          <br />
          <HorizontalRule />
          <AgeRange
            ageRanges={formState.allowedAgeRanges}
            availableAgeRanges={ownerAvailableAgeRanges}
            onChange={handleAllowedAgeRangesChange}
          />
        </>
      )}
      <br />
      <HorizontalRule />
      {isAdmin && isEditing && (
        <TextInput
          label="User ID (optional)"
          name="userId"
          changed={handleInputChange}
          value={formState.userId}
          maxlength={50}
          placeholder="e.g. 8WOYVwBBfoffB4uqTvEcKZeFBa92"
        />
      )}
      <TextInput
        label="Resource Link (optional)"
        name="lessonPlanUrl"
        changed={handleInputChange}
        value={formState.lessonPlanUrl}
        maxlength={200}
        readOnly={isReadOnly}
        hyperlink={(isEditing && prompt.lessonPlanUrl) || ''}
        hyperlinkName="resource link"
        placeholder="e.g. zigazoo.com"
        isUrl={true}
      />
      {isAdmin && !hasPollFormat && (
        <SingleSelectSlider
          label="Minimum Number of Shoutouts (optional)"
          min={Constants.PromptDefaults.SHOUTOUT_MIN}
          max={Constants.PromptDefaults.SHOUTOUT_MAX}
          marks={Constants.SHOUTOUT_SLIDER_MARKS}
          defaultValue={Constants.PromptDefaults.SHOUTOUT_MIN}
          value={formState.requirements.minShoutout}
          onChange={(value) => handleRequirementChange({ target: { name: 'minShoutout', value } })}
        />
      )}
      {isAdmin && (
        <Checkbox
          id="external-shoutouts"
          label={
            <span>
              <strong>External Shoutouts ONLY</strong>
            </span>
          }
          changed={handleExternalShoutoutTogggle}
          checked={!!formState.requirements?.externalShoutoutsOnly}
          style={{ marginTop: '40px' }}
        />
      )}
      {!isReadOnly && isAdmin && !hasPollFormat && (
        <TextInput
          label="Share Name (optional)"
          name="shareName"
          changed={handleInputChange}
          value={formState.shareName}
          maxlength={100}
          readOnly={isReadOnly}
        />
      )}

      {/* <HashTagsNestedForm
        label="Hash Tags (optional)"
        allowedAgeRanges={formState.allowedAgeRanges}
        hashTags={formState.hashTags}
        onChange={handleHashTagsChange}
      /> */}

      {isAdmin && (
        <>
          <br />
          <HorizontalRule />
          <RewardDetails
            rewardDetails={formState.rewardDetails}
            onUpdate={handleRewardDetailsChange}
          />
          <br />
          <HorizontalRule />
        </>
      )}

      <TagsContainer
        label="Keyword Tags (optional)"
        totalTags={Constants.PromptDefaults.TOTAL_TAGS}
        handler={handleTagChange}
        tags={formState.tags}
        readOnly={isReadOnly}
      />

      {isAdmin && (
        <Checkbox
          id="has-leaderboard"
          label={
            <span>
              <strong>Hide Leaderboard</strong>
            </span>
          }
          changed={handleHideLeaderboardToggle}
          checked={!!formState.hideLeaderboard}
          style={{ marginTop: '40px' }}
        />
      )}

      {props.percentage !== '0' ? (
        <>
          <div style={{ margin: '15px 0' }}>
            <p
              style={{
                margin: '0px',
                borderRadius: '5px',
                backgroundColor: '#ffc107',
                color: 'black',
                padding: '12px',
                fontWeight: 'bold',
                textAlign: 'center',
              }}>
              Your project is loading. Please stay on this page until the upload is done!
            </p>
          </div>

          <div style={{ width: '50%' }}>
            <p style={{ margin: '0', paddingBottom: '10px', height: '40px' }}>
              Uploading video... <strong> {props.percentage} % </strong>
            </p>
            <Line percent={props.percentage} strokeWidth="5" strokeColor="#007bff" />
          </div>
        </>
      ) : (
        <div>
          <br />
          <HorizontalRule />
        </div>
      )}
      {props.isLoading ? (
        <div>
          <Spinner style={{ position: 'relative', bottom: '15px', height: '40px', left: '60px' }} />
        </div>
      ) : (
        <StyledActionButtons>
          <GetSubmitButtons
            isReviewer={isAdmin}
            isEditing={isEditing}
            isLive={isLive}
            isResubmissionEdit={isResubmissionEditMode}
          />
          {isEditing && isAdmin && (
            <div>
              <HorizontalRule />
              <PromptFormPullUpButtons prompt={prompt} />
            </div>
          )}

          {/*showAssignButton && (
              <Button
                  title={isEditing ? 'Assign To My Class' : 'Assign To My Class'}
                  enabled={true}
                  clicked={() => props.assignPromptToGroup(formState.promptTypeId, formState.id)}
              />
          )*/}
        </StyledActionButtons>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.prompts.isLoading,
  percentage: state.prompts.percentage,
  promptTypes: state.prompts.promptTypes,
  interests: state.interests.interests,
  interestsAreLoading: state.interests.isLoading,
  userType: state.session.userType,
  groupCode: state.session.groupCode,
  sendToPrompts: state.prompts.sendToPrompts,
  message: state.prompts.message,
  channels: state.prompts.channels,
  sessionAllowedAgeRanges: state.session.allowedAgeRanges,
});

const mapDispatchToProps = (dispatch) => ({
  createPrompt: (prompt, isEditing, allPrompts) =>
    dispatch(createPromptAction(prompt, isEditing, allPrompts)),
  getPromptTypes: () => dispatch(getAllPromptTypesAction()),
  getInterests: () => dispatch(getInterestsAction()),
  getPrompts: () => dispatch(getPromptsAction()),
  assignPromptToGroup: (promptTypeId, promptId) =>
    dispatch(assignPromptToGroupAction(promptTypeId, promptId)),
  updateSendToPrompts: (sendToPrompts) => dispatch(updateSendToPromptsAction(sendToPrompts)),
  updateLocalPrompt: (id, newData) => dispatch(updateLocalPromptAction(id, newData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PromptForm);
