import {
  AUTHORIZATION_SUCCESS,
  SESSION_FAILURE_ACTION,
  AUTHORIZE_USER,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  DESTROY_SESSION,
} from './actions';

export const defaultState = {
  isLoading: true,
  error: '',
  userType: '',
  groupCode: '',
  forceNewPassword: false,
  uid: '',
};

export const session = (state = defaultState, action) => {
  switch (action.type) {
    case AUTHORIZATION_SUCCESS:
      return {
        ...state,
        ...action.userData,
        isLoading: false,
      };

    case SESSION_FAILURE_ACTION:
      return { ...state, error: action.errorMessage, isLoading: false };

    case AUTHORIZE_USER:
      return { ...state, isLoading: true };

    case CHANGE_PASSWORD:
      return { ...state, isLoading: true, error: '' };

    case CHANGE_PASSWORD_SUCCESS:
      return { ...state, isLoading: false, forceNewPassword: false };
    case DESTROY_SESSION:
      return { ...defaultState, isLoading: false };
    default:
      return state;
  }
};
