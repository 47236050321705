import PropTypes from 'prop-types';
import styled from 'styled-components';

const Icon = styled.svg`
  fill: ${(props) => props.color || 'black'};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;

Icon.defaultProps = {
  width: '20px',
  height: '20px',
};

Icon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default Icon;
