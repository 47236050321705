import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PromptReleaseStatusFilter } from '../../../../utils/constants';
import { setPromptsFilterAction } from '../../../../store/prompts/actions';
import usePromptFilters from '../hooks/usePromptFilters';
import PromptFilterDropdown from './PromptFilterDropdown';

const PromptFilterReleaseStatus = () => {
  const dispatch = useDispatch();
  const { filters } = usePromptFilters();
  const { releaseStatus } = filters;
  const options = [
    { name: 'All', id: PromptReleaseStatusFilter.ALL },
    { name: 'Needs Review', id: PromptReleaseStatusFilter.UNREVIEWED },
    { name: 'Failed', id: PromptReleaseStatusFilter.FAILED },
    { name: 'Rejected', id: PromptReleaseStatusFilter.REJECTED },
    { name: 'Processing', id: PromptReleaseStatusFilter.PROCESSING },
    { name: 'Future Approved', id: PromptReleaseStatusFilter.SCHEDULED },
    { name: 'Approved', id: PromptReleaseStatusFilter.APPROVED },
    { name: 'Released', id: PromptReleaseStatusFilter.LIVE },
    { name: 'All Future', id: PromptReleaseStatusFilter.NOT_LIVE },
  ];

  const handleChange = (e) => {
    const { value } = e.target;

    dispatch(
      setPromptsFilterAction({
        releaseStatus: value == PromptReleaseStatusFilter.ALL ? null : value,
      })
    );
  };

  return (
    <PromptFilterDropdown
      name="releaseStatus"
      options={options}
      label="Release Status"
      changed={handleChange}
      value={releaseStatus || PromptReleaseStatusFilter.ALL}
    />
  );
};

export default PromptFilterReleaseStatus;
