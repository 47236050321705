import React from 'react';
import styled from 'styled-components';
import theme from '../../../theme';
import { Grid, RowContainer, Column } from '../../UI/Grid';
import Spinner from '../../UI/Spinner/Spinner';
import UsersListItem from './UsersListItem';
import useUsersListLoader from './hooks/useUsersListLoader';
import Button from '../../UI/Button/Button';

const StyledTitleRow = styled(RowContainer)`
  && {
    border-bottom: 2px solid ${theme.color.fogGrey};
    font-weight: bold;
    padding-bottom: 20px;
    padding: 10px 15px;
  }
`;

const StyledCenteredContainer = styled.div`
  padding: 100px 50px;
  text-align: center;
`;

const StyledListSpinner = styled(Spinner)`
  && {
    margin: 100px auto;
  }
`;

const StyledFooter = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0;
  border-top: 2px solid ${theme.color.fogGrey};
`;

const UsersList = () => {
  const { usersList, isLoading, error, loadMore, hasNext } = useUsersListLoader();
  const isUsersListEmpty = !isLoading && !usersList.length;
  const isFirstPageLoad = isLoading && !usersList.length;

  return (
    <>
      <Grid>
        <StyledTitleRow>
          <Column width="5%"></Column>
          <Column width="10%">ID</Column>
          <Column width="25%">Full Name</Column>
          <Column width="20%">Username</Column>
          <Column width="20%">Email</Column>
          <Column width="10%">Phone Number</Column>
          <Column width="10%"></Column>
        </StyledTitleRow>
        {isFirstPageLoad ? (
          <StyledCenteredContainer>
            <StyledListSpinner />
          </StyledCenteredContainer>
        ) : error ? (
          <StyledCenteredContainer>
            <p>Something went wrong. Please try again later.</p>
            <p>{error}</p>
          </StyledCenteredContainer>
        ) : isUsersListEmpty ? (
          <StyledCenteredContainer>
            I&apos;m sorry, but it looks like we couldn&apos;t find any more items to suggest at
            this time.
          </StyledCenteredContainer>
        ) : (
          usersList.map((item) => <UsersListItem key={item.uid} {...item} />)
        )}
      </Grid>
      {!isFirstPageLoad && (
        <StyledFooter>
          {isLoading ? (
            <StyledListSpinner />
          ) : (
            hasNext && <Button clicked={loadMore}>Load More</Button>
          )}
        </StyledFooter>
      )}
    </>
  );
};

export default UsersList;
