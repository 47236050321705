import moment from 'moment';
import { convertTimeToMilliseconds, promptIsLive } from '../utils/functions';
import { PromptReviewStatus } from '../utils/constants';

export const promptDecorator = (prompt) => {
  const scheduledDateTimestamp = convertTimeToMilliseconds(prompt.scheduledDate);

  const isLive = promptIsLive(prompt);

  const isNonLiveUnreviewed =
    !isLive &&
    !prompt.processing &&
    (!prompt.reviewStatus ||
      prompt.reviewStatus === PromptReviewStatus.UNREVIEWED ||
      prompt.reviewStatus === PromptReviewStatus.OBSOLETE_UNREVIEWED);

  return {
    isLive,
    isNonLiveUnreviewed,
    scheduledDateTimestamp,
    ...prompt,
  };
};

export const decoratePrompts = (prompts) => {
  const decoratedPrompts = prompts.map((prompt) => promptDecorator(prompt));

  const joinChannels = (channels) => {
    const channelsById = channels.reduce((acc, channel) => {
      acc[channel.uid] = channel;
      return acc;
    }, {});

    return decoratedPrompts.map((prompt) => ({
      ...prompt,
      channelRelation: channelsById[prompt.userId],
    }));
  };

  return {
    prompts: decoratedPrompts,
    joinChannels,
  };
};
