import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AgeRange as AgeRangeConst } from '../../../../../../utils/constants';
import { KidIcon, PeopleIcon } from '../../../../../UI/Icons';

const BOTH_AGE_RANGES = 'both';
const ageRangeOptions = {
  [BOTH_AGE_RANGES]: {
    Component: () => '2',
    hint: 'Kids and General Audience',
    backgroundColor: '#094dacdb',
  },
  [AgeRangeConst.GA]: {
    Component: PeopleIcon,
    hint: 'General Audience',
    backgroundColor: '#3A2E58db',
  },
  [AgeRangeConst.KIDS]: {
    Component: KidIcon,
    hint: 'Kids',
    backgroundColor: '#e456a4db',
  },
};

const StyledAgeRangeMarker = styled.div`
  align-items: center;
  background-color: ${(props) => ageRangeOptions[props.ageRange].backgroundColor};
  border-bottom-right-radius: 5px;
  color: #fff;
  display: flex;
  font-weight: bold;
  justify-content: center;
  padding: 10px;
  position: absolute;
  top: 0;
  width: 40px;
`;

const StyledInlineAgeRangeMarker = styled(StyledAgeRangeMarker)`
  border-radius: 0;
  opacity: 60%;
  position: initial;
`;

const getAgeRange = (ageRanges) => {
  if (!ageRanges || ageRanges.length === 0) {
    return AgeRangeConst.KIDS;
  }

  const isForKids = ageRanges.includes(AgeRangeConst.KIDS);
  const isForGA = ageRanges.includes(AgeRangeConst.GA);

  if (isForKids && isForGA) {
    return BOTH_AGE_RANGES;
  }

  if (isForGA) {
    return AgeRangeConst.GA;
  }

  return AgeRangeConst.KIDS;
};

const AgeRangeMarker = ({ ageRanges, inline }) => {
  const ageRange = useMemo(() => getAgeRange(ageRanges), [ageRanges]);
  const ageRangeOption = ageRangeOptions[ageRange];
  const { Component: AgeRangeIcon, hint } = ageRangeOption;
  const StyledWrapper = inline ? StyledInlineAgeRangeMarker : StyledAgeRangeMarker;

  return (
    <StyledWrapper title={hint} ageRange={ageRange}>
      <AgeRangeIcon color="#fff" />
    </StyledWrapper>
  );
};

AgeRangeMarker.defaultProps = {
  inline: false,
};

AgeRangeMarker.propTypes = {
  ageRanges: PropTypes.arrayOf(PropTypes.string).isRequired,
  inline: PropTypes.bool,
};

export default AgeRangeMarker;
