import React from 'react';
import styled from 'styled-components';

const StyledHR = styled.hr`
  border-top: 2px;
  margin-bottom: 20px;
`;

const HorizontalRule = (props) => <StyledHR />;

export default HorizontalRule;
