import React from 'react';
import styled from 'styled-components';
import { Routes } from '../../../utils/constants';
import PageThumbnail from './PageThumbnail';
import audiolockImage from './images/audiolock-tool.jpg';
import autoAdsTargetViewsImage from './images/auto-ads-target-views-tool.jpg';
import userImage from './images/user-tool.jpg';
import carouselImage from './images/carousel-tool.jpg';
import discoverRankingImage from './images/discover-ranking-tool.jpg';
import songclipImage from './images/songclip-tool.jpg';

const InternalAppsRow = styled.div`
  align-items: center;
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(3, 1fr);
  flex-wrap: wrap;
  padding: 20px 0;
`;

const InternalApps = () => {
  return (
    <div className="Container">
      <h1>Internal Apps</h1>

      <InternalAppsRow>
        <PageThumbnail
          caption="Carousel Editor"
          image={carouselImage}
          url={Routes.CAROUSEL_EDITOR}
        />
        <PageThumbnail
          caption="Auto Ads Target Views Editor"
          image={autoAdsTargetViewsImage}
          url={Routes.AUTO_ADS_TARGET_VIEWS_EDITOR}
        />
        <PageThumbnail
          caption="Gen Z Carousel Editor"
          image={carouselImage}
          url={Routes.GA_CAROUSEL_EDITOR}
        />
        <PageThumbnail
          caption="SongClip Downloader"
          image={songclipImage}
          url={Routes.SONGCLIP_DOWNLOADER}
        />
        <PageThumbnail
          caption="Zigazoo Audiolock"
          image={audiolockImage}
          url={Routes.ZIGAZOO_AUDIOLOCK}
        />
        <PageThumbnail caption="User Editor" image={userImage} url={Routes.USER_EDITOR} />
        <PageThumbnail
          caption="Discover Ranking"
          image={discoverRankingImage}
          url={Routes.DISCOVER_RANKING_EDITOR}
        />
      </InternalAppsRow>
    </div>
  );
};

export default InternalApps;
