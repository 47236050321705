import { useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { useDispatch, useSelector } from 'react-redux';
import { auth } from '../config/firebase';
import { createSessionAction } from '../store/session/actions';

export default function useSession() {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.session);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      dispatch(createSessionAction(user?.uid));
    });
  }, [auth]);

  return { isLoading };
}
