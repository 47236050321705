import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import theme from '../../../theme';
import { Grid, RowContainer, Column } from '../../UI/Grid';
import Spinner from '../../UI/Spinner/Spinner';
import ModerationFeedVideoModal from './ModerationFeedVideoModal';
import ModerationFeedListItem from './ModerationFeedListItem';

const StyledTitleRow = styled(RowContainer)`
  && {
    border-bottom: 2px solid ${theme.color.fogGrey};
    font-weight: bold;
    padding-bottom: 20px;
    padding: 10px 15px;
  }
`;

const StyledCenteredContainer = styled.div`
  padding: 100px 50px;
  text-align: center;
`;

const StyledListSpinner = styled(Spinner)`
  && {
    margin: 100px auto;
  }
`;

const ModerationFeedList = () => {
  const { feed, isLoading, error } = useSelector((state) => state.moderationFeed);
  const isModerationFeedEmpty = feed.length === 0;
  const [currentItem, setCurrentItem] = useState(null);

  const handleModalClose = () => {
    setCurrentItem(null);
  };

  const handleItemClick = (item) => {
    setCurrentItem(item);
  };

  return (
    <>
      <ModerationFeedVideoModal item={currentItem} onClose={handleModalClose} />
      <Grid>
        <StyledTitleRow>
          <Column width="20%">Collection</Column>
          <Column width="20%">Generated At</Column>
          <Column width="25%">Creator</Column>
          <Column width="25%">Status</Column>
          <Column width="10%"></Column>
        </StyledTitleRow>
        {isLoading ? (
          <StyledCenteredContainer>
            <StyledListSpinner />
          </StyledCenteredContainer>
        ) : error ? (
          <StyledCenteredContainer>
            <p>Something went wrong. Please try again later.</p>
            <p>{error}</p>
          </StyledCenteredContainer>
        ) : isModerationFeedEmpty ? (
          <StyledCenteredContainer>
            I&apos;m sorry, but it looks like we couldn&apos;t find any more items to suggest at
            this time.
          </StyledCenteredContainer>
        ) : (
          feed.map((item) => (
            <ModerationFeedListItem
              key={item.id}
              {...item.rawData}
              status={item.rawData.reviewStatus || item.rawData.automoderationResult}
              isLoading={item.isLoading}
              error={item.error}
              onClick={() => handleItemClick(item)}
            />
          ))
        )}
      </Grid>
    </>
  );
};

export default ModerationFeedList;
