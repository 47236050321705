import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../../../config/firebase';
import { updateLocalPromptAction } from '../../../../store/prompts/actions';
import { promptDecorator } from '../../../../decorators/promptDecorator';
import { getPrompt } from '../../../../store/prompt/services';

const POLL_INTERVAL = 45 * 1000;

export default function usePromptListener(prompt) {
  const dispatch = useDispatch();
  const [currentPrompt, setCurrentPrompt] = useState(prompt);

  const intervalIdRef = useRef(null);
  const [listener, setListener] = useState(null);

  const unsubscribe = () => {
    intervalIdRef.current && clearInterval(intervalIdRef.current);
  };

  const handleSnapshot = async (doc) => {
    const updatedPrompt = await getPrompt(prompt.id);

    setCurrentPrompt(promptDecorator(updatedPrompt));

    dispatch(updateLocalPromptAction(prompt.id, updatedPrompt));
  };

  // NOTE: This function is executed in async mode,
  //       so that allows us to avoid waiting till the end
  //       in the main thread of rendering
  const assignListeners = async () => {
    const isProcessing = currentPrompt.processing && currentPrompt.processing === true;
    const isFailed = currentPrompt.failed;

    if (isProcessing || isFailed) {
      if (intervalIdRef.current) {
        return;
      }

      // poll for update
      const intervalId = setInterval(handleSnapshot, POLL_INTERVAL);
      if (!intervalIdRef.current) {
        intervalIdRef.current = intervalId;
      } else {
        clearInterval(intervalId);
      }
    } else {
      unsubscribe();
    }
  };

  useEffect(() => {
    // NOTE: Do not await this function, it's executed in async mode
    assignListeners();

    // NOTE: Unsubscribe from the listener when the component unmounts
    return () => {
      unsubscribe();
    };
  }, []);

  return currentPrompt;
}
