import React from 'react';

const Checkbox = (props) => {
  return (
    <div style={props.style}>
      <input id={props.id} type="checkbox" onChange={props.changed} checked={props.checked} />
      <label htmlFor={props.id}>{props.label}</label>
    </div>
  );
};

export default Checkbox;
