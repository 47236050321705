import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

const DisableIcon = ({ color, width, height }) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    color={color}
    width={width}
    height={height}>
    <path d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm0,2a9.949,9.949,0,0,1,6.324,2.262L4.262,18.324A9.992,9.992,0,0,1,12,2Zm0,20a9.949,9.949,0,0,1-6.324-2.262L19.738,5.676A9.992,9.992,0,0,1,12,22Z" />
  </Icon>
);

DisableIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default DisableIcon;
