import { put, call } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import {
  browserSessionPersistence,
  signInWithEmailAndPassword,
  updatePassword,
} from 'firebase/auth';
import { auth } from '../../config/firebase';

import * as Constants from '../../utils/constants';
import {
  authorizationSuccessAction,
  sessionFailureAction,
  changePasswordSuccessAction,
} from './actions';
import { signInWithGooglePopup, getUser, updateUser } from './services';
import { getUserTypeForSession } from '../../utils/functions';

export function* createSessionSaga({ userId = null }) {
  if (!userId) {
    yield put(sessionFailureAction(null));
    return;
  }

  const user = yield getUser(userId);
  const userType = user && getUserTypeForSession(user);

  Sentry.setUser({ email: user.email, userType });
  // We get relevant data from the user record depending on the user
  switch (userType) {
    case Constants.UserTypes.ADMIN:
    case Constants.UserTypes.MODERATOR:
      yield put(authorizationSuccessAction({ userType, uid: user.uid }));
      break;

    case Constants.UserTypes.CHANNEL:
    case Constants.UserTypes.PRELAUNCH_CHANNEL:
      yield put(
        authorizationSuccessAction({
          userType,
          uid: user.uid,
          channelId: user.channelId,
          forceNewPassword: !user.changedPassword,
        })
      );
      break;

    default:
      yield put(sessionFailureAction(Constants.ErrorMessages.LOGIN));
      break;
  }
}

// We both authenticate and check admin authorization for the user
export function* authorizationSaga({ loginType, email, password }) {
  try {
    let loginResult;

    yield auth.setPersistence(browserSessionPersistence);

    // Admins get the pop up sign in, while partners use email/password
    if (loginType === 'google') {
      loginResult = yield signInWithGooglePopup();
    } else {
      loginResult = yield signInWithEmailAndPassword(auth, email, password);
    }

    yield call(createSessionSaga, loginResult.user.uid);
  } catch (e) {
    // The user either does not have a valid account or is not an admin. In either case, they are not allowed to proceed
    Sentry.addBreadcrumb(e);
    yield put(sessionFailureAction(e.message));
  }
}

export function* changePasswordSaga(data) {
  try {
    const user = auth.currentUser;

    // Update the password on Firebase and the user record
    yield updatePassword(user, data.password);
    yield updateUser({ uid: user.uid, changedPassword: true });
    yield put(changePasswordSuccessAction());
  } catch (e) {
    Sentry.addBreadcrumb(e);
    sessionFailureAction(Constants.ErrorMessages.CHANGE_PASSWORD);
    console.log(e);
  }
}
