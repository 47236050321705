import React from 'react';
import { useSelector } from 'react-redux';
import FormLabel from '../../../UI/FormLabel/FormLabel';
import RadioButton from '../../../UI/RadioButton/RadioButton';
import { UserTypes, PromptMediaType } from '../../../../utils/constants';

const MediaType = (props) => {
  const userType = useSelector((state) => state.session.userType);
  const isAdmin = userType === UserTypes.ADMIN;

  return (
    <div>
      <FormLabel label={props.label} />
      <div style={{ marginTop: '5px' }}>
        <RadioButton
          id="video"
          label="Video"
          value="video"
          checked={props.type === PromptMediaType.VIDEO}
          changed={props.changed}
        />
        {isAdmin && (
          <RadioButton
            id="image"
            label="Image"
            value="image"
            checked={props.type === PromptMediaType.IMAGE}
            changed={props.changed}
          />
        )}
        <RadioButton
          id="poll"
          label="Poll"
          value="poll"
          checked={props.type === PromptMediaType.POLL}
          changed={props.changed}
        />
      </div>
    </div>
  );
};

export default MediaType;
