export const GET_PROPOSED_TAGS = 'GET_PROPOSED_TAGS';
export const GET_PROPOSED_TAGS_SUCCESS = 'GET_PROPOSED_TAGS_SUCCESS';
export const GET_PROPOSED_TAGS_FAILURE = 'GET_PROPOSED_TAGS_FAILURE';
export const ACCEPT_PROPOSED_TAG = 'ACCEPT_PROPOSED_TAG';
export const ACCEPT_PROPOSED_TAG_SUCCESS = 'ACCEPT_PROPOSED_TAG_SUCCESS';
export const ACCEPT_PROPOSED_TAG_FAILURE = 'ACCEPT_PROPOSED_TAG_FAILURE';
export const REJECT_PROPOSED_TAG = 'REJECT_PROPOSED_TAG';
export const REJECT_PROPOSED_TAG_SUCCESS = 'REJECT_PROPOSED_TAG_SUCCESS';
export const REJECT_PROPOSED_TAG_FAILURE = 'REJECT_PROPOSED_TAG_FAILURE';

export const getProposedTagsAction = () => ({
  type: GET_PROPOSED_TAGS,
});

export const getProposedTagsSuccessAction = (proposedTags, hasNextRecords) => ({
  type: GET_PROPOSED_TAGS_SUCCESS,
  proposedTags,
  hasNextRecords,
});

export const getProposedTagsFailureAction = (error) => ({
  type: GET_PROPOSED_TAGS_FAILURE,
  error,
});

export const acceptProposedTagAction = (tagId) => ({
  type: ACCEPT_PROPOSED_TAG,
  tagId,
});

export const acceptProposedTagSuccessAction = (tagId) => ({
  type: ACCEPT_PROPOSED_TAG_SUCCESS,
  tagId,
});

export const acceptProposedTagFailureAction = (tagId, error) => ({
  type: ACCEPT_PROPOSED_TAG_FAILURE,
  tagId,
  error,
});

export const rejectProposedTagAction = (tagId) => ({
  type: REJECT_PROPOSED_TAG,
  tagId,
});

export const rejectProposedTagSuccessAction = (tagId) => ({
  type: REJECT_PROPOSED_TAG_SUCCESS,
  tagId,
});

export const rejectProposedTagFailureAction = (tagId, error) => ({
  type: REJECT_PROPOSED_TAG_FAILURE,
  tagId,
  error,
});
