import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { ArrowUpIcon, ArrowDownIcon } from '../../UI/Icons';
import theme from '../../../theme';

const navMenuItem = `
	border: none;
	background: transparent;
    color: ${theme.color.heatherGrey};
    cursor: pointer;
    display: block;
    font-size: 14px;
    font-weight: 300;
    padding: 12px;
	text-align: left;
	text-decoration: none;
    width: 100%;

    &:hover,
    &:active {
      color: ${theme.color.white};
    }
 `;

const StyledDropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const StyledDropdownButtonToggle = styled.button`
  ${navMenuItem}
  align-items: center;
  color: ${(props) => props.isOpen && theme.color.white};
  display: flex;
  gap: 2px;
  padding: 15px 7px;
  font-weight: 600;

  &:hover {
    color: ${theme.color.white};
  }
`;

const StyledDropdownList = styled.div`
  background-color: ${theme.color.blue};
  color: black;
  position: absolute;
  top: 100%;
  left: 0;
  padding: 0;
  margin: 0;
  top: 50px;
  width: 100%;
  z-index: 1;

  a,
  button {
    ${navMenuItem}
  }
`;

const StyledDropdownListSeparator = styled.div`
  border-top: 1px solid ${theme.color.heatherGrey};
  width: 100%;
`;

const NavMenuDropdown = ({
  children,
  options,
  optionsMobile,
  renderOptionsMobile,
  onOptionClick,
  isMobile,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleOptionClick = (option) => {
    onOptionClick(option);
    setIsOpen(false);
  };

  const handleOptionMobileClick = () => setIsOpen(false);

  const handleToggleMenu = () => setIsOpen(!isOpen);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <StyledDropdownWrapper ref={dropdownRef}>
      <StyledDropdownButtonToggle isOpen={isOpen} onClick={handleToggleMenu}>
        {children}
        {isOpen ? <ArrowUpIcon color="white" /> : <ArrowDownIcon color="white" />}
      </StyledDropdownButtonToggle>
      {isOpen && (
        <StyledDropdownList>
          {isMobile() && (
            <>
              {renderOptionsMobile(optionsMobile, handleOptionMobileClick)}
              <StyledDropdownListSeparator />
            </>
          )}
          {options.map((option) => (
            <button type="button" key={option.value} onClick={() => handleOptionClick(option)}>
              {option.label}
            </button>
          ))}
        </StyledDropdownList>
      )}
    </StyledDropdownWrapper>
  );
};

export default NavMenuDropdown;
