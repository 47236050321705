import { Routes } from '../../../utils/constants';

export const userPermissions = [
  { name: 'my_projects', pathname: Routes.PROMPTS, label: 'My Projects' },
  {
    name: 'create_projects',
    pathname: { to: Routes.NEW_PROMPT, state: { isEditing: false } },
    label: 'Create Project',
  },
];

export const adminPermissions = [
  {
    name: 'internal_apps',
    pathname: Routes.INTERNAL_APPS,
    label: 'Internal Apps',
  },
];

export const adminModeratorPermissions = [
  { name: 'proposed_tags', pathname: Routes.PROPOSED_TAGS, label: 'Proposed Tags' },
  { name: 'moderation_feed', pathname: Routes.MODERATION_FEED, label: 'Moderation Feed' },
  { name: 'users', pathname: Routes.USERS_LIST, label: 'Users' },
  { name: 'edit_user', pathname: Routes.EDIT_USER, label: 'Edit User' },
  { name: 'disable_user', pathname: Routes.DISABLE_USER, label: 'Disable User' },
];
