import React from 'react';
import styled from 'styled-components';

const StyledStagingLabel = styled.span`
  background-color: #ff5454;
  color: white;
  font-weight: bold;
  padding: 10px 15px;
  border-radius: 5px;
  text-transform: uppercase;
  position: fixed;
  top: 50%;
  right: 10px;
  font-size: 1.25em;
`;

const StagingLabel = () => {
  return <StyledStagingLabel>Staging</StyledStagingLabel>;
};

export default StagingLabel;
