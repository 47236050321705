import { put, call } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import { getAllModerationFeed } from './services';
import { getModerationFeedSuccessAction, getModerationFeedFailureAction } from './actions';

export function* getAllModerationFeedSaga() {
  try {
    const [feed, hasNextRecords] = yield call(getAllModerationFeed);
    yield put(getModerationFeedSuccessAction(feed, hasNextRecords));
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    yield put(getModerationFeedFailureAction(error.message));
  }
}
