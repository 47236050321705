export const isStaging = process.env.REACT_APP_ZIGAZOO_ENV !== 'production';

export const Routes = {
  NEW_PROMPT: '/prompt/new',
  EDIT_PROMPT: '/prompts/:id',
  PROMPTS: '/prompts',
  LOG_IN: '/login',
  ADMIN_LOGIN: '/admin-login',
  DISABLE_USER: '/users/disable',
  INTERNAL_APPS: '/admin/internal-apps',
  CAROUSEL_EDITOR: '/admin/carousel-editor',
  AUTO_ADS_TARGET_VIEWS_EDITOR: '/admin/auto-ads-target-views-editor',
  USER_EDITOR: '/admin/user-editor',
  GA_CAROUSEL_EDITOR: '/admin/ga-carousel-editor',
  DISCOVER_RANKING_EDITOR: '/admin/discover-ranking-editor',
  SONGCLIP_DOWNLOADER: '/admin/songclip-downloader',
  ZIGAZOO_AUDIOLOCK: '/admin/zigazoo-audiolock',
  PROPOSED_TAGS: '/tags/proposed',
  MODERATION_FEED: '/moderation/feed',
  EDIT_USER: '/users/edit',
  USERS_LIST: '/users',
};

export const ErrorMessages = {
  LOGIN: 'You are not authorized to use this application.',
  CHANGE_PASSWORD: 'There was a problem changing your password. Please try again.',
};

export const PromptProcessingSteps = {
  MEDIA_UPLOAD: 'MEDIA_UPLOAD',
  VIDEO_OPTIMIZATION: 'VIDEO_OPTIMIZATION',
};

export const NotificationTypes = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

export const PromptDefaults = {
  GRADE_LOWEST: -3,
  GRADE_HIGHEST: 12,
  GRADE_DEFAULT_START: 0,
  GRADE_DEFAULT_END: 6,
  TOTAL_TAGS: 10,
  SECOND_CATEGORY: 'None',
  SHOUTOUT_MIN: 0,
  SHOUTOUT_MAX: 10,
};

export const SHOUTOUT_SLIDER_MARKS = {
  0: 0,
  1: 1,
  3: 3,
  5: 5,
  10: 10,
};

export const GradeLevelMarks = {
  '-3': 'Age 2',
  '-2': 'Age 3',
  '-1': 'Age 4',
  0: 'Kinder',
  1: 'Grade 1',
  2: 'Grade 2',
  3: 'Grade 3',
  4: 'Grade 4',
  5: 'Grade 5',
  6: 'Grade 6',
  7: 'Grade 7',
  8: 'Grade 8',
  9: 'Grade 9',
  10: 'Grade 10',
  11: 'Grade 11',
  12: 'Grade 12',
};

export const PromptCategories = [
  'Animals',
  'Arts & Crafts',
  'Books',
  'Challenges',
  'Current Events',
  'Dance',
  'DIY',
  'Entertainment',
  'Food',
  'Gaming',
  'Mindfulness',
  'Movies',
  'Music',
  'Nature & Outdoors',
  'Science Experiments',
  'Social Impact',
  'Sports',
  'Style',
  'Technology',
  'Travel',
  'Writing',
];

export const PromptReviewStatus = {
  LIVE: 'LIVE', // portal-specific status
  UNREVIEWED: 'UNREVIEWED',
  OBSOLETE_UNREVIEWED: 'NEEDS_REVIEW',
  APPROVED: 'APPROVED',
  DENIED: 'DENIED',
  AUTOMODERATION_DENIED: 'AUTOMODERATION_DENIED',
};

export const UserTypes = {
  ADMIN: 'ADMIN',
  CHANNEL: 'CHANNEL',
  MODERATOR: 'MODERATOR',
  PRELAUNCH_CHANNEL: 'PRELAUNCH_CHANNEL',
};

export const CAROUSEL_EDITOR_IFRAME_URL = isStaging
  ? 'https://zigazoo.retool.com/apps/ed479392-911a-11ed-aa73-3304fc3f29af/Carousel%20Editor%20Stg'
  : 'https://zigazoo.retool.com/apps/d244e84a-97ac-11ed-be12-1750ae2e7657/Carousel%20Editor%20Prod';

export const AUTO_ADS_TARGET_VIEWS_EDITOR_IFRAME_URL = isStaging
  ? 'https://zigazoo.retool.com/apps/d285e4c2-41ce-11ee-93a4-d7493ed298e6/Auto%20Ads%20Target%20Views%20Count%20Stg'
  : 'https://zigazoo.retool.com/apps/3d5a6180-4c04-11ee-b872-dfe372029f4a/Auto%20Ads%20Target%20Views%20Count%20Prod';

export const GA_CAROUSEL_EDITOR_IFRAME_URL = isStaging
  ? 'https://zigazoo.retool.com/apps/fdb42df2-cfff-11ed-8e01-d77af45ddc4a/Gen%20Z%20Carousel%20Editor%20-%20STG'
  : 'https://zigazoo.retool.com/apps/54aaa12a-d318-11ed-bac4-cfd68adb4923/Gen%20Z%20Carousel%20Editor%20-%20PROD';

export const SONGCLIP_DOWNLOADER_IFRAME_URL =
  'https://zigazoo.retool.com/apps/caae0afa-9354-11ed-a71f-a32b8115ebad/Songclip%20Downloader?_releaseVersion=latest';

export const ZIGAZOO_AUDIOLOCKER_IFRAME_URL = isStaging
  ? 'https://zigazoo.retool.com/apps/Audiolock%20Upload%20Tool%20-%20STG'
  : 'https://zigazoo.retool.com/apps/Audiolock%20Upload%20Tool%20-%20PROD';

export const USER_EDITOR_IFRAME_URL = isStaging
  ? 'https://zigazoo.retool.com/apps/d3980f0c-593e-11ee-9809-1baf64bbdfe5/User%20editor%20Stg'
  : 'https://zigazoo.retool.com/apps/4742c5e8-6c57-11ee-b82b-c7161cfd4612/User%20editor%20Prod';

export const DISCOVER_RANKING_EDITOR_IFRAME_URL = isStaging
  ? 'https://zigazoo.retool.com/apps/1b54a7aa-51a2-11ee-8274-638d798c1efb/Rank%20Prompt%20Type%20-%20STG'
  : 'https://zigazoo.retool.com/apps/f2f9d368-5ee7-11ee-837f-ffae9f927056/Rank%20Prompt%20Type%20-%20PROD';

export const AgeRange = {
  KIDS: 'KIDS',
  GA: 'GA',
  ADMIN: 'ADMIN_PORTAL',
};

export const PromptReviewMode = {
  ALL: 'ALL',
  KIDS_RANKING: 'KIDS_RANKING',
  GA_RANKING: 'GA_RANKING',
};

export const PromptListMode = {
  REVIEWING: 'REVIEWING',
  RANKING: 'RANKING',
  OTHER: 'OTHER',
};

export const PromptListSort = {
  TOP_KIDS_RANKED: 'TOP_KIDS_RANKED',
  TOP_GA_RANKED: 'TOP_GA_RANKED',
  LATEST_SCHEDULED: 'LATEST_SCHEDULED',
};

export const PromptAgeRangeFilter = {
  ALL: 'ALL',
  BOTH: 'BOTH',
  GA: AgeRange.GA,
  KIDS: AgeRange.KIDS,
};

export const PromptReleaseStatusFilter = {
  ALL: 'ALL',
  LIVE: 'LIVE',
  NOT_LIVE: 'NOT_LIVE',
  UNREVIEWED: 'UNREVIEWED',
  FAILED: 'FAILED',
  PROCESSING: 'PROCESSING',
  SCHEDULED: 'SCHEDULED',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
};

export const PromptMediaType = {
  VIDEO: 'video',
  POLL: 'poll',
  IMAGE: 'image',
};

export const PollType = {
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
};

export const RewardType = {
  CONSUMER_GOOD: 'CONSUMER_GOOD',
};

export const USER_TYPE = {
  ADMIN: 'ADMIN',
  PRELAUNCH_CHANNEL: 'PRELAUNCH_CHANNEL',
  CHANNEL: 'CHANNEL',
  TEACHER: 'TEACHER',
  STUDENT: 'STUDENT',
  COLLECTOR: 'COLLECTOR',
  GA_INVITE_PENDING: 'GA_INVITE_PENDING',
};

export const STORAGE_BASE_URL = isStaging
  ? 'https://storage.googleapis.com/zigazoo-staging-public'
  : 'https://storage.googleapis.com/zigazoo-public';
