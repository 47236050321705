import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

const CheckIcon = ({ color, width, height }) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0"
    y="0"
    viewBox="0 0 507.506 507.506"
    xmlSpace="preserve"
    color={color}
    width={width}
    height={height}>
    <g>
      <path d="M163.865,436.934c-14.406,0.006-28.222-5.72-38.4-15.915L9.369,304.966c-12.492-12.496-12.492-32.752,0-45.248l0,0   c12.496-12.492,32.752-12.492,45.248,0l109.248,109.248L452.889,79.942c12.496-12.492,32.752-12.492,45.248,0l0,0   c12.492,12.496,12.492,32.752,0,45.248L202.265,421.019C192.087,431.214,178.271,436.94,163.865,436.934z" />
    </g>
  </Icon>
);

CheckIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default CheckIcon;
