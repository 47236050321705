import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

const PlayIcon = ({ color, width, height }) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    color={color}
    width={width}
    height={height}>
    <g>
      <path d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0zm0 21c-4.963 0-9-4.038-9-9s4.037-9 9-9 9 4.038 9 9-4.037 9-9 9zM9 7.5l8 4.5-8 4.5v-9z" />
    </g>
  </Icon>
);

PlayIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default PlayIcon;
