import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const colors = {
  info: {
    background: '#eef4ff',
    color: '#3467C1',
  },
  warning: {
    background: '#fff5e6',
    color: '#8d5900',
  },
  error: {
    background: '#ffe8e6',
    color: '#b91c1c',
  },
  success: {
    background: '#d9f7be',
    color: '#52c41a',
  },
};

const StyledAlert = styled.div`
  background-color: ${({ type }) => colors[type].background};
  color: ${({ type }) => colors[type].color};
  font-size: 14px;
  font-weight: 400;
  padding: 10px 20px;
  border-radius: 5px;
  width: 100%;
`;

const Alert = ({ type, children, ...props }) => {
  return (
    <StyledAlert type={type} {...props}>
      {children}
    </StyledAlert>
  );
};

Alert.propTypes = {
  type: PropTypes.oneOf(['error', 'success', 'info', 'warning']).isRequired,
};

Alert.defaultProps = {
  type: 'info',
};

export default Alert;
