import React from 'react';
import styled from 'styled-components';

const StyledTagField = styled.input`
  width: 20% !important;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const StyledTagText = styled.span`
  width: 20% !important;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const TagField = (props) => {
  return (
    <span>
      {props.readOnly ? (
        <StyledTagText>{props.value}</StyledTagText>
      ) : (
        <StyledTagField
          type="text"
          data-tag-id={props.id}
          onChange={props.handler}
          value={props.value}
        />
      )}
    </span>
  );
};

export default TagField;
