import { useState, useEffect } from 'react';
import { ref, getDownloadURL } from 'firebase/storage';
import * as Sentry from '@sentry/react';
import { storage } from '../config/firebase';

const getVideoFullPath = async (fullPath) => {
  try {
    return getDownloadURL(ref(storage, fullPath));
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
    throw new Error(e);
  }
};

const useDownloadUrl = (fullPath) => {
  const [isLoading, setIsLoading] = useState(false);
  const [videoPath, setVideoPath] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!fullPath) {
      setVideoPath(null);
      return;
    }

    setIsLoading(true);
    getVideoFullPath(fullPath)
      .then(setVideoPath)
      .catch(setError)
      .then(() => setIsLoading(false));
  }, [fullPath]);

  return [videoPath, isLoading, error];
};

export default useDownloadUrl;
