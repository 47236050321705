import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Spinner from '../../UI/Spinner/Spinner';
import Button from '../../UI/Button/Button';
import HorizontalRule from '../../UI/HorizontalRule/HorizontalRule';
import Notification from '../../UI/Notification/Notification';
import { clearNotificationMessageAction } from '../../../store/prompts/actions';
import { Routes } from '../../../utils/constants';
import theme from '../../../theme';
import Alert from '../../UI/Alert';
import { CenteredLayout } from '../../UI/Layouts';
import ScrollTopButton from '../../UI/ScrollTop/ScrollTopButton';
import usePromptGroups from './hooks/usePromptGroups';
import usePromptRankMode from './hooks/usePromptRankMode';
import usePromptListLoader from './hooks/usePromptListLoader';
import PromptFilter from './PromptFilter';
import { PromptMode, PromptRankingMode } from './PromptMode';
import PromptGroup from './PromptGroup/PromptGroup';
import PromptSearch, { PromptSearchEmpty } from './PromptSearch';
import useScreen from '../../../hooks/useScreen';

const StyledHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  @media (max-width: 991px) {
    align-items: stretch;
    flex-direction: column;
    gap: 10px;
  }
`;

const StyledButton = styled(Button)`
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  border: none;
  font-size: 14px;
  font-weight: 600;
  text-transform: initial;
`;

const StyledReviewButton = styled(StyledButton)`
  background-color: ${theme.color.yellow};
`;

const StyledExpandButton = styled(StyledButton)`
  background-color: ${theme.color.fogGrey};
  min-width: 120px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  @media (max-width: 767px) {
    flex-direction: column-reverse;
  }
`;

const LeftColumn = styled.div`
  flex: 1;

  @media (min-width: 768px) {
    max-width: calc(100% - 220px);
  }
`;

const RightColumn = styled.div`
  border-bottom: 1px solid #eee;
  margin-bottom: 15px;
  padding-bottom: 15px;

  @media (min-width: 768px) {
    border-bottom: none;
    border-left: 1px solid #eee;
    flex: 0 0 200px;
    margin: 0 0 0 15px;
    padding: 0 0 0 15px;
  }
`;

const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 15px;
  }
`;

const ActionButtons = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: stretch;

  @media (min-width: 768px) {
    justify-content: flex-end;
  }
`;

const StyledSplitter = styled.span`
  &:before {
    color: ${theme.color.mediumGrey};
    content: '|';
  }
`;

const PromptList = (props) => {
  const isAdmin = props.userType === 'ADMIN';
  const [openTypes, setOpenTypes] = useState({});
  const [reviewMode, setReviewMode] = useState(isAdmin);
  const [expandMode, setExpandMode] = useState(!isAdmin);
  const { arePromptsEmpty, hasNotFoundPrompts, isLoading } = usePromptListLoader();
  const promptGroups = usePromptGroups();
  const { isDraggable, isEnabled: isRankModeEnabled } = usePromptRankMode();
  const { isMobile } = useScreen(500);

  const handleOpenTypes = (promptTypeId, isOpen) => {
    setOpenTypes({ ...openTypes, [promptTypeId]: isOpen });
  };

  const toggleReviewMode = () => {
    setReviewMode(!reviewMode);
  };

  const handleExpand = () => {
    setExpandMode(!expandMode);
  };

  return (
    <>
      <div className="Container">
        <StyledHeader>
          <h1>My Projects</h1>
          {isAdmin && <PromptSearch />}
        </StyledHeader>
        <HorizontalRule />
        {arePromptsEmpty ? (
          <div>
            <h1>
              We&apos;re thrilled to have you here! Get started by creating your first project for
              the Zigazoo community.
            </h1>
            <p>
              Projects go live immediately, so please ensure all information is accurate before
              submitting.
            </p>
            <Link to={{ pathname: Routes.NEW_PROMPT, state: { isEditing: false } }}>
              <Button title="Create Project" enabled={true} clicked={() => {}} />
            </Link>
          </div>
        ) : (
          <>
            {isAdmin && (
              <ContentHeader>
                <ActionButtons>
                  <PromptMode />
                  {isRankModeEnabled && (
                    <>
                      {!isMobile() && <StyledSplitter />}
                      <PromptRankingMode />
                    </>
                  )}
                </ActionButtons>

                <ActionButtons>
                  <StyledReviewButton clicked={toggleReviewMode}>
                    {reviewMode ? 'Show All' : 'Review Mode'}
                  </StyledReviewButton>
                  <StyledSplitter />
                  <StyledExpandButton clicked={handleExpand}>
                    {expandMode ? 'Collapse All' : 'Expand All'}
                  </StyledExpandButton>
                </ActionButtons>
              </ContentHeader>
            )}

            <Wrapper>
              <LeftColumn>
                {isLoading ? (
                  <CenteredLayout>
                    <Spinner />
                  </CenteredLayout>
                ) : hasNotFoundPrompts ? (
                  <CenteredLayout>
                    <PromptSearchEmpty />
                  </CenteredLayout>
                ) : (
                  <>
                    {isAdmin && isRankModeEnabled && !isDraggable && (
                      <>
                        <Alert>
                          ℹ️ Please note that you will not be able to drag and drop prompts until
                          you have selected an age range filter.
                        </Alert>
                        <br />
                      </>
                    )}
                    {promptGroups.map((promptGroup) => (
                      <PromptGroup
                        key={promptGroup.id}
                        type={promptGroup.type}
                        prompts={promptGroup.prompts}
                        promptTypeId={promptGroup.id}
                        openedType={handleOpenTypes}
                        isOpen={
                          expandMode ||
                          (openTypes[promptGroup.id] && openTypes[promptGroup.id] === true)
                        }
                        isReviewMode={reviewMode}
                      />
                    ))}
                  </>
                )}
              </LeftColumn>
              {isAdmin && (
                <RightColumn>
                  <PromptFilter />
                </RightColumn>
              )}
            </Wrapper>
          </>
        )}
      </div>

      {props.message !== '' && !isLoading && (
        <Notification
          toastList={[
            {
              id: 1,
              title: props.title,
              description: props.message,
              backgroundColor: props.notificationType === 'success' ? '#5cb85c' : '#d9534f',
              icon: '',
            },
          ]}
          position="top-right"
          clear={() => props.clearNotificationMessage()}
        />
      )}

      <ScrollTopButton />
    </>
  );
};

const mapStateToProps = (state) => ({
  userType: state.session.userType,
  message: state.prompts.message,
  title: state.prompts.title,
  notificationType: state.prompts.notificationType,
});

const mapDispatchToProps = (dispatch) => ({
  clearNotificationMessage: () => dispatch(clearNotificationMessageAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PromptList);
