import React from 'react';
import FormLabel from '../FormLabel/FormLabel';
import styled from 'styled-components';

const StyledImage = styled.img`
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 5px;
  height: 200px;
  aspect-ratio: ${(props) => props.aspectRatio || 'auto'};
  object-fit: cover;
`;

const FileInput = (props) => {
  let existingImage;

  if (props.url) {
    existingImage = (
      <div>
        <a href={props.url} target="_blank" rel="noopener noreferrer">
          <StyledImage aspectRatio={props.aspectRatio} src={props.url} />
        </a>
      </div>
    );
  }

  return (
    <div>
      {props.label && <FormLabel label={props.label} />}
      {existingImage}
      {!props.readOnly && (
        <input
          style={{ marginRight: '10px', marginTop: '15px' }}
          type="file"
          accept={props.accept}
          onChange={props.changed}
        />
      )}
      {props.hyperlink && (
        <a
          style={{ color: '#094dac', fontWeight: 'bold', position: 'relative', left: '10px' }}
          rel="noopener noreferrer"
          target="_blank"
          href={props.hyperlink}>
          View existing video
        </a>
      )}
    </div>
  );
};

export default FileInput;
