import React, { useState } from 'react';
import { connect } from 'react-redux';
import Button from '../UI/Button/Button';
import Spinner from '../UI/Spinner/Spinner';
import styled from 'styled-components';
import { changePasswordAction } from '../../store/session/actions';

const StyledInput = styled.input`
  background-color: white !important;
  margin-bottom: 10px;
`;

const ChangePassword = (props) => {
  const [state, setState] = useState({ password: '', confirm: '', error: '' });

  const handlePasswordInput = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const changePassword = () => {
    if (state.password === '' || state.confirm === '') {
      return setState({ ...state, error: 'Please enter both password fields.' });
    }

    if (state.password !== state.confirm) {
      return setState({ ...state, error: 'The passwords must match.' });
    }

    // Quick and dirty regex-less solution to include 1 uppercase and 1 number
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const digits = '0123456789';
    if (
      !uppercase.split('').some((letter) => state.password.includes(letter)) ||
      !digits.split('').some((digit) => state.password.includes(digit))
    ) {
      return setState({
        ...state,
        error:
          'Your password must contain at least: 6 characters, 1 uppercase letter and 1 number.',
      });
    }

    setState({ ...state, error: '' });
    props.changePassword(state.password);
  };

  return (
    <div className="Center" style={{ width: '50%' }}>
      <br />
      <h2>Welcome to Zigazoo!</h2>
      <p>Create a new password (at least 6 characters, 1 uppercase letter and 1 number):</p>
      <StyledInput
        type="password"
        name="password"
        placeholder="New Password"
        onChange={handlePasswordInput}
      />
      <StyledInput
        type="password"
        name="confirm"
        placeholder="Confirm New Password"
        onChange={handlePasswordInput}
      />
      <br />
      {props.isLoading ? (
        <Spinner />
      ) : (
        <Button
          style={{ marginTop: '20px' }}
          title="Change Password"
          enabled={true}
          clicked={changePassword}
        />
      )}
      {state.error && <p className="Error">{state.error}</p>}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.session.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  changePassword: (password) => dispatch(changePasswordAction(password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
