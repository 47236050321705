import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPromptsSearchTermAction } from '../../../../store/prompts/actions';
import SearchBar from '../../../UI/SearchBar';

const PromptSearch = () => {
  const { searchTerm } = useSelector((state) => state.prompts);
  const dispatch = useDispatch();

  const handleSubmit = (value) => {
    dispatch(setPromptsSearchTermAction(value));
  };

  return (
    <SearchBar
      minLengath={3}
      placeholder="Search by name"
      value={searchTerm}
      onSubmit={handleSubmit}
    />
  );
};

export default PromptSearch;
