import { useEffect } from 'react';
import { fetchAndActivate } from 'firebase/remote-config';
import { remoteConfig } from '../config/firebase';

export default function useRemoteConfig() {
  useEffect(() => {
    fetchAndActivate(remoteConfig)
      .then((activated) => {
        if (!activated) console.debug('not activated');
      })
      .catch((error) => console.error(error));
  }, []);
}
