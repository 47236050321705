export const AUTHORIZE_USER = 'AUTHORIZE_USER';
export const CREATE_SESSION = 'CREATE_SESSION';
export const DESTROY_SESSION = 'DESTROY_SESSION';
export const AUTHORIZATION_SUCCESS = 'AUTHORIZATION_SUCCESS';
export const SESSION_FAILURE_ACTION = 'SESSION_FAILURE_ACTION';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';

export const authorizeUserAction = (loginType, email, password) => ({
  type: AUTHORIZE_USER,
  loginType,
  email,
  password,
});

export const createSessionAction = (userId) => ({
  type: CREATE_SESSION,
  userId,
});

export const destroySessionAction = () => ({
  type: DESTROY_SESSION,
});

export const authorizationSuccessAction = (userData) => ({
  type: AUTHORIZATION_SUCCESS,
  userData,
});

export const sessionFailureAction = (errorMessage) => ({
  type: SESSION_FAILURE_ACTION,
  errorMessage,
});

export const changePasswordAction = (password) => ({
  type: CHANGE_PASSWORD,
  password: password,
});

export const changePasswordSuccessAction = () => ({
  type: CHANGE_PASSWORD_SUCCESS,
});
