import React from 'react';
import styled from 'styled-components';

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.gap || '0px'};
  padding: ${(props) => props.padding || '0px'};
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  align-items: ${(props) => props.alignItems || 'flex-start'};
  gap: ${(props) => props.gap || '0px'};
  flex-wrap: wrap;
  align-items: center;
`;

export const ColumnContainer = styled.div`
  width: ${(props) => props.width};
`;

export const Grid = ({ gap, padding, children }) => {
  return (
    <GridContainer gap={gap} padding={padding}>
      {children}
    </GridContainer>
  );
};

export const Row = ({ justifyContent, alignItems, gap, children }) => {
  return (
    <RowContainer justifyContent={justifyContent} alignItems={alignItems} gap={gap}>
      {children}
    </RowContainer>
  );
};

export const Column = ({ width, children }) => {
  return <ColumnContainer width={width}>{children}</ColumnContainer>;
};
