import React from 'react';
import styled from 'styled-components';
import FormLabel from '../FormLabel/FormLabel';

const StyledSelect = styled.select`
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.1em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
`;

const Dropdown = ({ options, label, name, changed, readOnly, value, ...props }) => {
  const selectedValue = options[1] ? options[1].name : 'No type set.';

  return (
    <div>
      <FormLabel label={label} />
      {readOnly ? (
        <p style={{ margin: '0' }}>{selectedValue}</p>
      ) : (
        <StyledSelect name={name} onChange={changed} value={value || ''} {...props}>
          {options.map((option) => (
            <option key={`${option.name}-${option.id || ''}-${name}`} value={option.id}>
              {option.label && option.label}
              {option.name}
            </option>
          ))}
        </StyledSelect>
      )}
    </div>
  );
};

export default Dropdown;
