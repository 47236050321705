import { useSelector } from 'react-redux';
import { PromptReleaseStatusFilter, PromptAgeRangeFilter } from '../../../../utils/constants';

export default function usePromptFilters() {
  const { filters } = useSelector((state) => state.prompts);
  const { allowedAgeRanges } = filters;
  const hasFilterChosen = Object.values(filters).some((filter) => !!filter);

  return {
    filters,
    hasFilterChosen,
    isLiveStatusSelected: filters.releaseStatus === PromptReleaseStatusFilter.LIVE,
    isApprovedStatusSelected: filters.releaseStatus === PromptReleaseStatusFilter.APPROVED,
    isUnreviewedStatusSelected: filters.releaseStatus === PromptReleaseStatusFilter.UNREVIEWED,
    isAllowedAgeRangeSelected: !!allowedAgeRanges,
    isKidsRangeSelected: allowedAgeRanges === PromptAgeRangeFilter.KIDS,
    isGARangeSelected: allowedAgeRanges === PromptAgeRangeFilter.GA,
    isBothRangesSelected: allowedAgeRanges === PromptAgeRangeFilter.BOTH,
  };
}
