import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import theme from '../../../theme';
import Button from '../../UI/Button/Button';
import { RowContainer, ColumnContainer, Column } from '../../UI/Grid';
import { PlayIcon } from '../../UI/Icons';

const StyledListItemContainer = styled(RowContainer)`
  && {
    padding: 10px 15px;
    opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};

    &:hover {
      background-color: ${theme.color.fogGrey};
    }
  }
`;

const StyledTruncatedColumn = styled(ColumnContainer)`
  && {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const StyledActionColumn = styled(ColumnContainer)`
  && {
    text-align: right;
  }
`;

const StyledPlayButton = styled(Button)`
  && {
    padding: 7px 15px 3px;
  }
`;

const ModerationFeedListItem = ({
  creator,
  collection,
  generation,
  status,
  isLoading,
  onClick,
}) => {
  const generatedAtDate = useMemo(() => moment(generation / 1000).format('YYYY/MM/DD HH:mm'), [
    generation,
  ]);

  return (
    <StyledListItemContainer isDisabled={isLoading}>
      <Column width="20%">{collection || 'prompt'}</Column>
      <Column width="20%">{generatedAtDate}</Column>
      <StyledTruncatedColumn width="25%">{creator[0]?.username || 'Unknown'}</StyledTruncatedColumn>
      <Column width="25%">{status}</Column>
      <StyledActionColumn width="10%">
        <StyledPlayButton clicked={onClick}>
          <PlayIcon width="18px" height="18px" />
        </StyledPlayButton>
      </StyledActionColumn>
    </StyledListItemContainer>
  );
};

ModerationFeedListItem.defaultProps = {
  error: null,
  isLoading: false,
};

ModerationFeedListItem.propTypes = {
  creator: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string.isRequired,
    })
  ),
  collection: PropTypes.string.isRequired,
  fullPath: PropTypes.string.isRequired,
  generation: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  status: PropTypes.string.isRequired,
};

export default ModerationFeedListItem;
