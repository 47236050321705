import { put, call } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import { getAllProposedTags, acceptProposedTag, rejectProposedTag } from './services';
import {
  getProposedTagsSuccessAction,
  getProposedTagsFailureAction,
  acceptProposedTagFailureAction,
  acceptProposedTagSuccessAction,
  rejectProposedTagFailureAction,
  rejectProposedTagSuccessAction,
} from './actions';

export function* getAllProposedTagsSaga() {
  try {
    const [proposedTags, hasNextRecords] = yield call(getAllProposedTags);
    yield put(getProposedTagsSuccessAction(proposedTags, hasNextRecords));
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    yield put(getProposedTagsFailureAction(error.message));
  }
}

export function* acceptProposedTagSaga({ tagId }) {
  try {
    yield call(acceptProposedTag, tagId);
    yield put(acceptProposedTagSuccessAction(tagId));
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    yield put(acceptProposedTagFailureAction(tagId, error.message));
  }
}

export function* rejectProposedTagSaga({ tagId }) {
  try {
    yield call(rejectProposedTag, tagId);
    yield put(rejectProposedTagSuccessAction(tagId));
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    yield put(rejectProposedTagFailureAction(tagId, error.message));
  }
}
