import { put, all } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import {
  deleteUser,
  getUser,
  updateUser,
  updateUsernameInChat,
  updateUsernameInSearch,
  validateUsername,
  getUsersList,
} from './services';
import {
  setUserAction,
  getUserFailureAction,
  updateUserAction,
  updateUserSuccessAction,
  updateUserFailureAction,
  deleteUserSuccessAction,
  validateUsernameSuccessAction,
  validateUsernameFailureAction,
  getUsersListSuccessAction,
  getUsersListFailureAction,
} from './actions';

export function* getUserSaga({ username }) {
  try {
    const userWithVideos = yield getUser(username);
    yield put(setUserAction(userWithVideos.data));
  } catch (e) {
    Sentry.captureException(e);
    yield put(getUserFailureAction(e.message));
  }
}

export function* updateUserSaga(request) {
  try {
    const { uid, updateRequest } = request;
    yield updateUser({ ...updateRequest, uid: uid });

    if (updateRequest.username) {
      yield updateUsernameDependencies(uid, updateRequest);
    }

    yield put(updateUserSuccessAction(updateRequest));
  } catch (error) {
    console.log(error);
    yield put(updateUserFailureAction(error));
  }
}

function* updateUsernameDependencies(userId, request) {
  yield all([
    updateUsernameInSearch(userId, request),
    updateUsernameInChat(userId, request.username),
  ]);
}

export function* deleteUserSaga({ uid }) {
  yield deleteUser(uid);
  yield put(deleteUserSuccessAction());
}

export function* validateUsernameSaga({ username }) {
  try {
    if (username && username.length) {
      const response = yield validateUsername({ username });
      const isValid = response.data;
      yield put(validateUsernameSuccessAction(isValid, username));
    } else {
      yield put(validateUsernameSuccessAction(false, username));
    }
  } catch (error) {
    console.log(error);
    yield put(validateUsernameFailureAction(error));
  }
}

export function* getUsersListSaga(params) {
  try {
    const response = yield getUsersList(params);

    yield put(getUsersListSuccessAction(response.data));
  } catch (e) {
    Sentry.captureException(e);
    yield put(getUsersListFailureAction(e.message));
  }
}
