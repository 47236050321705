import { put, select, call, all } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import moment from 'moment';
import { PromptMediaType, RewardType } from '../../utils/constants';

import {
  uploadPromptImage,
  uploadPromptPollAnswerImage,
  createPrompt,
  getAllPromptTypes,
  updatePrompt,
  getPrompts,
  uploadPromptVideo,
  optimizeVideo,
  updatePromptRanks,
  getChannels,
} from './services';
import {
  createPromptSuccessAction,
  createPromptFailureAction,
  setPromptTypesAction,
  setPromptsAction,
  setChannelsAction,
  updateSendToPromptsAction,
  updatePromptRanksActionSuccess,
  updatePromptRanksActionFailure,
} from './actions';
import { addTimestampToFilename } from '../../utils/functions';
import { PromptProcessingSteps, AgeRange, PollType } from '../../utils/constants';

import * as selectors from '../selectors';

export function* getAllPromptTypesSaga() {
  try {
    // Fetch all existing prompt types from the back-end
    let promptTypes = yield getAllPromptTypes();
    const userType = yield select(selectors.userType);

    // Remove featured projects if user is not an admin
    if (userType !== 'ADMIN') {
      promptTypes = promptTypes.filter(
        (t) =>
          !['featured challenge', 'celebrity reads', 'talent challenge'].includes(
            t.type.toLowerCase()
          )
      );
    }

    // When retrieved, store a sorted mapping of prompt type names and IDs
    const sortedPromptTypes = promptTypes
      .map((pt) => [pt.type, pt.id, pt.allowedAgeRanges])
      .sort((a, b) => (a[0] > b[0] ? 1 : -1));

    yield put(setPromptTypesAction(sortedPromptTypes));
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
  }
}

export function* pullUpPromptSaga({
  promptTypeId,
  promptId,
  ageRange = AgeRange.KIDS,
  scheduledDate, // ms
}) {
  try {
    const RANK = {
      [AgeRange.KIDS]: { rank: 10 },
      [AgeRange.GA]: { gaRank: 10 },
    };
    const oneWeekAgo = moment().subtract(1, 'week');
    const oneWeekAgoMilliseconds = oneWeekAgo.unix() * 1000;
    const scheduled = moment(scheduledDate);

    const promptResult = yield updatePrompt(
      promptTypeId,
      promptId,
      {
        ...(scheduled.isBefore(oneWeekAgo) ? { scheduledDate: oneWeekAgoMilliseconds } : {}),
        ...RANK[ageRange],
      },
      null
    );

    yield updateLocalPromptSaga({ id: promptResult.id, newData: promptResult });
    yield put(updateSendToPromptsAction(true));
    yield put(
      createPromptSuccessAction(
        'Prompt Ranking Updated',
        'The ranking of a prompt has been updated. Please review the changes to ensure they meet your preferences.'
      )
    );

    return promptResult.data;
  } catch (e) {
    Sentry.captureException(e);
    throw new Error(e);
  }
}

function* processPollImageAnswer({ image, blobImage, ...pollAnswer }) {
  const data = pollAnswer;

  if (image && blobImage) {
    data.answerImgUrl = yield uploadPromptPollAnswerImage(
      pollAnswer.id,
      blobImage,
      addTimestampToFilename(image.name)
    );

    // NOTE: the image has been changed, so we have to flush the old optimized images
    data.optimized150ImageUrl = '';
    data.optimized300ImageUrl = '';
  }

  return data;
}

function* processPollAnswers(pollType, pollAnswers) {
  if (pollType === PollType.TEXT) {
    return pollAnswers.map(({ image, ...answer }) => answer);
  }

  const processedAnswers = yield all(
    pollAnswers.map((pollAnswer) => call(processPollImageAnswer, pollAnswer))
  );

  return processedAnswers;
}

export function* createUpdatePromptSaga(promptData) {
  try {
    const isEditing = promptData.isEditing;
    const isUploadingNewImage = promptData.prompt.image ? true : false;
    const isUploadingNewVideo = promptData.prompt.video ? true : false;
    const promptTypeId = promptData.prompt.promptTypeId;
    const prevPromptTypeId = promptData.prompt.prevPromptTypeId;
    const uid = yield select(selectors.uid);
    let videoFilename = '';

    // Upload the prompt image to start with if it's a new prompt or edit prompt with new image
    const promptImage = isUploadingNewImage
      ? yield uploadPromptImage(
          promptData.prompt.image,
          addTimestampToFilename(promptData.prompt.image.name)
        )
      : null;

    const pollAnswers = yield call(
      processPollAnswers,
      promptData.prompt.pollType,
      promptData.prompt.pollAnswers
    );

    const isRewardFilled =
      promptData.prompt.rewardDetails &&
      Object.values(promptData.prompt.rewardDetails).every((value) => Boolean(value));

    // Create prompt object data to be created/updated
    let prompt = {
      name: promptData.prompt.name,
      instructions: promptData.prompt.instructions,
      scheduledDate: promptData.prompt.scheduledDate, // ms
      gradeMin: promptData.prompt.gradeMin,
      gradeMax: promptData.prompt.gradeMax,
      tags: promptData.prompt.tags,
      // hashTags: promptData.prompt.hashTags,
      promptTypeId: promptTypeId,
      type: promptData.prompt.type,
      lessonPlanUrl: promptData.prompt.lessonPlanUrl,
      url: isUploadingNewImage ? promptImage.downloadURL : promptData.prompt.url,
      shareName: promptData.prompt.shareName,
      categories: promptData.prompt.categories,
      reviewStatus: promptData.prompt.reviewStatus,
      requirements: promptData.prompt.requirements,
      allowedAgeRanges: promptData.prompt.allowedAgeRanges,
      hideLeaderboard: promptData.prompt.hideLeaderboard,
      ...(isRewardFilled
        ? {
            rewardDetails: {
              type: RewardType.CONSUMER_GOOD,
              ...promptData.prompt.rewardDetails,
            },
          }
        : { rewardDetails: null }),
      ...(promptData.prompt.userId && { userId: promptData.prompt.userId }),
      ...(promptData.prompt.interestId && { interests: [promptData.prompt.interestId] }),
      ...(promptData.prompt.mediaType === PromptMediaType.POLL && {
        pollAnswers,
        randomizePollAnswers: promptData.prompt.randomizePollAnswers,
        pollType: promptData.prompt.pollType,
      }),
    };

    // Default prompt videos to processing status
    if (isUploadingNewVideo) {
      prompt.datePosted = Date.now();
      prompt.processing = true;
      prompt.pendingProcesses = [
        PromptProcessingSteps.MEDIA_UPLOAD,
        PromptProcessingSteps.VIDEO_OPTIMIZATION,
      ];
      videoFilename = addTimestampToFilename(promptData.prompt.video.name).split(' ').join('_');
      prompt.videoFilename = videoFilename;
    }

    // Create/update the prompt accordingly
    let promptResult = isEditing
      ? yield updatePrompt(promptTypeId, promptData.prompt.id, prompt, promptImage)
      : yield createPrompt(prompt, promptImage);

    // Use the scheduled date as set by the user (easier to handle than Firebase's timestamp)
    promptResult.scheduledDate = promptData.prompt.scheduledDate;

    // If there's a video, we start the optimization process asynchronously
    if (isUploadingNewVideo) {
      yield uploadPromptVideo(promptData.prompt.video, videoFilename, () =>
        updateProcessingSteps(promptTypeId, promptResult.id, videoFilename)
      );
      optimizeVideo(promptResult.id);
    }

    if (isEditing && promptTypeId !== prevPromptTypeId) {
      // If the user switched prompt types, we keep track of the prev. prompt type ID for our internal store
      promptResult.prevPromptTypeId = prevPromptTypeId;
    }

    // We then update the prompt locally
    yield updateLocalPromptSaga({ id: promptResult.id, newData: promptResult });

    let notificationTitle = '';
    let notificationMessage = '';

    if (isEditing) {
      if (isUploadingNewVideo) {
        notificationTitle = 'Your edit is being processed!';
        notificationMessage =
          'Your new video is being processed and it will be available soon. All other changes are reflected immediately in the app.';
      } else {
        notificationTitle = 'Your project was edited!';
        notificationMessage = 'Changes will reflect immediately in the app.';
      }
    } else {
      // Creating a new project
      if (isUploadingNewVideo) {
        notificationTitle = 'WOOHOO! Your challenge has been submitted!';
        notificationMessage =
          'Please allow 24-48 hours for your video to be reviewed & approved by our Content Team. Once approved, your challenge will be appropriately scheduled by our Content Team. Once it’s live on our app, you will see your challenge under the “posts” tab on your profile. **Please note this may take up to 5 business days for your challenge to go live from your requested date/time.**';
      } else {
        notificationTitle = 'Your project was created successfully!';
        notificationMessage = 'It is now available for users in the Zigazoo app.';
      }
    }

    yield put(createPromptSuccessAction(notificationTitle, notificationMessage));
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
    yield put(
      createPromptFailureAction(
        'We encountered an issue!',
        'Your prompt could not be created or updated. Please try again. If the error persists, contact us at dave@zigazoo.com for help.'
      )
    );
  }
}

function updateProcessingSteps(promptTypeId, promptId, promptVideoFileName) {
  updatePrompt(promptTypeId, promptId, {
    pendingProcesses: [PromptProcessingSteps.VIDEO_OPTIMIZATION],
    videoFilename: promptVideoFileName,
  });
}

export function* getPromptsSaga() {
  try {
    // If the user is an admin, we get the channel names first
    const userType = yield select(selectors.userType);

    if (userType === 'ADMIN') {
      const channels = yield getChannels();
      yield put(setChannelsAction(channels));
    }

    const filter = yield select(selectors.promptsFilters);
    let sort = yield select(selectors.promptsSort);
    let searchTerm = yield select(selectors.promptsSearchTerm);

    // NOTE: We do not want to apply the filters if the user is not an admin
    if (userType !== 'ADMIN') {
      Object.keys(filter).forEach((key) => {
        filter[key] = null;
      });
      sort = null;
      searchTerm = null;
    }

    // Then, we get all prompts, regardless of user type
    const allPrompts = yield getPrompts({ filter, sort, searchTerm });

    yield put(setPromptsAction(allPrompts));
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
  }
}

export function* updatePromptRanksSaga({ promptTypeId, rankAgeRange, promptRanks }) {
  try {
    yield updatePromptRanks(promptTypeId, rankAgeRange, promptRanks);
    yield put(updatePromptRanksActionSuccess());
  } catch (e) {
    yield put(updatePromptRanksActionFailure(e));
    console.log(e);
    Sentry.captureException(e);
  }
}

export function* assignPromptToGroupSaga(promptData) {
  try {
    yield updatePrompt(promptData.promptTypeId, promptData.promptId, {
      isAssigning: true,
    });
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
  }
}

// Updates the data for a single prompt in Redux
export function* updateLocalPromptSaga({ id, newData }) {
  let isEditing = false;
  let allPrompts = yield select(selectors.prompts);
  const newPrompt = { id, ...newData };
  let addedToNewTypeAlready = false;

  if (newData.prevPromptTypeId) {
    isEditing = true;
    // The prompt is switching types, so we move it around
    // We traverse all prompts, both adding it in the new type and removing it from the old one
    allPrompts.forEach((type, typeIndex) => {
      // If the type where it's going doesn't have any projects, we add the project here (because the inner forEach won't catch it)
      if (type.id === newData.promptTypeId && allPrompts[typeIndex].prompts.length === 0) {
        allPrompts[typeIndex].prompts.push(newPrompt);
        addedToNewTypeAlready = true;
      }

      type.prompts.forEach((prompt, promptIndex) => {
        if (type.id === newData.prevPromptTypeId && prompt.id === id) {
          // Then we found the prompt to remove, so we remove it
          allPrompts[typeIndex].prompts.splice(promptIndex, 1);
        } else if (type.id === newData.promptTypeId && !addedToNewTypeAlready) {
          // Otherwise, if we are in the new type and it hasn't been added, we add it
          allPrompts[typeIndex].prompts.push(newPrompt);
          addedToNewTypeAlready = true;
        }
      });

      // Finally, we sort the prompts for each type to ensure that they look like they would in the app
      allPrompts[typeIndex].prompts.sort((a, b) => a.rank - b.rank);
    });
  } else {
    // The prompt is staying in the same type, so we look for the prompt in our internal data structure
    // and update it if it exists
    allPrompts.forEach((type, typeIndex) => {
      type.prompts.forEach((prompt, promptIndex) => {
        if (prompt.id === id) {
          isEditing = true;
          allPrompts[typeIndex].prompts[promptIndex] = newPrompt;
        }
      });
    });
  }

  // Otherwise, it's a new prompt, so we insert it in the right type as the first element
  if (!isEditing) {
    const typeIndex = allPrompts.findIndex((type) => type.id === newData.promptTypeId);

    if (allPrompts.length) {
      allPrompts[typeIndex].prompts.unshift({ id, ...newData });
    }
  }

  yield put(setPromptsAction(allPrompts));
}
