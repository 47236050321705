import * as Sentry from '@sentry/react';
import { httpsCallable } from '../../utils/functions';

export const getAllInterests = async () => {
  try {
    const response = await httpsCallable('getInterests', {});
    return response.data;
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
    throw new Error(e);
  }
};
