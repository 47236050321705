import React from 'react';
import * as Sentry from '@sentry/react';
import styled from 'styled-components';
import PromptForm from './components/Prompt/PromptForm/PromptForm';
import Navbar from './components/Navbar/Navbar';
import Login from './components/Login/Login';
import AdminLogin from './components/Login/AdminLogin/AdminLogin';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import PromptList from './components/Prompt/PromptList/PromptList';
import ChangePassword from './components/ChangePassword/ChangePassword';
import EditUser from './components/Users/EditUser/EditUser';
import DisableUser from './components/Users/DisableUser/DisableUser';
import InternalApps from './components/Tools/InternalApps';
import CarouselEditor from './components/Tools/CarouselEditor';
import AutoAdsTargetViewsEditor from './components/Tools/AutoAdsTargetViewsEditor';
import DiscoverRankingEditor from './components/Tools/DiscoverRankingEditor';
import UserEditor from './components/Tools/UserEditor';
import GACarouselEditor from './components/Tools/GACarouselEditor';
import SongClipDownloader from './components/Tools/SongClipDownloader';
import ZigazooAudiolocker from './components/Tools/ZigazooAudiolocker';
import ProposedTags from './components/Tags/ProposedTags';
import ModerationFeed from './components/Moderation/ModerationFeed';
import ScrollTop from './components/UI/ScrollTop/ScrollTop';
import { CenteredLayout } from './components/UI/Layouts';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { isStaging, Routes, UserTypes } from './utils/constants';
import { getUserTypeForSession } from './utils/functions';
import StagingLabel from './components/UI/StagingLabel/StagingLabel';
import useSession from './hooks/useSession';
import Spinner from './components/UI/Spinner/Spinner';
import useRemoteConfig from './hooks/useRemoteConfig';
import NewPromptPage from './pages/NewPromptPage';
import EditPromptPage from './pages/EditPromptPage';
import UsersListPage from './pages/UserListPage';
import './App.css';

Sentry.init({
  dsn: 'https://20f0d4f63f5c4c039450cafa4247e4b5@o375091.ingest.sentry.io/6105708',
  environment: isStaging ? 'staging' : 'production',
});

const renderRedirect = (userType) => {
  console.log('renderRedirect', userType);
  switch (userType) {
    case UserTypes.ADMIN:
    case UserTypes.CHANNEL:
    case UserTypes.PRELAUNCH_CHANNEL:
      return <Redirect to={Routes.PROMPTS} />;
    case UserTypes.MODERATOR:
      return <Redirect to={Routes.EDIT_USER} />;
    default:
      return <Login />;
  }
};

function App(props) {
  const { isLoading } = useSession();

  useRemoteConfig();

  return (
    <BrowserRouter>
      <Navbar userType={props.userType} hideLinks={props.forceNewPassword} />
      <ScrollTop />
      {isStaging && <StagingLabel />}
      {isLoading ? (
        <CenteredLayout>
          <Spinner />
        </CenteredLayout>
      ) : props.forceNewPassword ? (
        <ChangePassword />
      ) : (
        <>
          <ProtectedRoute
            exact
            userType={props.userType}
            path={Routes.NEW_PROMPT}
            component={NewPromptPage}
          />
          <ProtectedRoute
            exact
            userType={props.userType}
            path={Routes.EDIT_PROMPT}
            component={EditPromptPage}
          />
          <ProtectedRoute
            exact
            path={Routes.PROMPTS}
            component={PromptList}
            userType={props.userType}
          />
          <ProtectedRoute
            exact
            path={Routes.INTERNAL_APPS}
            component={InternalApps}
            userType={props.userType}
            allowedUserTypes={[UserTypes.ADMIN]}
          />
          <ProtectedRoute
            exact
            path={Routes.CAROUSEL_EDITOR}
            component={CarouselEditor}
            userType={props.userType}
            allowedUserTypes={[UserTypes.ADMIN]}
          />
          <ProtectedRoute
            exact
            path={Routes.USER_EDITOR}
            component={UserEditor}
            userType={props.userType}
            allowedUserTypes={[UserTypes.ADMIN]}
          />

          <ProtectedRoute
            exact
            path={Routes.AUTO_ADS_TARGET_VIEWS_EDITOR}
            component={AutoAdsTargetViewsEditor}
            userType={props.userType}
            allowedUserTypes={[UserTypes.ADMIN]}
          />
          <ProtectedRoute
            exact
            path={Routes.DISCOVER_RANKING_EDITOR}
            component={DiscoverRankingEditor}
            userType={props.userType}
            allowedUserTypes={[UserTypes.ADMIN]}
          />
          <ProtectedRoute
            exact
            path={Routes.GA_CAROUSEL_EDITOR}
            component={GACarouselEditor}
            userType={props.userType}
            allowedUserTypes={[UserTypes.ADMIN]}
          />
          <ProtectedRoute
            exact
            path={Routes.SONGCLIP_DOWNLOADER}
            component={SongClipDownloader}
            userType={props.userType}
            allowedUserTypes={[UserTypes.ADMIN]}
          />
          <ProtectedRoute
            exact
            path={Routes.ZIGAZOO_AUDIOLOCK}
            component={ZigazooAudiolocker}
            userType={props.userType}
            allowedUserTypes={[UserTypes.ADMIN]}
          />
          <ProtectedRoute
            exact
            path={Routes.DISABLE_USER}
            component={DisableUser}
            userType={props.userType}
            allowedUserTypes={[UserTypes.ADMIN, UserTypes.MODERATOR]}
          />
          <ProtectedRoute
            exact
            path={Routes.EDIT_USER}
            component={EditUser}
            userType={props.userType}
            allowedUserTypes={[UserTypes.ADMIN, UserTypes.MODERATOR]}
          />
          <ProtectedRoute
            exact
            path={Routes.USERS_LIST}
            component={UsersListPage}
            userType={props.userType}
            allowedUserTypes={[UserTypes.ADMIN, UserTypes.MODERATOR]}
          />
          <ProtectedRoute
            exact
            path={Routes.PROPOSED_TAGS}
            component={ProposedTags}
            userType={props.userType}
            allowedUserTypes={[UserTypes.ADMIN, UserTypes.MODERATOR]}
          />
          <ProtectedRoute
            exact
            path={Routes.MODERATION_FEED}
            component={ModerationFeed}
            userType={props.userType}
            allowedUserTypes={[UserTypes.ADMIN, UserTypes.MODERATOR]}
          />
          <Route path={Routes.LOG_IN}>{props.userType ? <Redirect to="/" /> : <Login />}</Route>
          <Route path={Routes.ADMIN_LOGIN}>
            {props.userType ? <Redirect to="/" /> : <AdminLogin />}
          </Route>
          <Route path="/" exact>
            {renderRedirect(props.userType)}
          </Route>
        </>
      )}
    </BrowserRouter>
  );
}

const mapStateToProps = (state) => {
  return {
    userType: getUserTypeForSession(state.session),
    forceNewPassword: state.session.forceNewPassword,
  };
};

export default connect(mapStateToProps)(App);
