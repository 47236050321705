import * as Sentry from '@sentry/react';
import { httpsCallable } from '../../../utils/functions';
import { AgeRange } from '../../../utils/constants';

const SEARCH_LIMIT = 20;

export const searchTags = async (value, ageRanges = []) => {
  const hasBothRanges = [AgeRange.GA, AgeRange.KIDS].every((ageRange) =>
    ageRanges.includes(ageRange)
  );

  try {
    const response = await httpsCallable(
      'searchTags',
      {
        limit: SEARCH_LIMIT,
        searchTerms: [value],
      },
      // NOTE: this is a hack to send a right ageRange, searchTags function takes a context ageRange
      { ageRange: hasBothRanges ? AgeRange.ADMIN : ageRanges[0] }
    );
    const tags = response.data?.tags;

    return tags;
  } catch (e) {
    console.error(e);
    Sentry.captureException(e);
    throw new Error(e);
  }
};
