import React from 'react';
import styled from 'styled-components';
import PromptFilterAgeRange from './PromptFilterAgeRange';
import PromptFilterReleaseStatus from './PromptFilterReleaseStatus';

const StyledFilters = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -20px;
`;

const PromptFilter = () => {
  return (
    <StyledFilters>
      <PromptFilterAgeRange />
      <PromptFilterReleaseStatus />
    </StyledFilters>
  );
};

export default PromptFilter;
