import * as Sentry from '@sentry/react';
import { httpsCallable } from '../../utils/functions';

export const getPrompt = async (promptId) => {
  try {
    const response = await httpsCallable('getAdminPortalPrompt', { promptId });

    return response.data;
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
    throw new Error(e);
  }
};
