import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import theme from '../../../theme';
import { RowContainer, ColumnContainer, Column } from '../../UI/Grid';
import Button from '../../UI/Button/Button';
import { CheckIcon, CrossIcon } from '../../UI/Icons';
import {
  acceptProposedTagAction,
  rejectProposedTagAction,
} from '../../../store/tags/proposed/actions';

const ListItemContainer = styled(RowContainer)`
  && {
    padding: 10px 15px;
    opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};

    &:hover {
      background-color: ${theme.color.fogGrey};
    }
  }
`;

const TruncatedColumn = styled(ColumnContainer)`
  && {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const ActionColumn = styled(ColumnContainer)`
  && {
    text-align: right;
  }
`;

const AcceptButton = styled(Button)`
  background-color: ${theme.color.green};
  margin-right: 10px;
  padding: 5px 10px;
}`;

const RejectButton = styled(Button)`
  background-color: ${theme.color.red};
  padding: 5px 10px;
}`;

const ErrorLabel = styled.span`
  color: ${theme.color.red};
  display: block;
  font-size: 12px;
`;

const ProposedTagsListItem = ({ id, displayName, createdAt, usageCount, isLoading, error }) => {
  const dispatch = useDispatch();
  const handleAccept = () => {
    dispatch(acceptProposedTagAction(id));
  };

  const handleReject = () => {
    dispatch(rejectProposedTagAction(id));
  };

  const createdAtDate = useMemo(() => moment.unix(createdAt / 1000).format('YYYY/MM/DD HH:mm'), [
    createdAt,
  ]);

  return (
    <ListItemContainer isDisabled={isLoading}>
      <TruncatedColumn width="50%">
        {displayName}
        {error && <ErrorLabel>{error}</ErrorLabel>}
      </TruncatedColumn>
      <Column width="30%">{createdAtDate}</Column>
      <Column width="10%">{usageCount || '-'}</Column>
      <ActionColumn width="10%">
        <AcceptButton clicked={handleAccept} enabled={!isLoading}>
          <CheckIcon color="white" height="14px" width="14px" />
        </AcceptButton>
        <RejectButton clicked={handleReject} enabled={!isLoading}>
          <CrossIcon color="white" height="14px" width="14px" />
        </RejectButton>
      </ActionColumn>
    </ListItemContainer>
  );
};

ProposedTagsListItem.defaultProps = {
  error: null,
  isLoading: false,
};

ProposedTagsListItem.propTypes = {
  createdAt: PropTypes.number.isRequired,
  displayName: PropTypes.string.isRequired,
  error: PropTypes.string,
  id: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  usageCount: PropTypes.number,
};

export default ProposedTagsListItem;
