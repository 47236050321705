export const SEARCH_TAGS = 'SEARCH_TAGS';
export const SEARCH_TAGS_SUCCESS = 'SEARCH_TAGS_SUCCESS';
export const SEARCH_TAGS_FAILURE = 'SEARCH_TAGS_FAILURE';
export const SEARCH_TAGS_RESET = 'SEARCH_TAGS';

export const searchTagsAction = (value, ageRange) => ({
  type: SEARCH_TAGS,
  value,
  ageRange,
});

export const searchTagsSuccessAction = (tags) => ({
  type: SEARCH_TAGS_SUCCESS,
  tags,
});

export const searchTagsFailureAction = (error) => ({
  type: SEARCH_TAGS_FAILURE,
  error,
});

export const resetSearchedTagsAction = (tags) => ({
  type: SEARCH_TAGS_SUCCESS,
});
