import { GET_PROMPT, GET_PROMPT_SUCCESS, GET_PROMPT_FAILURE } from './actions';

const defaultState = {
  isLoading: true,
  errors: null,
  prompt: null,
};

export const prompt = (state = defaultState, action) => {
  switch (action.type) {
    case GET_PROMPT:
      return {
        ...state,
        isLoading: true,
        prompt: null,
      };

    case GET_PROMPT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        prompt: action.prompt,
      };

    case GET_PROMPT_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: action.errors,
      };

    default:
      return state;
  }
};
