import { userIndex } from '../../config/algolia';
import { cometChatApiKey } from '../../config/cometChat';
import { CometChat } from '@cometchat-pro/chat';
import { httpsCallable } from '../../utils/functions';

export const getUser = async (username) => await httpsCallable('getUser', { username });

export const deleteUser = async (uid) =>
  await httpsCallable('deleteUser', { userId: uid, adminDelete: true, permanentDelete: true });

export const updateUser = async (request) => await httpsCallable('updateUser', request);

export const updateUsernameInSearch = async (uid, updateRequest) => {
  try {
    const request = { ...updateRequest, objectID: uid };
    await userIndex.partialUpdateObject(request);
  } catch (err) {
    console.log('FAILED to update user in search');
  }
};

export const updateUsernameInChat = async (uid, newUsername) => {
  try {
    const user = new CometChat.User(uid);
    user.setName(newUsername);
    await CometChat.updateUser(user, cometChatApiKey);
  } catch (err) {
    console.log('FAILED to update user in CometChat');
  }
};

export const validateUsername = async (request) => await httpsCallable('validateUsername', request);

export const getUsersList = async (params) => await httpsCallable('getAdminPortalUsers', params);
