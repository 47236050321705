import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Routes } from '../../../../../utils/constants';
import { Draggable } from 'react-beautiful-dnd';
import Spinner from '../../../../UI/Spinner/Spinner';
import ShareIcon from '../../../../UI/ShareIcon/ShareIcon';
import { PromptReviewStatus } from '../../../../../utils/constants';
import { setPromptSuccessAction } from '../../../../../store/prompt/actions';
import theme from '../../../../../theme';
import AgeRangeMarker from './AgeRangeMarker';
import usePromptRankMode from '../../hooks/usePromptRankMode';
import usePromptListener from '../../hooks/usePromptListener';
import {
  StyledPromptItemWrapper,
  StyledPromptItemContent,
  StyledStatusLabel,
  CenteredRow,
  StyledPromptItemTextWrapper,
  StyledPromptItemText,
  StyledUserName,
  StyledThumbnail,
  StyledPromptItemMutedWrapper,
  PromptItemMutedContent,
  PromptItemMutedText,
} from './PromptItem.styles';

const PromptItem = ({ prompt: currentPrompt, rank, isReviewMode }) => {
  const dispatch = useDispatch();
  const { userType } = useSelector((state) => state.session);
  const { isUpdatingRanks, rankingPromptId } = useSelector((state) => state.prompts);
  const { isDraggable } = usePromptRankMode();
  const prompt = usePromptListener(currentPrompt);
  const isAdmin = userType === 'ADMIN';
  const status = prompt.isLive ? PromptReviewStatus.LIVE : prompt.reviewStatus;
  const isLive = status === PromptReviewStatus.LIVE;
  const scheduledDate = prompt.scheduledDateTimestamp;
  const isProcessing = prompt.processing;
  const hasFailed = prompt.failed;
  const isRanking = rankingPromptId === prompt.id;
  const isDraggableDisabled = !isAdmin || !isDraggable || isUpdatingRanks;

  // If it's a scheduled post, we build the date string to display
  const scheduledDateFormatted = useMemo(() => {
    if (isLive) {
      return null;
    }

    const date = new Date(scheduledDate);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear() % 100}`;
  }, [isLive, scheduledDate]);

  const isMuted = isReviewMode && (isProcessing || isLive);

  // TODO: Refactor this
  const [statusLabelStyle, statusLabelText] = useMemo(() => {
    let style = {
      backgroundColor: null,
      color: null,
    };
    let text = '';

    if (hasFailed) {
      style.backgroundColor = theme.color.red;
      style.color = theme.color.white;
      text = 'FAILED';
    } else if (status === PromptReviewStatus.LIVE) {
      style.backgroundColor = theme.color.green;
      style.color = theme.color.white;
      text = 'LIVE';
    } else if (
      !status ||
      status === PromptReviewStatus.UNREVIEWED ||
      status === PromptReviewStatus.OBSOLETE_UNREVIEWED
    ) {
      style.backgroundColor = isAdmin ? theme.color.mediumGrey : theme.color.yellow;
      style.color = isAdmin ? theme.color.white : theme.color.black;
      text = isAdmin ? `NEEDS REVIEW: ${scheduledDateFormatted}` : scheduledDateFormatted;
    } else if (status === PromptReviewStatus.DENIED) {
      style.backgroundColor = theme.color.red;
      style.color = theme.color.white;
      text = 'ON HOLD ⚠️';
    } else if (status === PromptReviewStatus.AUTOMODERATION_DENIED) {
      style.backgroundColor = theme.color.red;
      style.color = theme.color.white;
      text = 'Automod denied';
    } else {
      style.backgroundColor = theme.color.yellow;
      style.color = theme.color.black;
      text = `${scheduledDateFormatted} ✔️`;
    }

    return [style, text];
  }, [hasFailed, status, isAdmin, scheduledDateFormatted]);

  const highlightedName = useMemo(() => {
    if (!prompt.highlights || prompt.highlights.length === 0) {
      return prompt.name;
    }

    return prompt.highlights[0].texts
      .map((text) => {
        if (text.type == 'hit') {
          return '<mark>' + text.value + '</mark>';
        } else {
          return text.value;
        }
      })
      .join('')
      .replace(/\s/g, '&nbsp;');
  }, [prompt.highlights]);

  // Sends the user to the create-edit screen for the selected prompt
  const redirectToEdit = (e) => {
    if (isProcessing) {
      return;
    }

    window.open(Routes.EDIT_PROMPT.replace(':id', prompt.id), '_blank');
  };

  const renderStandardView = (provided) => (
    <StyledPromptItemWrapper
      isDisabled={isProcessing || isUpdatingRanks}
      hasFailed={hasFailed}
      onClick={redirectToEdit}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}>
      {isUpdatingRanks && isRanking && (
        <Spinner style={{ position: 'absolute', inset: 0, margin: 'auto' }} />
      )}
      <StyledPromptItemContent>
        <CenteredRow
          style={{
            alignItems: isProcessing ? 'center' : 'stretch',
          }}>
          {isAdmin && <AgeRangeMarker ageRanges={prompt.allowedAgeRanges || []} />}

          {!isProcessing ? (
            <StyledThumbnail
              src={prompt.thumbnail_300_url ? prompt.thumbnail_300_url : prompt.url}
            />
          ) : (
            <Spinner style={{ width: '150px' }} />
          )}
          <StyledPromptItemTextWrapper>
            <StyledPromptItemText dangerouslySetInnerHTML={{ __html: highlightedName }} />
            {isAdmin && prompt.channelRelation?.username && (
              <StyledUserName>{prompt.channelRelation.username}</StyledUserName>
            )}
          </StyledPromptItemTextWrapper>
        </CenteredRow>
        <CenteredRow>
          {!isProcessing && (
            <StyledStatusLabel style={statusLabelStyle}>{statusLabelText}</StyledStatusLabel>
          )}
          {!isProcessing && isLive && (
            <CenteredRow>
              <ShareIcon type="facebook" promptId={prompt.id} />
              <ShareIcon type="twitter" promptId={prompt.id} />
              <ShareIcon type="share" promptId={prompt.id} />
            </CenteredRow>
          )}
        </CenteredRow>
      </StyledPromptItemContent>
    </StyledPromptItemWrapper>
  );

  const renderMutedView = (provided) => (
    <StyledPromptItemMutedWrapper
      onClick={redirectToEdit}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}>
      <PromptItemMutedContent>
        {(isProcessing || (isUpdatingRanks && isRanking)) && (
          <Spinner style={{ position: 'absolute', inset: 0, margin: 'auto' }} />
        )}
        {isAdmin && <AgeRangeMarker inline ageRanges={prompt.allowedAgeRanges || []} />}
        <PromptItemMutedText dangerouslySetInnerHTML={{ __html: highlightedName }} />
      </PromptItemMutedContent>
    </StyledPromptItemMutedWrapper>
  );

  return (
    <Draggable draggableId={prompt.id} index={rank} isDragDisabled={isDraggableDisabled}>
      {isMuted ? renderMutedView : renderStandardView}
    </Draggable>
  );
};

PromptItem.propTypes = {
  prompt: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string,
    thumbnail_300_url: PropTypes.string,
    allowedAgeRanges: PropTypes.arrayOf(PropTypes.string),
    channelRelation: PropTypes.shape({
      username: PropTypes.string,
    }),
    isLive: PropTypes.bool.isRequired,
    scheduledDateTimestamp: PropTypes.number,
    status: PropTypes.string,
    processing: PropTypes.bool,
    failed: PropTypes.bool,
    reviewStatus: PropTypes.string,
    isUpdatingRanks: PropTypes.bool,
    rankingPromptId: PropTypes.string,
  }).isRequired,
  rank: PropTypes.number.isRequired,
  promptTypeId: PropTypes.string.isRequired,
  isReviewMode: PropTypes.bool,
  isRanking: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  userType: state.session.userType,
});

// INFO: This is a performance optimization to prevent the prompt from re-rendering when it doesn't need to
function promptPropsAreEqual(prevPrompt, nextPrompt) {
  return (
    prevPrompt.rank === nextPrompt.rank &&
    prevPrompt.isReviewMode === nextPrompt.isReviewMode &&
    prevPrompt.prompt.isLive === nextPrompt.prompt.isLive &&
    prevPrompt.prompt.status === nextPrompt.prompt.status &&
    prevPrompt.prompt.reviewStatus === nextPrompt.prompt.reviewStatus &&
    prevPrompt.prompt.processing === nextPrompt.prompt.processing &&
    prevPrompt.prompt.failed === nextPrompt.prompt.failed &&
    prevPrompt.highlights === nextPrompt.highlights
  );
}

export default React.memo(PromptItem, promptPropsAreEqual);
