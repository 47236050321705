import {
  GET_USER,
  SET_USER,
  CLEAR_USER,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  GET_USER_FAILURE,
  VALIDATE_USERNAME,
  VALIDATE_USERNAME_SUCCESS,
  VALIDATE_USERNAME_FAILURE,
  GET_USERS_LIST,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_LIST_FAILURE,
  SET_USERS_LIST_SEARCH_TERM,
} from './actions';

export const defaultState = {
  user: {},
  loading: false,
  isValidating: false,
  justUpdatedUser: false,
  justDeletedUser: false,
  validatedUsername: undefined,
  error: '',
  usersList: [],
  isUsersListLoading: true,
  hasNext: false,
  usersListSearchTerm: '',
};

export const users = (state = defaultState, action) => {
  switch (action.type) {
    case GET_USER:
      return { ...state, loading: true, error: '' };

    case SET_USER:
      return { ...state, user: action.user, loading: false };

    case CLEAR_USER:
      return {
        ...state,
        user: {},
        loading: false,
        justUpdatedUser: false,
        justDeletedUser: false,
        error: '',
      };

    case UPDATE_USER:
      return { ...state, loading: true, justUpdatedUser: false };

    case UPDATE_USER_SUCCESS:
      return { ...state, loading: false, justUpdatedUser: true };

    case DELETE_USER:
      return { ...state, loading: true, justDeletedUser: false };

    case DELETE_USER_SUCCESS:
      return { ...state, loading: false, justDeletedUser: true };

    case GET_USER_FAILURE:
    case UPDATE_USER_FAILURE:
      return { ...state, loading: false, error: action.error };

    case VALIDATE_USERNAME:
      return { ...state, validatedUsername: undefined, isValidating: true };

    case VALIDATE_USERNAME_SUCCESS:
      return {
        ...state,
        validatedUsername: action.isValid ? action.username : undefined,
        isValidating: false,
      };

    case VALIDATE_USERNAME_FAILURE:
      return { ...state, validatedUsername: undefined, isValidating: false, error: action.error };

    case GET_USERS_LIST:
      return {
        ...state,
        isUsersListLoading: true,
        ...(action.skip ? {} : { usersList: [] }),
      };

    case GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        isUsersListLoading: false,
        usersList: [...state.usersList, ...action.users],
        hasNext: action.hasNext,
      };

    case GET_USERS_LIST_FAILURE:
      return {
        ...state,
        isUsersListLoading: false,
        error: action.error,
      };

    case SET_USERS_LIST_SEARCH_TERM:
      return { ...state, usersListSearchTerm: action.searchTerm };

    default:
      return state;
  }
};
