import * as Sentry from '@sentry/react';
import { httpsCallable } from '../../../utils/functions';

const PROPOSED_TAGS_LIMIT = 50;
const SEARCHABLE_TAG_STATUS = 'SEARCHABLE';
const REJECTED_TAG_STATUS = 'REJECTED';

export const getAllProposedTags = async () => {
  try {
    const response = await httpsCallable('getProposedTags', { limit: PROPOSED_TAGS_LIMIT });
    const proposedTags = response.data?.proposedTags;
    const hasNextRecords = proposedTags?.length === PROPOSED_TAGS_LIMIT;

    return [proposedTags, hasNextRecords];
  } catch (e) {
    console.error(e);
    Sentry.captureException(e);
    throw new Error(e);
  }
};

const updateTagStatus = async (tagId, status) => {
  try {
    const response = await httpsCallable('updateTagStatus', { tagId, status });
    return response.data;
  } catch (e) {
    console.error(e);
    Sentry.captureException(e);
    throw new Error(e);
  }
};

export const acceptProposedTag = async (tagId) => updateTagStatus(tagId, SEARCHABLE_TAG_STATUS);
export const rejectProposedTag = async (tagId) => updateTagStatus(tagId, REJECTED_TAG_STATUS);
