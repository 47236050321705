import React, { useState } from 'react';
import { connect } from 'react-redux';
import GoogleLogin from './GoogleLogin/GoogleLogin';
import EmailLogin from './EmailLogin/EmailLogin';
import Spinner from '../UI/Spinner/Spinner';
import HorizontalRule from '../UI/HorizontalRule/HorizontalRule';
import { authorizeUserAction } from '../../store/session/actions';

const Login = (props) => {
  const [loginCredentials, setLoginCredentials] = useState({ email: '', password: '' });

  const handleLoginCredentials = (event) => {
    setLoginCredentials({ ...loginCredentials, [event.target.name]: event.target.value });
  };

  const handleLogin = (event) => {
    props.authorizeUser('channel', loginCredentials.email, loginCredentials.password);
  };

  const handleGoogleSSO = (event) => {
    props.authorizeUser('google');
  };

  return (
    <div className="Center" style={{ width: '40%' }}>
      {props.isLoading ? (
        <Spinner />
      ) : (
        <div>
          <EmailLogin
            clicked={handleLogin}
            credentialsHandler={handleLoginCredentials}
            email={loginCredentials.email}
            password={loginCredentials.password}
          />
          <h4 style={{ fontStyle: 'italic' }}>- Or -</h4>
          <GoogleLogin clicked={handleGoogleSSO} />
          <br />

          <p className="Error">{!props.isLoading && props.error}</p>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  error: state.session.error,
  isLoading: state.session.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  authorizeUser: (loginType, email, password) =>
    dispatch(authorizeUserAction(loginType, email, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
