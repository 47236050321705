export const GET_ALL_PROMPT_TYPES = 'GET_ALL_PROMPT_TYPES';
export const CREATE_PROMPT = 'CREATE_PROMPT';
export const CREATE_PROMPT_SUCCESS = 'CREATE_PROMPT_SUCCESS';
export const CREATE_PROMPT_FAILURE = 'CREATE_PROMPT_FAILURE';
export const SET_PROMPT_TYPES = 'SET_PROMPT_TYPES';
export const CLEAR_NOTIFICATION_MESSAGE = 'CLEAR_NOTIFICATION_MESSAGE';
export const GET_PROMPTS = 'GET_PROMPTS';
export const SET_PROMPTS = 'SET_PROMPTS';
export const UPDATE_PROMPT_RANKS = 'UPDATE_PROMPT_RANKS';
export const UPDATE_PROMPT_RANKS_SUCCESS = 'UPDATE_PROMPT_RANKS_SUCCESS';
export const UPDATE_PROMPT_RANKS_FAILURE = 'UPDATE_PROMPT_RANKS_FAILURE';
export const ASSIGN_PROMPT_TO_GROUP = 'ASSIGN_PROMPT_TO_GROUP';
export const UPDATE_PROGRESS_BAR = 'UPDATE_PROGRESS_BAR';
export const UPDATE_LOCAL_PROMPT = 'UPDATE_LOCAL_PROMPT';
export const UPDATE_SEND_TO_PROMPTS = 'UPDATE_SEND_TO_PROMPTS';
export const SET_CHANNELS = 'SET_CHANNELS';
export const SET_PROMPTS_FILTER = 'SET_PROMPTS_FILTER';
export const SET_PROMPTS_SEARCH_TERM = 'SET_PROMPTS_SEARCH_TERM';
export const PUSH_UP_PROMPT = 'PUSH_UP_PROMPT';
export const PREVENT_PROMPTS_LOAD = 'PREVENT_PROMPTS_LOAD';

export const getAllPromptTypesAction = () => ({
  type: GET_ALL_PROMPT_TYPES,
});

export const setPromptTypesAction = (promptTypes) => ({
  type: SET_PROMPT_TYPES,
  promptTypes: promptTypes,
});

export const createPromptAction = (prompt, isEditing, allPrompts) => {
  return {
    type: CREATE_PROMPT,
    prompt,
    isEditing,
    allPrompts,
  };
};

export const createPromptSuccessAction = (title, message) => ({
  type: CREATE_PROMPT_SUCCESS,
  title,
  message,
});

export const createPromptFailureAction = (title, message) => ({
  type: CREATE_PROMPT_FAILURE,
  title,
  message,
});

export const clearNotificationMessageAction = () => ({
  type: CLEAR_NOTIFICATION_MESSAGE,
});

export const getPromptsAction = () => ({
  type: GET_PROMPTS,
});

export const setPromptsAction = (prompts, { force = false } = {}) => ({
  type: SET_PROMPTS,
  prompts,
  force,
});

export const updatePromptRanksAction = (promptTypeId, rankAgeRange, promptRanks, promptId) => ({
  type: UPDATE_PROMPT_RANKS,
  promptTypeId,
  rankAgeRange,
  promptRanks,
  promptId,
});

export const updatePromptRanksActionSuccess = () => ({
  type: UPDATE_PROMPT_RANKS_SUCCESS,
});

export const updatePromptRanksActionFailure = (error) => ({
  type: UPDATE_PROMPT_RANKS_FAILURE,
  error,
});

export const assignPromptToGroupAction = (promptTypeId, promptId) => ({
  type: ASSIGN_PROMPT_TO_GROUP,
  promptTypeId,
  promptId,
});

export const updateLocalPromptAction = (id, newData) => ({
  type: UPDATE_LOCAL_PROMPT,
  id,
  newData,
});

export const updateSendToPromptsAction = (sendToPrompts) => ({
  type: UPDATE_SEND_TO_PROMPTS,
  sendToPrompts,
});

export const setChannelsAction = (channels) => ({
  type: SET_CHANNELS,
  channels,
});

export const setPromptsFilterAction = (filter) => ({
  type: SET_PROMPTS_FILTER,
  filter,
});

export const setPromptsSearchTermAction = (searchTerm) => ({
  type: SET_PROMPTS_SEARCH_TERM,
  searchTerm,
});

export const pullUpPromptAction = (promptTypeId, promptId, ageRange, scheduledDate) => ({
  type: PUSH_UP_PROMPT,
  promptTypeId,
  promptId,
  ageRange,
  scheduledDate, // ms
});

export const preventPromptsLoadAction = () => ({
  type: PREVENT_PROMPTS_LOAD,
});
