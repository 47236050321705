import usePromptFilters from './usePromptFilters';
import usePromptSearch from './usePromptSearch';
import usePromptMode from './usePromptMode';

export default function usePromptRankMode() {
  const { hasSearchTerm } = usePromptSearch();
  const { isRankingMode } = usePromptMode();
  const { isKidsRangeSelected, isGARangeSelected } = usePromptFilters();
  const isEnabled = isRankingMode;
  const isKidsSelected = isKidsRangeSelected && isEnabled;
  const isGASelected = isGARangeSelected && isEnabled;
  const isLocked = !!hasSearchTerm;
  const isDraggable = !isLocked && (isKidsRangeSelected || isGARangeSelected);

  return {
    isKidsSelected,
    isGASelected,
    isDraggable,
    isLocked,
    isEnabled,
  };
}
