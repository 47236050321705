import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import TextInput from '../../UI/TextInput/TextInput';
import Button from '../../UI/Button/Button';
import Spinner from '../../UI/Spinner/Spinner';
import HorizontalRule from '../../UI/HorizontalRule/HorizontalRule';
import { getUserAction, clearUserAction, deleteUserAction } from '../../../store/users/actions';

const isStaging = process.env.ZIGAZOO_ENV === 'staging';

const DisableUser = (props) => {
  const [username, setUsername] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    return () => {
      // Reset the user field
      props.clearUser();
    };
  }, []);

  useEffect(() => {
    if (props.justDeletedUser) {
      setSuccess(true);
      props.clearUser();
      setUsername('');
    }
  }, [props.justDeletedUser]);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const showConfirmDialog = () => {
    const dialog = window.confirm(
      'Are you sure you want to disable this user and delete all their videos?'
    );

    if (dialog === true) {
      props.deleteUser(props.user.uid);
    }
  };

  return (
    <div className="Container">
      <h1>Disable User & Delete Videos</h1>
      <HorizontalRule />
      {props.loading ? (
        <Spinner />
      ) : success ? (
        <div className="Container">
          <p>The user was disabled and their videos deleted successfully!</p>
        </div>
      ) : (
        <>
          {props.user && !props.user.uid ? (
            <TextInput
              name="username"
              placeholder="Enter username"
              style={{ width: '30%' }}
              changed={handleUsernameChange}
              value={props.user.username}
            />
          ) : (
            <h2>{props.user.username}&apos;s Videos</h2>
          )}
          {props.user && props.user.videos && props.user.videos.length === 0 && (
            <p>This user has no videos.</p>
          )}
          {props.user &&
            props.user.videos &&
            props.user.videos.map((video, index) => {
              const d = new Date(Date.parse(video.timeCreated.split('T')[0]));
              const dateString = `${monthNames[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;

              return (
                <div
                  style={{
                    width: '30%',
                    display: 'inline-block',
                    marginBottom: '30px',
                    marginLeft: '15px',
                  }}
                  key={video.fullPath}>
                  <span style={{ position: 'relative', bottom: '6px' }}>{dateString}</span>
                  <video
                    style={{
                      boxShadow:
                        'rgba(0, 0, 0, 0.1) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px',
                      width: '100%',
                    }}
                    height="240"
                    controls>
                    <source
                      src={`https://storage.googleapis.com/${
                        isStaging
                          ? process.env.REACT_APP_DEV_FIREBASE_STORAGE_BUCKET
                          : process.env.REACT_APP_PROD_FIREBASE_STORAGE_BUCKET
                      }/${video.fullPath}`}></source>
                  </video>
                </div>
              );
            })}
          <br />
          {props.user && props.user.uid ? (
            <Button
              className="Destructive-Button"
              style={{ marginTop: '10px' }}
              enabled={true}
              title="Disable User & Delete All Videos"
              clicked={showConfirmDialog}
            />
          ) : (
            <Button
              style={{ marginTop: '10px' }}
              enabled={username !== ''}
              title="Find"
              clicked={() => props.getUser(username)}
            />
          )}
          {props.error && <p className="Error">{props.error}</p>}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.users.user,
  loading: state.users.loading,
  justDeletedUser: state.users.justDeletedUser,
  error: state.users.error,
});

const mapDispatchToProps = (dispatch) => ({
  getUser: (username) => dispatch(getUserAction(username)),
  clearUser: () => dispatch(clearUserAction()),
  deleteUser: (uid) => dispatch(deleteUserAction(uid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DisableUser);
