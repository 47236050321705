import React from 'react';
import FormLabel from '../../../../UI/FormLabel/FormLabel';
import TagField from './TagField/TagField';

const TagsContainer = (props) => {
  return (
    <div>
      <FormLabel label={props.label} />
      <p style={{ fontSize: '.9em', fontStyle: 'italic', marginTop: '0px' }}>
        Keyword tags make it easier for users to find your project in the Search tab.
      </p>
      {props.readOnly && props.tags.filter((t) => t != '').length === 0 ? (
        <p style={{ margin: '0px' }}>No tags set.</p>
      ) : (
        props.tags.map((e, i) => (
          <TagField
            key={i}
            id={i}
            handler={props.handler}
            value={props.tags[i]}
            readOnly={props.readOnly}
          />
        ))
      )}
    </div>
  );
};

export default TagsContainer;
