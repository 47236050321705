import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import Button from '../Button/Button';
import theme from '../../../theme';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const ModalFade = styled.div`
  animation: ${fadeIn} 0.3s ease-in-out;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: ${(props) => (props.showModal ? 'block' : 'none')};
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
`;

const ModalDialog = styled.div`
  background-color: #fff;
  margin: 80px auto;
  max-width: 80%;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

const ModalContent = styled.div``;

const ModalHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid;
  border-color: ${theme.color.mediumGrey};
  padding: 25px 20px;
  font-size: 24px;
  line-height: 24px;
`;

const ModalTitle = styled.h5`
  margin: 0;
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  font-size: 30px;
  line-height: 1;
  margin: 0;
  padding: 0;
  cursor: pointer;
`;

const ModalBody = styled.div`
  min-height: 400px;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 15px 20px;
  border-top: 1px solid;
  border-color: ${theme.color.mediumGrey};
`;

const Modal = ({ isShown, children, title, FooterComponent, onClose }) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(isShown);
  }, [isShown]);

  const handleClose = () => {
    onClose();
    setShowModal(false);
  };

  return (
    <ModalFade showModal={showModal}>
      <ModalDialog>
        <ModalContent>
          <ModalHeader>
            <ModalTitle>{title}</ModalTitle>
            <CloseButton onClick={handleClose}>&times;</CloseButton>
          </ModalHeader>
          <ModalBody>{children}</ModalBody>
          <ModalFooter>
            {FooterComponent ? <FooterComponent /> : <Button clicked={handleClose}>Close</Button>}
          </ModalFooter>
        </ModalContent>
      </ModalDialog>
    </ModalFade>
  );
};

Modal.defaultProps = {
  title: '',
  FooterComponent: null,
};

Modal.propTypes = {
  isShown: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default Modal;
