import {
  SEARCH_TAGS_FAILURE,
  SEARCH_TAGS_SUCCESS,
  SEARCH_TAGS,
  SEARCH_TAGS_RESET,
} from './actions';

const defaultState = {
  error: null,
  isLoading: false,
  searchedTags: [],
};

export const searchedTags = (state = defaultState, action) => {
  switch (action.type) {
    case SEARCH_TAGS: {
      return { ...state, isLoading: true };
    }

    case SEARCH_TAGS_SUCCESS:
      return {
        ...state,
        searchedTags: Object.values(action.tags || {}),
        isLoading: false,
      };

    case SEARCH_TAGS_FAILURE:
      return { ...state, isLoading: false, error: action.error };

    case SEARCH_TAGS_RESET:
      return defaultState;

    default:
      return state;
  }
};
