import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const VideoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  height: 100%;
  width: 100%;
`;

const VideoPlayer = ({ videoSource, children, ...props }) => (
  <VideoContainer>
    <video controls src={videoSource} {...props}>
      Your browser does not support the video tag.
      {children}
    </video>
  </VideoContainer>
);

VideoPlayer.propTypes = {
  videoSource: PropTypes.string,
  children: PropTypes.node,
};

export default VideoPlayer;
