import React from 'react';
import styled from 'styled-components';
import Button from '../Button/Button';
import theme from '../../../theme';

const StyledButtonGroup = styled.div`
  border-radius: 5px;
  overflow: hidden;
  display: flex;

  button {
    border-right: 1px solid ${theme.color.darkBlue};

    &:last-child {
      border-right: none;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }
`;

const StyledButton = styled(Button)`
  border-radius: 0;
  text-transform: initial;
  font-size: 14px;
  color: ${theme.color.white};
  background-color: ${(props) => (props.active ? theme.color.darkBlue : theme.color.blue)};
  padding: 10px 20px;
`;

const ButtonGroup = ({ buttons, activeButton, onButtonClick }) => {
  return (
    <StyledButtonGroup>
      {buttons.map((button) => (
        <StyledButton
          key={button.id}
          clicked={() => onButtonClick(button.id)}
          active={activeButton === button.id}
          enabled={activeButton !== button.id && !button.isDisabled}>
          {button.label}
        </StyledButton>
      ))}
    </StyledButtonGroup>
  );
};

export default ButtonGroup;
