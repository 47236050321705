import React from 'react';
import './RadioButton.scss';

const RadioButton = (props) => {
  return (
    <span className="RadioButton">
      <input
        id={props.id}
        onChange={props.changed}
        value={props.value}
        type="radio"
        checked={props.checked}
        style={props.style}
        disabled={props.disabled}
      />
      <label htmlFor={props.id}>{props.label}</label>
    </span>
  );
};

export default RadioButton;
