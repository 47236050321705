import { put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import { getAllInterests } from './services';
import { getInterestsSuccessAction, getInterestsFailureAction } from './actions';

export function* getAllInterestsSaga() {
  try {
    const records = yield getAllInterests();
    yield put(getInterestsSuccessAction(records));
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
    yield put(getInterestsFailureAction(error.message));
  }
}
