import React from 'react';
import { useParams } from 'react-router-dom';
import PromptForm from '../components/Prompt/PromptForm/PromptForm';
import usePromptLoader from '../components/Prompt/hooks/usePromptLoader';
import { CenteredLayout } from '../components/UI/Layouts';
import Spinner from '../components/UI/Spinner/Spinner';

const EditPromptPage = ({ location, userType }) => {
  const { id } = useParams();
  const { prompt, errors, isLoading } = usePromptLoader(id);

  if (isLoading) {
    return (
      <CenteredLayout>
        <Spinner />
      </CenteredLayout>
    );
  }

  if (errors) {
    return (
      <CenteredLayout>
        <h2>{errors.message}</h2>
      </CenteredLayout>
    );
  }

  return (
    <PromptForm isEditing prompt={{ id, ...prompt }} location={location} userType={userType} />
  );
};

export default EditPromptPage;
