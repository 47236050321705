import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { getRemoteConfig } from 'firebase/remote-config';
import { isStaging } from '../utils/constants';
// Firebase configuration and initialization

const prodConfig = {
  apiKey: process.env.REACT_APP_PROD_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_PROD_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_PROD_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_PROD_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_PROD_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_PROD_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_PROD_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_PROD_FIREBASE_MEASUREMENT_ID,
};

const devConfig = {
  projectId: process.env.REACT_APP_DEV_FIREBASE_PROJECT_ID,
  apiKey: process.env.REACT_APP_DEV_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_DEV_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DEV_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_DEV_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_DEV_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_DEV_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_DEV_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_DEV_FIREBASE_MEASUREMENT_ID,
};

const config = isStaging ? devConfig : prodConfig;
const isLocalFirebaseEnabled = process.env.REACT_APP_DEV_FIREBASE_LOCATION === 'local';

const app = initializeApp(config);
const storage = getStorage(app);
const functions = getFunctions(app);
const db = getFirestore(app);
const auth = getAuth(app);
const remoteConfig = getRemoteConfig(app);

remoteConfig.defaultConfig = {
  hashtags_limit_kids: 5,
  hashtags_limit_ga: 5,
};

if (isLocalFirebaseEnabled) {
  functions.useFunctionsEmulator('http://localhost:5001');
  auth.useEmulator('http://localhost:9099');
}

export { storage, functions, db, auth, app as default, remoteConfig, isStaging };
