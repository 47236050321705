import React from 'react';
import FormLabel from '../FormLabel/FormLabel';
import Slider from 'rc-slider';
import './SliderContainer.css';

const SingleSelectSlider = ({label, min, max, marks, defaultValue, value, onChange, readOnly}) => (
  <div className='sliderContainer'>
    <FormLabel label={label} />
    {readOnly 
      ? <p className='readOnlyStyle'>{value}</p>
      : <Slider
        min={min}
        max={max}
        marks={marks}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
      />
    }
  </div>
);


export default SingleSelectSlider;
