import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import theme from '../../../theme';
import { Grid, RowContainer, Column } from '../../UI/Grid';
import Spinner from '../../UI/Spinner/Spinner';
import ProposedTagsListItem from './ProposedTagsListItem';

const TitleRow = styled(RowContainer)`
  && {
    border-bottom: 2px solid ${theme.color.fogGrey};
    font-weight: bold;
    padding-bottom: 20px;
    padding: 10px 15px;
  }
`;

const BlankList = styled.div`
  padding: 100px 50px;
  text-align: center;
`;

const ListSpinner = styled(Spinner)`
  && {
    margin: 100px auto;
  }
`;

const ProposedTagsList = () => {
  const { proposedTags, isLoading, error } = useSelector((state) => state.proposedTags);
  const isProposedTagsEmpty = proposedTags.length === 0;

  return (
    <Grid>
      <TitleRow>
        <Column width="50%">HashTag Name</Column>
        <Column width="30%">Proposed At</Column>
        <Column width="10%">Uses</Column>
        <Column width="10%"></Column>
      </TitleRow>
      {isLoading ? (
        <BlankList>
          <ListSpinner />
        </BlankList>
      ) : error ? (
        <BlankList>
          <p>Something went wrong. Please try again later.</p>
          <p>{error}</p>
        </BlankList>
      ) : isProposedTagsEmpty ? (
        <BlankList>
          I&apos;m sorry, but it looks like we couldn&apos;t find any more proposed tags to suggest
          at this time.
        </BlankList>
      ) : (
        proposedTags.map((proposedTag) => (
          <ProposedTagsListItem
            key={proposedTag.id}
            {...proposedTag.rawData}
            isLoading={proposedTag.isLoading}
            error={proposedTag.error}
          />
        ))
      )}
    </Grid>
  );
};

export default ProposedTagsList;
