import toArray from 'lodash/toArray';

export function areArraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  arr1.sort();
  arr2.sort();

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
}

// NOTE: Emoji characters can complicate text length calculation due to
// 			 their variable representation in Unicode.
// 			 Simple emojis are single Unicode characters,
// 			 but complex ones can be composed of multiple Unicode characters
// 			 joined by special characters like Zero Width Joiners (ZWJ).
// 			 Therefore, basic string length operations may count them as multiple characters,
// 			 even though they appear as a single glyph on display
export function getLengthOfTextWithEmoji(text) {
  return toArray(text).length;
}

export function sliceWithEmojis(str, length) {
  const symbols = toArray(str);

  return symbols.slice(0, length).join('');
}
