import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Button from '../../../UI/Button/Button';
import { generateUUID } from '../../../../utils/functions';
import PollAnswerField from './PollAnswerField';

const MIN_ANSWERS_NUMBER = 2;
const MAX_ANSWERS_NUMBER = 5;

export const DEFAULT_POLL_ANSWERS_LIST = Array.from({ length: MIN_ANSWERS_NUMBER }, () => ({
  id: `answer-${generateUUID()}`,
  answerText: '',
}));

const StyledPollContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const StyledPollAnserAddButton = styled(Button)`
  font-size: 14px;
  padding: 10px 20px;
`;

const Poll = ({ pollType, pollAnswers, onUpdate }) => {
  const [answers, setAnswers] = useState(pollAnswers || DEFAULT_POLL_ANSWERS_LIST);

  useEffect(() => {
    setAnswers(pollAnswers);
  }, [pollAnswers]);

  const handleAddAnswer = () => {
    const newAnswers = [...answers, { id: `answer-${generateUUID()}`, answerText: '' }];

    setAnswers(newAnswers);
    onUpdate(newAnswers);
  };

  const handleUpdateAnswer = (index, obj) => {
    const newAnswers = [...answers];
    newAnswers[index] = { ...newAnswers[index], ...obj };

    setAnswers(newAnswers);
    onUpdate(newAnswers);
  };

  const handleRemoveAnswer = (index) => {
    const newAnswers = answers.filter((_, idx) => idx !== index);

    setAnswers(newAnswers);
    onUpdate(newAnswers);
  };

  return (
    <StyledPollContainer>
      <br />
      <label>
        <span className="mandatory-field">
          <strong>
            Poll Answers ({answers.length}/{MAX_ANSWERS_NUMBER})
          </strong>
        </span>
      </label>
      <br />
      <br />
      {answers.map((answer, index) => (
        <PollAnswerField
          key={answer.id}
          type={pollType}
          answer={answer}
          isDeletable={answers.length > MIN_ANSWERS_NUMBER}
          onChange={(obj) => handleUpdateAnswer(index, obj)}
          onDelete={() => handleRemoveAnswer(index)}
        />
      ))}
      {answers.length < MAX_ANSWERS_NUMBER && (
        <StyledPollAnserAddButton clicked={handleAddAnswer}>Add Answer</StyledPollAnserAddButton>
      )}
    </StyledPollContainer>
  );
};

export default Poll;
