import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUsersListAction } from '../../../../store/users/actions';

export default function useUsersListLoader() {
  const dispatch = useDispatch();
  const { usersList, isUsersListLoading, error, hasNext, usersListSearchTerm } = useSelector(
    (state) => state.users
  );

  useEffect(() => {
    if (usersList.length || (usersListSearchTerm && !usersList.length)) {
      return;
    }

    dispatch(getUsersListAction({ skip: 0, limit: 30 }));
  }, []);

  const loadMore = () => {
    dispatch(
      getUsersListAction({
        skip: usersList.length,
        limit: 30,
        ...(usersListSearchTerm ? { searchTerm: usersListSearchTerm } : {}),
      })
    );
  };

  return { isLoading: isUsersListLoading, usersList, error, loadMore, hasNext };
}
