import React, { useEffect, useState, useCallback, useRef } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import styled from 'styled-components';
import 'react-image-crop/src/ReactCrop.scss';
import {
  dataURLtoBlob,
  getCroppedImage,
  getImageOrientation,
  IMAGE_ORIENTATION,
} from '../../../utils/imageProcessing';
import Modal from '../Modal';
import Button from '../Button/Button';
import theme from '../../../theme';

const StyledCropBackground = styled.div`
  background-color: #000;
`;

const StyledCropContainer = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledApplyButton = styled(Button)`
  background-color: ${theme.color.blue};
  color: ${theme.color.white};
  margin-left: 20px;

  &:active {
    background-color: ${theme.color.darkBlue};
  }
`;

const StyledCropImage = styled.img``;

const StyledMiddleButton = styled(Button)`
  font-size: 14px;
  padding: 10px 20px;
`;

const StyledUploadButton = styled(StyledMiddleButton)``;

const StyledEditButton = styled(StyledMiddleButton)`
  background-color: ${theme.color.blue};
  color: ${theme.color.white};
`;

const StyledResetButton = styled(StyledMiddleButton)`
  background-color: ${theme.color.red};
  color: ${theme.color.white};
`;

const StyledActions = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;
`;

const DEFAULT_CROP_SETTINGS = { unit: 'px' };

const ImageUploader = ({ aspectRatio, onComplete }) => {
  const [image, setImage] = useState(null);
  const [rawImage, setRawImage] = useState(null);
  const [crop, setCrop] = useState(DEFAULT_CROP_SETTINGS);
  const [isShownModal, setShownModal] = useState(false);
  const [isCropped, setCropped] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [imageOrientation, setImageOrientation] = useState(null);
  const imgRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const reset = () => {
    fileInputRef.current.value = null;
    imgRef.current.src = null;

    setImage(null);
    setRawImage(null);
    setCrop(DEFAULT_CROP_SETTINGS);
    setCropped(false);
    setImageOrientation(null);

    onComplete({ image: null, blobImage: null, croppedImage: null });
  };

  const handleCropChange = (crop) => {
    setCrop(crop);
  };

  const handleImageUpload = (e) => {
    if (!e.target.files.length) {
      return;
    }

    setShownModal(true);

    const file = e.target.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event) => {
      const img = new Image();

      img.onload = function () {
        const orientation = getImageOrientation(this.naturalHeight, this.naturalWidth);

        setImageOrientation(orientation);
      };

      img.src = event.target.result;

      setImage(event.target.result);
    });
    reader.readAsDataURL(file);

    setRawImage(file);
  };

  const handleCropComplete = (crop) => {
    setCrop(crop);
  };

  const handleImageLoad = (e) => {
    const { width, height } = e.currentTarget;

    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: 'px',
          width: width,
        },
        aspectRatio,
        width,
        height
      ),
      width,
      height
    );

    setCrop(crop);
  };

  const handleCloseModal = () => {
    setShownModal(false);
  };

  const handleApplyClick = useCallback(async () => {
    try {
      const croppedDataURL = await getCroppedImage(imgRef.current, crop, rawImage.name);
      const blobImage = dataURLtoBlob(croppedDataURL);

      setCropped(true);
      onComplete({ image: rawImage, blobImage, croppedImage: croppedDataURL });
      setShownModal(false);
    } catch (e) {
      console.error(e);
    }
  }, [imgRef, crop, rawImage]);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleEditClick = () => {
    setShownModal(true);
  };

  const handleResetClick = () => {
    reset();
  };

  const ModalFooter = useCallback(
    () => (
      <>
        <Button clicked={handleCloseModal}>Close</Button>
        <StyledApplyButton clicked={handleApplyClick}>Apply</StyledApplyButton>
      </>
    ),
    [handleApplyClick, handleCloseModal]
  );

  return (
    <div>
      <input
        ref={fileInputRef}
        type="file"
        accept="image/jpeg,image/png"
        style={{ display: 'none' }}
        onChange={handleImageUpload}
      />

      <StyledActions>
        {isCropped ? (
          <>
            <StyledEditButton clicked={handleEditClick}>Edit Image</StyledEditButton>
            <StyledResetButton clicked={handleResetClick}>Reset Image</StyledResetButton>
          </>
        ) : (
          <StyledUploadButton clicked={handleUploadClick}>Browse Image</StyledUploadButton>
        )}
      </StyledActions>

      <Modal
        isShown={isShownModal}
        title="Crop image"
        onClose={handleCloseModal}
        FooterComponent={ModalFooter}>
        <StyledCropBackground>
          <StyledCropContainer>
            <ReactCrop
              aspect={aspectRatio}
              src={image}
              crop={crop}
              onComplete={handleCropComplete}
              onChange={handleCropChange}>
              <StyledCropImage
                ref={imgRef}
                src={image}
                onLoad={handleImageLoad}
                style={
                  imageOrientation === IMAGE_ORIENTATION.PORTRAIT
                    ? { height: `${windowHeight - 330}px` }
                    : { width: '100%', maxHeight: `${windowHeight - 330}px` }
                }
              />
            </ReactCrop>
          </StyledCropContainer>
        </StyledCropBackground>
      </Modal>
    </div>
  );
};

ImageUploader.defaultProps = {
  aspectRatio: 1,
};

export default ImageUploader;
