import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProposedTagsAction } from '../../../store/tags/proposed/actions';
import ProposedTagsList from './ProposedTagsList';

const ProposedTags = () => {
  const { proposedTags, hasNextRecords } = useSelector((state) => state.proposedTags);
  const dispatch = useDispatch();

  useEffect(() => {
    if (proposedTags.length > 0 || !hasNextRecords) return;

    dispatch(getProposedTagsAction());
  }, [dispatch, proposedTags.length, hasNextRecords]);

  return (
    <div className="Container">
      <h1>Proposed Tags</h1>
      <ProposedTagsList />
    </div>
  );
};

export default ProposedTags;
