import React, { useState } from 'react';
import { connect } from 'react-redux';
import GoogleLogin from '../GoogleLogin/GoogleLogin';
import Spinner from '../../UI/Spinner/Spinner';
import { authorizeUserAction } from '../../../store/session/actions';

const AdminLogin = (props) => {
  const handleLogin = (event) => {
    props.authorizeUser('google');
  };

  return (
    <div className="Center">
      {props.isLoading ? (
        <Spinner />
      ) : (
        <div>
          <GoogleLogin clicked={handleLogin} />
          <p className="Error">{!props.isLoading && props.error}</p>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  error: state.session.error,
  isLoading: state.session.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  authorizeUser: (loginType, email, password) =>
    dispatch(authorizeUserAction(loginType, email, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminLogin);
