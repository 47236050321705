import { GET_INTERESTS, GET_INTERESTS_SUCCESS, GET_INTERESTS_FAILURE } from './actions';

const defaultState = {
  isLoading: false,
  interests: [],
};

export const interests = (state = defaultState, action) => {
  switch (action.type) {
    case GET_INTERESTS: {
      return { ...state, isLoading: true };
    }

    case GET_INTERESTS_SUCCESS:
      return { ...state, interests: action.interests, isLoading: false };

    case GET_INTERESTS_FAILURE:
      return { ...state, isLoading: false };

    default:
      return state;
  }
};
