import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { TrashIcon } from '../../../UI/Icons';
import { PollType } from '../../../../utils/constants';
import Button from '../../../UI/Button/Button';
import { useDownloadUrl } from '../../../../hooks';
import theme from '../../../../theme';
import ImageUploader from '../../../UI/ImageUploader/ImageUploader';
import { getLengthOfTextWithEmoji, sliceWithEmojis } from '../../../../utils/helpers';

const StyledPollAnswer = styled.div`
  border: 2px solid #eee;
  border-radius: 5px;
  padding: 25px;
  margin-bottom: 20px;
`;

const StyledPollAnswerRemoveButton = styled(Button)`
  background: none;
  padding: 10px 15px;
`;

const StyledPreviewImage = styled.img`
  width: 300px;
  border-radius: 5px;
  margin-bottom: 20px;
`;

const MAX_ANSWER_LENGTH = 45; // NOTE: Max char count supported in UI
const MAX_IMAGE_ANSWER_LENGTH = 30; // NOTE: Max char count supported in UI

const PollAnswerField = ({ answer, type, isDeletable, onChange, onDelete }) => {
  const [croppedImage, setCroppedImage] = useState(null);
  const [text, setText] = useState(answer.answerText || '');
  const [error, setError] = useState(null);
  const [imageUrl] = useDownloadUrl(answer?.answerImgUrl);
  const fileUrl = croppedImage || imageUrl;
  const hasImageType = type === PollType.IMAGE;
  const maxLength = hasImageType ? MAX_IMAGE_ANSWER_LENGTH : MAX_ANSWER_LENGTH;

  useEffect(() => {
    setText(sliceWithEmojis(text, maxLength));
  }, [maxLength]);

  useEffect(() => {
    onChange({ ...answer, answerText: text });
  }, [text]);

  const handleImageChange = (obj) => {
    const { croppedImage, ...image } = obj;

    setCroppedImage(croppedImage);
    onChange({ ...answer, ...image });
  };

  const handleTextChange = (event) => {
    const { value } = event.target;
    const length = getLengthOfTextWithEmoji(value);

    if (length > maxLength) {
      return;
    }

    setText(event.target.value);
  };

  return (
    <StyledPollAnswer>
      <div>
        {hasImageType && (
          <>
            <ImageUploader onComplete={handleImageChange} />
            <br />
            {fileUrl && <StyledPreviewImage src={fileUrl} />}
          </>
        )}

        <div>
          <input
            required
            type="text"
            maxLength={maxLength}
            value={text}
            placeholder="Type your answer here..."
            onChange={handleTextChange}
          />
          {isDeletable && (
            <StyledPollAnswerRemoveButton clicked={onDelete}>
              <TrashIcon color={theme.color.red} />
            </StyledPollAnswerRemoveButton>
          )}
        </div>
      </div>
    </StyledPollAnswer>
  );
};

export default PollAnswerField;
