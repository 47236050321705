import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { destroySessionAction } from '../../../store/session/actions';
import { Routes, UserTypes } from '../../../utils/constants';
import { auth } from '../../../config/firebase';
import { Link } from 'react-router-dom';
import useScreen from '../../../hooks/useScreen';
import theme from '../../../theme';
import { userPermissions, adminPermissions, adminModeratorPermissions } from './navBarLinks';

import NavMenuDropdown from './NavMenuDropdown';
import { inMemoryPersistence } from 'firebase/auth';

const StyledNavMenu = styled.div`
  margin-left: auto;
  display: block;
  margin-right: 5%;
`;

const StyledNavLink = styled(Link)`
  color: ${theme.color.heatherGrey};
  text-decoration: none;
  font-size: 14px;
  display: inline-block;
  padding: 15px 7px;
  font-weight: 600;
  &:hover,
  &:active,
  &:focus {
    color: ${theme.color.white};
  }
`;

const StyledSplitter = styled.span`
  margin: 0 10px;

  &:before {
    color: ${theme.color.darkBlue};
    content: '|';
  }
`;

const NavMenu = ({ userType }) => {
  const dispatch = useDispatch();
  const { isMobile } = useScreen(1200);
  const navMenuItems = [];
  const dropdownMenuItems = [{ value: 'sign_out', label: 'Sign out' }];

  if (userType !== UserTypes.MODERATOR) {
    navMenuItems.push(...userPermissions);
  }

  if (userType === UserTypes.ADMIN) {
    navMenuItems.push(...adminPermissions);
  }

  if ([UserTypes.ADMIN, UserTypes.MODERATOR].includes(userType)) {
    navMenuItems.push(...adminModeratorPermissions);
  }

  const renderedNavLinks = (options, onClick) =>
    options.map(({ name, pathname, label }) => (
      <StyledNavLink key={name} to={pathname.to || pathname} onClick={onClick}>
        {label}
      </StyledNavLink>
    ));

  const handleOptionClick = (option) => {
    if (option.value === 'sign_out') {
      auth
        .setPersistence(inMemoryPersistence)
        .then(() => {
          return auth.signOut();
        })
        .then(() => {
          dispatch(destroySessionAction());
        });
    }
  };

  return (
    <StyledNavMenu>
      {!isMobile() && renderedNavLinks(navMenuItems)}
      <StyledSplitter />
      {auth.currentUser ? (
        <NavMenuDropdown
          options={dropdownMenuItems}
          optionsMobile={navMenuItems}
          renderOptionsMobile={renderedNavLinks}
          isMobile={isMobile}
          onOptionClick={handleOptionClick}>
          {auth.currentUser.email}
        </NavMenuDropdown>
      ) : (
        <StyledNavLink to={Routes.SIGN_IN}>Sign In</StyledNavLink>
      )}
    </StyledNavMenu>
  );
};

export default NavMenu;
