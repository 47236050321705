export const GET_USER = 'GET_USER';
export const SET_USER = 'SET_USER';
export const CLEAR_USER = 'CLEAR_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const VALIDATE_USERNAME = 'VALIDATE_USERNAME';
export const VALIDATE_USERNAME_SUCCESS = 'VALIDATE_USERNAME_SUCCESS';
export const VALIDATE_USERNAME_FAILURE = 'VALIDATE_USERNAME_FAILURE';
export const GET_USERS_LIST = 'GET_USERS_LIST';
export const GET_USERS_LIST_SUCCESS = 'GET_USERS_LIST_SUCCESS';
export const GET_USERS_LIST_FAILURE = 'GET_USERS_LIST_FAILURE';
export const SET_USERS_LIST_SEARCH_TERM = 'SET_USERS_LIST_SEARCH_TERM';

export const getUserAction = (username) => ({
  type: GET_USER,
  username,
});

export const setUserAction = (user) => ({
  type: SET_USER,
  user,
});

export const clearUserAction = () => ({
  type: CLEAR_USER,
});

export const updateUserAction = (uid, updateRequest) => ({
  type: UPDATE_USER,
  uid,
  updateRequest,
});

export const updateUserSuccessAction = (updateRequest) => ({
  type: UPDATE_USER_SUCCESS,
  updateRequest,
});

export const updateUserFailureAction = (error) => ({
  type: UPDATE_USER_FAILURE,
  error,
});

export const deleteUserAction = (uid) => ({
  type: DELETE_USER,
  uid,
});

export const deleteUserSuccessAction = () => ({
  type: DELETE_USER_SUCCESS,
});

export const getUserFailureAction = (error) => ({
  type: GET_USER_FAILURE,
  error,
});

export const validateUsernameAction = (username) => ({
  type: VALIDATE_USERNAME,
  username,
});

export const validateUsernameSuccessAction = (isValid, username) => ({
  type: VALIDATE_USERNAME_SUCCESS,
  isValid,
  username,
});

export const validateUsernameFailureAction = (error) => ({
  type: VALIDATE_USERNAME_FAILURE,
  error,
});

export const getUsersListAction = (params) => ({
  type: GET_USERS_LIST,
  ...params,
});

export const getUsersListSuccessAction = (params) => ({
  type: GET_USERS_LIST_SUCCESS,
  ...params,
});

export const getUsersListFailureAction = (error) => ({
  type: GET_USERS_LIST_FAILURE,
  error,
});

export const setUsersListSearchTermAction = (searchTerm) => ({
  type: SET_USERS_LIST_SEARCH_TERM,
  searchTerm,
});
