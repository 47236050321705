import { httpsCallable as firebaseHttpCallable } from 'firebase/functions';
import { functions } from '../config/firebase';
import { AgeRange, NotificationTypes, PromptReviewStatus, UserTypes } from './constants';

// Adds a timestamp to filenames to prevent overwriting in storage
export const addTimestampToFilename = (filename) => {
  // File names come in the format of [name].[extension]
  let withoutExt =
    filename.substring(0, filename.lastIndexOf('.')).replace(/\./g, '_').replace(/ /g, '_') +
    `_${Date.now()}`;
  return `${withoutExt}${filename.substring(filename.lastIndexOf('.'))}`.replace(
    /[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/]/gi,
    ''
  );
};

// Returns an object with notification data to use within a Notification component (handles success and error for now)
export const createNotificationObject = (title, message, type) => {
  const notification = {
    id: 1, // all set to 1 for initial implementation
    title: title,
    description: message,
    backgroundColor: type == NotificationTypes.SUCCESS ? '#5cb85c' : '#d9534f',
    icon: '',
  };
};

export const convertTimeToMilliseconds = (date) => {
  let converted;

  if (date) {
    if (date._seconds) {
      converted = date._seconds * 1000;
    } else if (date.seconds) {
      converted = date.seconds * 1000;
    } else {
      converted = date;
    }
  }

  return converted;
};

export const promptIsLive = (prompt) => {
  const scheduledDate = convertTimeToMilliseconds(prompt.scheduledDate);

  return (
    !prompt.processing &&
    !prompt.failed &&
    scheduledDate &&
    Date.now() > scheduledDate &&
    prompt.reviewStatus !== PromptReviewStatus.DENIED &&
    prompt.reviewStatus !== PromptReviewStatus.UNREVIEWED
  );
};

const nonNetworkErrorCodes = [
  'permission-denied',
  'invalid-agrument',
  'not-found',
  'internal',
  'ok',
];

const timeout = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const httpsCallable = (name, payload, options) => {
  const MAX_ATTEMPTS = 3;
  let error;
  return new Promise(async (resolve, reject) => {
    let attempts = 0;
    while (attempts < MAX_ATTEMPTS) {
      try {
        await timeout(attempts * 150);
        const newPayload = { ...payload, userContext: { ageRange: AgeRange.ADMIN } };
        const resp = await firebaseHttpCallable(functions, name, options)(newPayload);
        return resolve(resp);
      } catch (err) {
        error = err;
        attempts = attempts + 1;
        if (attempts === MAX_ATTEMPTS || nonNetworkErrorCodes.includes(err?.code)) {
          console.error(
            `Error for ${name} on attempt ${attempts}.\nError: '${err}'\nName: ${name}\nPayload:${JSON.stringify(
              payload
            )}`
          );
          return reject(err);
        } else {
          console.log(
            `Failed on attempt ${attempts}, retrying...`,
            err,
            name,
            JSON.stringify(payload)
          );
        }
      }
    }

    //This shouldn't happen if the logic above is right
    console.log('httpsCallable is out of loop');
    reject(error);
  });
};

export const getUserTypeForSession = (session) => {
  if (session.userType === UserTypes.ADMIN) {
    return UserTypes.ADMIN;
  } else if (session.moderator) {
    return UserTypes.MODERATOR;
  } else {
    return session.userType;
  }
};

export const generateUUID = () => Date.now().toString(36) + Math.random().toString(36).substr(2);
