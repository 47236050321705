/*******
 * Set up and configure store, reducers and sagas
 */

import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { takeLatest, all } from 'redux-saga/effects';

// Reducers
import { session } from './session/reducer';
import { prompts } from './prompts/reducer';
import { prompt } from './prompt/reducer';
import { users } from './users/reducer';
import { interests } from './interests/reducer';
import { proposedTags } from './tags/proposed/reducer';
import { searchedTags } from './tags/searched/reducer';
import { moderationFeed } from './moderation/feed/reducer';

// Sagas
import { authorizationSaga, createSessionSaga, changePasswordSaga } from './session/sagas';
import { getAllInterestsSaga } from './interests/sagas';
import {
  getAllProposedTagsSaga,
  acceptProposedTagSaga,
  rejectProposedTagSaga,
} from './tags/proposed/sagas';
import { getAllModerationFeedSaga } from './moderation/feed/sagas';
import {
  createUpdatePromptSaga,
  getAllPromptTypesSaga,
  getPromptsSaga,
  updatePromptRanksSaga,
  assignPromptToGroupSaga,
  updateLocalPromptSaga,
  pullUpPromptSaga,
} from './prompts/sagas';
import { getPromptSaga } from './prompt/sagas';
import {
  getUserSaga,
  deleteUserSaga,
  updateUserSaga,
  validateUsernameSaga,
  getUsersListSaga,
} from './users/sagas';
import { searchTagsSaga } from './tags/searched/sagas';

// Actions
import { GET_INTERESTS } from './interests/actions';
import { AUTHORIZE_USER, CREATE_SESSION, CHANGE_PASSWORD } from './session/actions';
import {
  CREATE_PROMPT,
  GET_ALL_PROMPT_TYPES,
  GET_PROMPTS,
  UPDATE_PROMPT_RANKS,
  ASSIGN_PROMPT_TO_GROUP,
  UPDATE_LOCAL_PROMPT,
  PUSH_UP_PROMPT,
} from './prompts/actions';
import {
  GET_USER,
  UPDATE_USER,
  DELETE_USER,
  VALIDATE_USERNAME,
  GET_USERS_LIST,
} from './users/actions';
import {
  GET_PROPOSED_TAGS,
  ACCEPT_PROPOSED_TAG,
  REJECT_PROPOSED_TAG,
} from './tags/proposed/actions';
import { GET_MODERATION_FEED } from './moderation/feed/actions';
import { GET_PROMPT } from './prompt/actions';
import { SEARCH_TAGS } from './tags/searched/actions';

const rootReducer = combineReducers({
  interests,
  moderationFeed,
  prompts,
  prompt,
  proposedTags,
  searchedTags,
  session,
  users,
});

const sagaMiddleware = createSagaMiddleware();

function* watchAll() {
  yield all([takeLatest(AUTHORIZE_USER, authorizationSaga)]);
  yield all([takeLatest(CREATE_SESSION, createSessionSaga)]);
  yield all([takeLatest(CREATE_PROMPT, createUpdatePromptSaga)]);
  yield all([takeLatest(GET_ALL_PROMPT_TYPES, getAllPromptTypesSaga)]);
  yield all([takeLatest(GET_PROMPTS, getPromptsSaga)]);
  yield all([takeLatest(GET_PROMPT, getPromptSaga)]);
  yield all([takeLatest(UPDATE_PROMPT_RANKS, updatePromptRanksSaga)]);
  yield all([takeLatest(ASSIGN_PROMPT_TO_GROUP, assignPromptToGroupSaga)]);
  yield all([takeLatest(CHANGE_PASSWORD, changePasswordSaga)]);
  yield all([takeLatest(UPDATE_LOCAL_PROMPT, updateLocalPromptSaga)]);
  yield all([takeLatest(PUSH_UP_PROMPT, pullUpPromptSaga)]);
  yield all([takeLatest(GET_USER, getUserSaga)]);
  yield all([takeLatest(UPDATE_USER, updateUserSaga)]);
  yield all([takeLatest(DELETE_USER, deleteUserSaga)]);
  yield all([takeLatest(VALIDATE_USERNAME, validateUsernameSaga)]);
  yield all([takeLatest(GET_INTERESTS, getAllInterestsSaga)]);
  yield all([takeLatest(GET_PROPOSED_TAGS, getAllProposedTagsSaga)]);
  yield all([takeLatest(ACCEPT_PROPOSED_TAG, acceptProposedTagSaga)]);
  yield all([takeLatest(REJECT_PROPOSED_TAG, rejectProposedTagSaga)]);
  yield all([takeLatest(GET_MODERATION_FEED, getAllModerationFeedSaga)]);
  yield all([takeLatest(SEARCH_TAGS, searchTagsSaga)]);
  yield all([takeLatest(GET_USERS_LIST, getUsersListSaga)]);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(watchAll);

export default store;
