import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Modal from '../../../UI/Modal';
import VideoPlayer from '../../../UI/VideoPlayer';
import Spinner from '../../../UI/Spinner/Spinner';
import { useDownloadUrl } from '../../../../hooks';

const StyledVideoSpinner = styled(Spinner)`
  && {
    margin: 100px auto;
  }
`;

const StyledVideoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModerationFeedVideoModal = ({ item, onClose }) => {
  const [videoPath, isLoading, error] = useDownloadUrl(item?.rawData?.fullPath);

  return (
    <Modal isShown={!!item} title={item?.rawData?.name || 'Undefined'} onClose={onClose}>
      <StyledVideoContainer>
        {isLoading ? (
          <StyledVideoSpinner />
        ) : error ? (
          <p>{error}</p>
        ) : (
          item && (
            <VideoPlayer autoPlay videoSource={videoPath} height={item?.rawData?.height || 500} />
          )
        )}
      </StyledVideoContainer>
    </Modal>
  );
};

ModerationFeedVideoModal.propTypes = {
  item: PropTypes.shape({
    rawData: PropTypes.shape({
      fullPath: PropTypes.string,
      height: PropTypes.number,
    }),
  }),
  onClose: PropTypes.func,
};

export default ModerationFeedVideoModal;
