import { useState } from 'react';
import {
  AgeRange as AgeRangeConst,
  PromptMediaType,
  PromptCategories,
  PromptDefaults,
  PromptReviewStatus,
  PollType,
} from '../../../../utils/constants';
import { DEFAULT_POLL_ANSWERS_LIST } from '../Poll';

export default function usePromptFormState(params, { isEditing = false, isAdmin = false } = {}) {
  const getInitMediaType = () => {
    const hasVideo = params.video_url || params.muxPlaybackData;
    const hasPoll = params.pollAnswers?.length > 0;

    if (isEditing && hasPoll) {
      return PromptMediaType.POLL;
    }

    if (isEditing && isAdmin && !hasVideo) {
      return PromptMediaType.IMAGE;
    }

    return PromptMediaType.VIDEO;
  };

  const getInitScheduledDate = () => {
    if (
      isEditing &&
      params.scheduledDate &&
      (params.scheduledDate._seconds || params.scheduledDate.seconds)
    ) {
      if (params.scheduledDate._seconds) {
        return params.scheduledDate._seconds * 1000;
      } else {
        return params.scheduledDate.seconds * 1000;
      }
    }

    return params?.scheduledDate || Date.now();
  };

  const getInitReviewStatus = () => {
    if (!isEditing) {
      return PromptReviewStatus.UNREVIEWED;
    }

    if (!params.reviewStatus) {
      const scheduledDate = getInitScheduledDate();
      const isScheduledDateElapsed = Date.now() > scheduledDate;

      return isScheduledDateElapsed ? PromptReviewStatus.APPROVED : PromptReviewStatus.UNREVIEWED;
    }

    return params.reviewStatus;
  };

  const getInitFormState = () => {
    const scheduledDate = getInitScheduledDate();

    return {
      promptTypes: params.promptTypes,
      interests: params.interests,
      allCategories: PromptCategories,
      id: isEditing ? params.id : '',
      prevPromptTypeId: isEditing ? params.promptTypeId : '', // we keep track of the original promptTypeId to know if we are changing prompt types
      promptTypeId: isEditing
        ? params.promptTypeId
        : params.promptTypes[0]
        ? params.promptTypes[0][1]
        : '',
      interestId: isEditing ? params.interests?.[0] || '' : '',
      type: isEditing ? params.type : params.promptTypes[0] ? params.promptTypes[0][0] : '',
      name: isEditing ? params.name : '',
      scheduledDate,
      gradeMin: isEditing && params.gradeMin ? params.gradeMin : PromptDefaults.GRADE_DEFAULT_START,
      gradeMax: isEditing && params.gradeMax ? params.gradeMax : PromptDefaults.GRADE_DEFAULT_END,
      instructions: isEditing ? params.instructions : '',
      url: isEditing ? params.url : '',
      video_url:
        isEditing && (params.video_url || params.muxPlaybackData)
          ? params.video_url || params.muxPlaybackData
          : '',
      muxStaticMediumWatermarkUrl: isEditing ? params.muxStaticMediumWatermarkUrl : '',
      muxStaticHighWatermarkUrl: isEditing ? params.muxStaticHighWatermarkUrl : '',
      muxStaticLowWatermarkUrl: isEditing ? params.muxStaticLowWatermarkUrl : '',
      watermarkUrl: isEditing ? params.watermarkUrl : '',
      image: null,
      video: null,
      shareName: isEditing && params.shareName ? params.shareName : '',
      loading_image_url: isEditing && params.loading_image_url ? params.loading_image_url : '',
      aspectRatio: isEditing && params.aspectRatio ? params.aspectRatio : 0,
      tags:
        isEditing && params.tags
          ? [...params.tags, ...Array(PromptDefaults.TOTAL_TAGS).fill('')].slice(0, 10)
          : Array(PromptDefaults.TOTAL_TAGS).fill(''),
      // hashTags: isEditing ? params.hashTags : [],
      mediaType: getInitMediaType(),
      lessonPlanUrl: isEditing && params.lessonPlanUrl ? params.lessonPlanUrl : '',
      firstCategory:
        isEditing && params.categories && params.categories[0]
          ? params.categories[0]
          : PromptCategories[0],
      secondCategory:
        isEditing && params.categories && params.categories[1]
          ? params.categories[1]
          : PromptDefaults.SECOND_CATEGORY,
      categories: [],
      reviewStatus: getInitReviewStatus(),
      requirements: params?.requirements || {},
      allowedAgeRanges:
        isEditing && !!params.allowedAgeRanges?.length
          ? params.allowedAgeRanges
          : [AgeRangeConst.KIDS],
      userId: isEditing && isAdmin ? params.userId : null,
      hideLeaderboard: isAdmin ? params.hideLeaderboard : null,
      pollAnswers: params.pollAnswers || DEFAULT_POLL_ANSWERS_LIST,
      randomizePollAnswers: params.randomizePollAnswers ?? true,
      pollType: params.pollType || PollType.TEXT,
      rewardDetails: isAdmin
        ? {
            name: null,
            description: null,
            expiresAt: null,
            ...(params.rewardDetails || {}),
          }
        : null,
    };
  };

  const [form, setForm] = useState(() => getInitFormState());

  const resetForm = () => {
    // Reset all fields, except for prompt types and selected prompt type
    setForm({
      ...getInitFormState(),
      promptTypes: form.promptTypes,
      promptTypeId: form.promptTypeId,
      type: form.type,
    });
  };

  return [form, setForm, resetForm];
}
