import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { decoratePrompts } from '../../../../decorators/promptDecorator';

export default function usePromptGroups() {
  const { prompts: promptGroups, channels, lastUpdated } = useSelector((state) => state.prompts);
  const { userType } = useSelector((state) => state.session);
  const isAdmin = userType === 'ADMIN';
  const filteredPromptGroupts = useMemo(() => {
    if (isAdmin) {
      return promptGroups;
    }

    return promptGroups.filter((group) => group.prompts.length > 0);
  }, [lastUpdated]);

  // NOTE: We decorate the prompts here to avoid doing it in the render method
  //       of the PromptGroup and PromptItem components which would be too expensive
  const decoratedPromptGroups = useMemo(
    () =>
      filteredPromptGroupts.map((group) => ({
        ...group,
        prompts: decoratePrompts(group.prompts).joinChannels(channels),
      })),
    [filteredPromptGroupts]
  );

  return decoratedPromptGroups;
}
