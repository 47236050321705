import React from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../UI/Button/Button';
import { setPromptsSearchTermAction } from '../../../../store/prompts/actions';

const PromptSearchEmpty = () => {
  const dispatch = useDispatch();

  const handleClearSearch = () => {
    dispatch(setPromptsSearchTermAction(null));
  };

  return (
    <div>
      <h3>Sorry, but we couldn&apos;t find any prompts matching your query request.</h3>
      <p>
        Please try refining your search terms or providing more specific information so we can
        assist you better.
      </p>
      <br />
      <Button clicked={handleClearSearch}>Reset search</Button>
    </div>
  );
};

export default PromptSearchEmpty;
