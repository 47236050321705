import React from 'react';
import FormLabel from '../FormLabel/FormLabel';

const TextArea = (props) => {
  return (
    <div>
      <FormLabel label={props.label} />
      {props.readOnly ? (
        <p style={{ margin: '0px' }}>{props.value}</p>
      ) : (
        <div>
          <textarea
            placeholder={props.placeholder}
            name={props.name}
            onChange={props.changed}
            value={props.value}
            maxLength={props.maxlength}
          />
        </div>
      )}
      {props.hyperlink && (
        <a
          style={{ color: '#094dac', fontWeight: 'bold' }}
          rel="noreferrer noopener"
          target="_blank"
          href={props.hyperlink}>
          Open existing {props.hyperlinkName}
        </a>
      )}
    </div>
  );
};

export default TextArea;
