import React from 'react';
import Button from '../../UI/Button/Button';
import styled from 'styled-components';

const StyledInput = styled.input`
  background-color: white !important;
  margin-bottom: 10px;
`;

const EmailLogin = (props) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      props.clicked();
    }
  };

  return (
    <div>
      <StyledInput
        type="text"
        name="email"
        placeholder="Email"
        onChange={props.credentialsHandler}
        value={props.email}
      />
      <StyledInput
        type="password"
        name="password"
        placeholder="Password"
        onChange={props.credentialsHandler}
        onKeyDown={handleKeyDown}
        value={props.password}
      />
      <br />
      <Button title="Email Login" enabled={true} clicked={props.clicked} />
    </div>
  );
};

export default EmailLogin;
