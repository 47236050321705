import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { convertTimeToMilliseconds } from '../../../utils/functions';
import { pullUpPromptAction } from '../../../store/prompts/actions';
import Button from '../../UI/Button/Button';
import { AgeRange } from '../../../utils/constants';
import Spinner from '../../UI/Spinner/Spinner';

const BUTTON_LABELS = {
  [AgeRange.KIDS]: 'Pull Up (Kids)',
  [AgeRange.GA]: 'Pull Up (GA)',
};

const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;
  column-gap: 1rem;
`;

const PromptFormPullUpButtons = ({ prompt }) => {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { allowedAgeRanges = [AgeRange.KIDS] } = prompt;
  const handlePullUp = (e) => {
    e.preventDefault();

    const scheduledDate = convertTimeToMilliseconds(prompt.scheduledDate);

    setLoading(true);
    dispatch(
      pullUpPromptAction(prompt.promptTypeId, prompt.id, e.target.dataset.agerange, scheduledDate)
    );
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <StyledButtonsContainer>
      {allowedAgeRanges.map((ageRange) => (
        <Button key={ageRange} clicked={handlePullUp} data-agerange={ageRange}>
          {BUTTON_LABELS[ageRange]}
        </Button>
      ))}
    </StyledButtonsContainer>
  );
};

export default PromptFormPullUpButtons;
