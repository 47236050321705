import React from 'react';
import RadioButton from '../../../UI/RadioButton/RadioButton';
import { PollType } from '../../../../utils/constants';

const PollTypeField = ({ pollType, isDisabled, onChange }) => {
  const handleChange = (e) => {
    onChange(e.currentTarget.value);
  };

  return (
    <div>
      <label>
        <span className="mandatory-field">
          <strong>Poll Type</strong>
        </span>
      </label>
      <br />
      <br />
      <div style={{ marginTop: '5px' }}>
        <RadioButton
          id={PollType.TEXT}
          label="Text"
          value={PollType.TEXT}
          checked={pollType == PollType.TEXT}
          changed={handleChange}
          disabled={isDisabled}
        />
        <RadioButton
          id={PollType.IMAGE}
          label="Image"
          value={PollType.IMAGE}
          checked={pollType == PollType.IMAGE}
          changed={handleChange}
          disabled={isDisabled}
        />
      </div>
    </div>
  );
};

export default PollTypeField;
