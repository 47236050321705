import React from 'react';
import { SONGCLIP_DOWNLOADER_IFRAME_URL } from '../../utils/constants';

const SongClipDownloader = () => {
  return (
    <div className="Container">
      <h1>SongClip Downloader</h1>
      <p>
        Note: You <strong>must</strong> have a ReTool account in order to use this page.
      </p>
      <iframe src={SONGCLIP_DOWNLOADER_IFRAME_URL} width="100%" height="800px"></iframe>
    </div>
  );
};

export default SongClipDownloader;
