import React from 'react';
import { useDispatch } from 'react-redux';
import ButtonGroup from '../../../UI/ButtonGroup';
import { PromptAgeRangeFilter, PromptReviewMode } from '../../../../utils/constants';
import { setPromptsFilterAction } from '../../../../store/prompts/actions';
import usePromptRankMode from '../hooks/usePromptRankMode';

const BUTTON_TO_FILTER_MAP = {
  [PromptReviewMode.KIDS_RANKING]: {
    allowedAgeRanges: PromptAgeRangeFilter.KIDS,
  },
  [PromptReviewMode.GA_RANKING]: {
    allowedAgeRanges: PromptAgeRangeFilter.GA,
  },
};

const PromptRankingMode = () => {
  const dispatch = useDispatch();
  const { isKidsSelected, isGASelected } = usePromptRankMode();
  const activeButton = isKidsSelected
    ? PromptReviewMode.KIDS_RANKING
    : isGASelected
    ? PromptReviewMode.GA_RANKING
    : null;

  const handleButtonClick = (id) => {
    dispatch(setPromptsFilterAction(BUTTON_TO_FILTER_MAP[id]));
  };

  return (
    <ButtonGroup
      activeButton={activeButton}
      buttons={[
        { id: PromptReviewMode.KIDS_RANKING, label: 'Kids' },
        { id: PromptReviewMode.GA_RANKING, label: 'GA' },
      ]}
      onButtonClick={handleButtonClick}
    />
  );
};

export default PromptRankingMode;
