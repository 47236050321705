import styled from 'styled-components';
import theme from '../../../../../theme';

export const StyledPromptItemWrapper = styled.div`
  border-radius: 5px;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed !important' : 'pointer !important')};
  background-color: ${(props) => {
    if (props.isDisabled) {
      return '#EEE';
    } else if (props.hasFailed) {
      return theme.color.lightPink;
    } else {
      return 'white';
    }
  }};
  color: ${(props) => (props.isDisabled ? 'grey' : '#3e4348')};
  margin: auto;
  overflow: hidden;
  border-radius: 5px;
  position: relative;
  margin: 20px 0px;
  &:hover {
    opacity: 0.85;
  }
`;

export const StyledPromptItemContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
`;

export const StyledStatusLabel = styled.span`
  min-height: 20px;
  font-size: 12px;
  font-weight: bold;
  padding: 2px 7px;
  margin-left: 10px;
  text-align: right;
  text-transform: uppercase;
  color: white;
  border-radius: 5px;
  text-align: center;
`;

export const CenteredRow = styled.div`
  align-items: center;
  display: flex;
  padding-right: 10px;
  max-width: calc(100% - 180px);
`;

export const StyledPromptItemTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  padding: 10px 0;
`;

export const StyledPromptItemText = styled.span`
  font-weight: bold;
  font-size: 1.25em;
  max-width: 100%;
  word-break: break-word;
`;

export const StyledUserName = styled.div`
  font-size: 16px;
  font-weight: bold;
  display: flex;
  color: #666;
  line-height: 30px;
`;

export const StyledThumbnail = styled.img`
  aspect-ratio: 1.2;
  object-fit: cover;
  min-width: 150px;
  width: 150px;
`;

export const StyledPromptItemMutedWrapper = styled.div`
  border-radius: 5px;
  cursor: 'pointer !important';
  background-color: #eee;
  margin: auto;
  overflow: hidden;
  margin: 20px 0px;

  &:hover {
    opacity: 0.85;
  }
`;

export const PromptItemMutedContent = styled.div`
  align-items: stretch;
  display: flex;
  position: relative;
`;

export const PromptItemMutedText = styled.span`
  align-items: center;
  color: ${theme.color.mediumGrey};
  display: flex;
  font-size: 14px;
  font-weight: bold;
  margin-left: 20px;
  padding: 10px 0;
`;
