import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, userType, allowedUserTypes, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          (allowedUserTypes?.length && allowedUserTypes.includes(userType)) ||
          (!allowedUserTypes && userType)
        ) {
          return <Component {...rest} {...props} />;
        } else {
          console.log('redirecting in ProtectedRoute');
          return <Redirect to={{ pathname: '/' }} />;
        }
      }}
    />
  );
};

export default ProtectedRoute;
