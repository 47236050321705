import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  border: none;
  font-size: 14px;
  font-weight: 600;
  text-transform: initial;
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
`;

function ScrollTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  const handleVisibility = () => {
    setIsVisible(window.pageYOffset > window.innerHeight);
  };

  useEffect(() => {
    // Add a scroll event listener to toggle the visibility of the button
    window.addEventListener('scroll', handleVisibility);

    return () => {
      window.removeEventListener('scroll', handleVisibility);
    };
  }, []);

  // Scroll to the top of the page when the button is clicked
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <StyledButton isVisible={isVisible} onClick={handleClick}>
      Scroll to Top
    </StyledButton>
  );
}

export default ScrollTopButton;
