import {
  ACCEPT_PROPOSED_TAG_FAILURE,
  ACCEPT_PROPOSED_TAG_SUCCESS,
  ACCEPT_PROPOSED_TAG,
  GET_PROPOSED_TAGS_FAILURE,
  GET_PROPOSED_TAGS_SUCCESS,
  GET_PROPOSED_TAGS,
  REJECT_PROPOSED_TAG_FAILURE,
  REJECT_PROPOSED_TAG_SUCCESS,
  REJECT_PROPOSED_TAG,
} from './actions';

const decorateProposedTags = (proposedTags) => {
  return proposedTags.map((proposedTag) => ({
    rawData: proposedTag,
    id: proposedTag.id,
    isLoading: false,
    error: null,
  }));
};

const defaultState = {
  error: null,
  isLoading: false,
  hasNextRecords: true,
  proposedTags: [],
};

export const proposedTags = (state = defaultState, action) => {
  const updateTag = (cb) => {
    return state.proposedTags.map((tag) => (tag.id === action.tagId ? cb(tag) : tag));
  };

  const filteredTags = () => {
    return state.proposedTags.filter((tag) => tag.id !== action.tagId);
  };

  switch (action.type) {
    case GET_PROPOSED_TAGS: {
      return { ...state, isLoading: true };
    }

    case GET_PROPOSED_TAGS_SUCCESS:
      return {
        ...state,
        proposedTags: decorateProposedTags(action.proposedTags || []),
        isLoading: false,
        hasNextRecords: action.hasNextRecords,
      };

    case GET_PROPOSED_TAGS_FAILURE:
      return { ...state, isLoading: false, error: action.error };

    case ACCEPT_PROPOSED_TAG:
    case REJECT_PROPOSED_TAG: {
      return {
        ...state,
        proposedTags: updateTag((tag) => ({ ...tag, isLoading: true })),
      };
    }

    case ACCEPT_PROPOSED_TAG_SUCCESS:
    case REJECT_PROPOSED_TAG_SUCCESS: {
      return {
        ...state,
        proposedTags: filteredTags(),
      };
    }

    case ACCEPT_PROPOSED_TAG_FAILURE:
    case REJECT_PROPOSED_TAG_FAILURE: {
      return {
        ...state,
        proposedTags: updateTag((tag) => ({ ...tag, isLoading: false, error: action.error })),
      };
    }

    default:
      return state;
  }
};
