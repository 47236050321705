export const GET_MODERATION_FEED = 'GET_MODERATION_FEED';
export const GET_MODERATION_FEED_SUCCESS = 'GET_MODERATION_FEED_SUCCESS';
export const GET_MODERATION_FEED_FAILURE = 'GET_MODERATION_FEED_FAILURE';

export const getModerationFeedAction = () => ({
  type: GET_MODERATION_FEED,
});

export const getModerationFeedSuccessAction = (feed, hasNextRecords) => ({
  type: GET_MODERATION_FEED_SUCCESS,
  feed,
  hasNextRecords,
});

export const getModerationFeedFailureAction = (error) => ({
  type: GET_MODERATION_FEED_FAILURE,
  error,
});
