import React from 'react';
import PropTypes from 'prop-types';

const Disclaimer = ({ children, style }) => {
  return (
    <p style={{ fontSize: '.9em', fontStyle: 'italic', ...style }}>
      <strong>Disclaimer:</strong>
      <span>&nbsp;{children}</span>
    </p>
  );
};

Disclaimer.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};

export default Disclaimer;
