import { ref, uploadBytesResumable, uploadBytes } from 'firebase/storage';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { storage } from '../../config/firebase';
import store from '../store';
import { httpsCallable } from '../../utils/functions';

export const getAllPromptTypes = async () => {
  try {
    const response = await httpsCallable('getAllPromptTypes');
    return response.data;
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
    throw new Error(e);
  }
};

export const uploadPromptImage = async (image, filename) => {
  try {
    const imageRef = ref(storage, `promptPhotos/${filename}`);
    await uploadBytes(imageRef, image);

    return imageRef.fullPath;
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
    throw new Error(e);
  }
};

export const uploadPromptPollAnswerImage = async (id, image, filename) => {
  try {
    const imageRef = ref(storage, `promptPhotos/pollAnswerPhotos/${id}/${filename}`);
    await uploadBytes(imageRef, image);

    return imageRef.fullPath;
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
    throw new Error(e);
  }
};

export const uploadPromptVideo = async (video, filename, successCallback) => {
  try {
    const storageRef = ref(storage, `prompt_videos/${filename}`);
    const uploadTask = uploadBytesResumable(storageRef, video);

    uploadTask.on('state_changed', updatePromptUploadStatus, handleError, successCallback);

    return uploadTask;
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
    throw new Error(e);
  }
};

const updatePromptUploadStatus = (snapshot) => {
  let currentProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  store.dispatch({
    type: 'UPDATE_PROGRESS_BAR',
    percentage: Math.round(currentProgress).toString(),
  });
};

const handleError = (error) => {
  console.log(error);
  Sentry.captureException(error);
};

export const optimizeVideo = async (promptId) => {
  await httpsCallable(
    'runPromptUploadConversionGen2',
    {
      promptId,
    },
    { timeout: 60 * 60 * 1000 }
  );
};

export const createPrompt = async (prompt, imagePath) => {
  try {
    const response = await httpsCallable('createPrompt', {
      prompt,
      source: 'web',
      ...(imagePath && { imagePath }),
    });
    return response.data;
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
    throw new Error(e);
  }
};

export const updatePrompt = async (promptTypeId, promptId, newData, imagePath) => {
  try {
    const response = await httpsCallable('updatePrompt', {
      promptTypeId,
      promptId,
      newData,
      ...(imagePath && { imagePath }),
    });
    return response.data;
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
    throw new Error(e);
  }
};

export const getPrompts = async (data = {}) => {
  try {
    const response = await httpsCallable('getAdminPortalPrompts', data);
    return response.data.prompts;
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
    throw new Error(e);
  }
};

export const switchPromptTypeForPrompt = async (switchProps) => {
  try {
    const response = await httpsCallable('switchPromptTypeForPrompt', switchProps);
    return response.data;
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
    throw new Error(e);
  }
};

export const updatePromptRanks = async (promptTypeId, rankAgeRange, promptRanks) => {
  try {
    const response = await httpsCallable('updatePromptRanks', {
      promptTypeId,
      promptRanks,
      rankAgeRange,
    });
    return response;
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
    throw new Error(e);
  }
};

export const getChannels = async () => {
  try {
    const response = await httpsCallable('getUsers', { requestChannels: true });
    return response.data.users;
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
    throw new Error(e);
  }
};
