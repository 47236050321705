import React from 'react';
import Button from '../../UI/Button/Button';

const GoogleLogin = (props) => {
  return (
    <div>
      <Button name="admin" title="Google Login" enabled={true} clicked={props.clicked} />
    </div>
  );
};

export default GoogleLogin;
