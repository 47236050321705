import React from 'react';
import FormLabel from '../FormLabel/FormLabel';
import styled from 'styled-components';

const StyledInput = styled.input`
  border-radius: 5px;
  border: none;
`;

const TextInput = (props) => (
  <div>
    <FormLabel label={props.label} />
    {props.readOnly ? (
      <p style={{ margin: '0' }}>{props.value}</p>
    ) : (
      <StyledInput
        type="text"
        name={props.name}
        placeholder={props.placeholder}
        onChange={props.changed}
        value={props.value}
        maxLength={props.maxlength}
        style={props.style}
      />
    )}
    {props.hyperlink && (
      <a
        style={{ color: '#094dac', fontWeight: 'bold', marginLeft: '10px' }}
        rel="noreferrer noopener"
        target="_blank"
        href={props.hyperlink}>
        Open existing {props.hyperlinkName}
      </a>
    )}
  </div>
);

export default TextInput;
