import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Button from '../Button/Button';
import theme from '../../../theme';

const StyledButton = styled(Button)`
  padding: 10px 20px;
  border-radius: 0 5px 5px 0;
  text-align: center;
  cursor: pointer;
  border: none;
  font-size: 14px;
  font-weight: 600;
  text-transform: initial;
  background-color: ${theme.color.blue};
  color: ${theme.color.white};

  &:active {
    background-color: ${theme.color.darkBlue};
  }
`;

const StyledResetButton = styled(Button)`
  padding: 7px 10px;
  border-radius: 0;
  text-align: center;
  cursor: pointer;
  border: none;
  font-size: 12px;
  text-transform: initial;
  background-color: ${theme.color.lightGrey};
  color: ${theme.color.black};
  position: absolute;
  right: 90px;
  top: 4px;
`;

const StyledInput = styled.input`
  padding: 10px 20px !important;
  border-radius: 5px 0 0 5px !important;
  border: 1px solid #eee;
  font-size: 14px !important;
  font-weight: 600;
  text-transform: initial;
  min-width: calc(100% - 87px);

  @media (min-width: 992px) {
    min-width: 400px;
  }
`;

const StyledForm = styled.form`
  display: flex;
  position: relative;
`;

const SearchBar = ({ minLengath, value, placeholder, onSubmit }) => {
  const [searchTerm, setSearchTerm] = useState(value || '');
  const isResetButtonVisible = searchTerm && value === searchTerm;

  // NOTE: reset search term when stored search term become empty
  useEffect(() => {
    if (value === searchTerm) {
      return;
    }

    setSearchTerm('');
  }, [value]);

  const handleChange = (event) => {
    const newSearchTerm = event.target.value;

    // TODO: Make this more efficient
    if (!newSearchTerm.trim() && searchTerm.trim().length > 0) {
      handleReset();

      return;
    }

    setSearchTerm(newSearchTerm);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (searchTerm.length < minLengath) {
      return;
    }

    onSubmit(searchTerm);
  };

  const handleReset = () => {
    setSearchTerm('');
    onSubmit(null);
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledInput
        type="text"
        placeholder={placeholder}
        value={searchTerm}
        onChange={handleChange}
      />
      {isResetButtonVisible && (
        <StyledResetButton type="button" clicked={handleReset}>
          &#9587;
        </StyledResetButton>
      )}
      <StyledButton type="submit">Search</StyledButton>
    </StyledForm>
  );
};

export default SearchBar;
