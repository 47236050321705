import styled from 'styled-components';

const CenteredLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  flex-direction: column;
`;

export default CenteredLayout;
